.favourite-con {
  // max-width: 750px;
  // height: 100vh;
  // margin: 0 auto;
  // overflow-y: auto;
  // position: relative;
  .content-title-header {
    display: flex;
    border-bottom: 1px #D0D7DE solid;
    height: 35px;
    position: relative;
    margin-top: 16px;
    .select-all {
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
    div:nth-child(1) {
      width: 40%;
      padding-left: 30px;
      .order-img {
        width: 12px;
        height: 15px;
        margin-left: 8px;
      }
    }
    div {
      width: 15%;
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #24292F;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .page-con {
    padding-top: 40px;
    padding-bottom: 16px;
    .page-con-title {
      font-family: HelveticaNeue-Bold;
      font-size: 30px;
      color: #24292F;
      letter-spacing: 0;
      line-height: 30px;
    }
  }

  .page-con-item {
    display: flex;
    align-items: center;
    .page-con-search {
      background: #F6F8FA;
      border-radius: 18px;
      width: 320px;
      height: 36px;
      margin-right: 16px;
      line-height: 36px;
      position: relative;
      .ant-input {
        height: 36px;
        line-height: 36px;
        padding-left: 40px;
        color: #8C959F;
      }
      .ant-input-outlined {
        background-color: #F6F8FA;
        border-radius: 18px;
        border: none;
       
        &:focus-within{
           border-color: #F6F8FA;
           box-shadow: none;
        }
      }
    }
    .search-img {
      position: absolute;
      top: 10px;
      left: 14px;
      width: 17.5px !important;
      height: 17.5px;
    }
  }

  .controls {
    div:nth-child(1) {
      margin-left: 0 !important;
    }
  }
  .move-to-line {
    position: relative;
    width: 1px;
    height: 12px;
    background: #D0D7DE;
    margin-top: 6px;
    margin-left: 20px;
  }
  .move-to-trash-selected {
    span {
      color: #16ADFD;
      margin-left: 0 !important;
    }
  }

  .header {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    border-bottom: 1px #F4F4F4 solid;

    .header-menu {
      img {
        width: 20px;
        margin-left: 16px;
        vertical-align: bottom;
      }
    }

    .header-txt {
      margin-left: 15px;
      font-family: HelveticaNeue-Bold;
      font-size: 24px;
      color: #1E2128;
      letter-spacing: 0;
    }


  }

  .ui-head-nav{
    border-bottom: 1px #DFDFE1 solid;
  }

  .header-search {
    // width: 305px;

    height: 32px;
    opacity: 0.6;
    background: #DFDFE1;
    border-radius: 16px;
    position: relative;
    margin: 12px 16px 12px;
    //box-sizing: border-box;

    img {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      left: 16px;
    }

    .header-search-input {
      width: 100%;
      height: 32px;
      background-color: transparent;
      letter-spacing: 0;

      .adm-input-element {
        text-indent: 38px;
      }

      input::-webkit-input-placeholder {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
      }
    }

  }

  .header-pause {
    background: #1E2128;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: -8px;

    p {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 16px;
    }

    .adm-button {
      width: 96px;
      height: 24px;
      background: #F4F4F4;
      border-radius: 12.5px;
      line-height: 8px;

      span {
        font-family: HelveticaNeue-Medium;
        font-size: 12px;
        color: #16ADFD;
        letter-spacing: 0;
      }
    }
  }

  .upgrade-storage {
    background: #1E2128;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: -8px;

    p:nth-child(1) {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 16px;

      span {
        font-family: HelveticaNeue;
        font-size: 14px;
        color: #16ADFD;
        letter-spacing: 0;
        text-decoration: underline;
      }
    }

    p:nth-child(2) {
      color: #fff;
      font-size: 18px;
      font-family: HelveticaNeue;
      font-weight: bold;
    }
  }

  .add {
    position: fixed;
    right: 12px;
    bottom: 12px;

    img {
      width: 56px;
    }
  }


}

.header-search-input:focus-visible {
  border-radius: 16px;
  outline: 1px #DFDFE1 solid;
  /* opacity: 0; */
}

.fav-file-head-box{

  height: 60px;


  &:has(.header-pause), &:has(.upgrade-storage){
    //height: 158px;
  }

  .ui-head-nav{
    padding: 19px 16px;
  }
}


.fav-content {
  padding: 0px 0 0px;
  box-sizing: border-box;
  height: calc(100% - 100px);

  .content-title {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 15px;
    padding: 20px 24px 15px;
    box-sizing: border-box;
    
    div:nth-child(1) {
      display: flex;

      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 20px;
        color: #1E2128;
      }

      img {
        width: 14px;
        margin-left: 8px;
      }
    }

    div:nth-child(2) {
      img {
        width: 18px;
      }
    }
    // .center-search {
    //   // border: 1px red solid;
    //   // display: flex;
    //   // .search-input {
    //   //   margin-right: 6px;
    //   //   position: relative;
    //     // .ant-input {
    //     //   width: 256px;
    //     //   height: 36px;
    //     //   background: #FFFFFF;
    //     //   border: 1px solid #16ADFD;
    //     //   border-radius: 18px;
    //     //   font-family: HelveticaNeue;
    //     //   font-size: 16px;
    //     //   color: #24292F;
    //     //   letter-spacing: 0;
    //     //   line-height: 18px;
    //     //   text-indent: 28px;
    //     // }
    //     // img {
    //     //   position: absolute;
    //     //   top: 10px; 
    //     //   left: 10px
    //     // }
    //   }
    //   .search-cancel {
    //     img {
    //        width: 36px;
    //        height: 36px;
    //     }
    //  }
    // }
  }

  .file-item-list{
    height: calc(100% - 55px);
    padding-bottom: 30px;
    // overflow-y: auto;
    box-sizing: border-box;
  }

  .content-data {
    margin: 107px auto 0;
    text-align: center;

    .content-data-img {
      width: 240px;
      height: 198px;
      margin: 0 auto;
    }

    .content-data-txt {
      h4 {
        margin-top: 12px;
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
        letter-spacing: 0;
        margin-top: 7px;
      }
    }
  }

  .content-two {
    margin-top: -12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // padding: 0 9px;
    .content-two-item {
      width: 152px;
      height: 152px;
      background: #FFFFFF;
      border-radius: 8px;
      margin-top: 24px;
      position: relative;
      overflow: hidden;

      .more {
        display: flex;
        justify-content: flex-end;
        margin: 0px;
        position: absolute;
        width: 100%;
        z-index: 1;
        padding: 2px 2px;
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.85));
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .a-more-btn{
          display: inline-flex;
          //height: 12px;
          padding: 6px 10px;
        }

        img {
          width: 20px;
          vertical-align: bottom;
        }
      }

      .file {
        //display: flex;
        //justify-content: center;

        img {
          width: 72px;
          vertical-align: middle;
        }
      }

      .real-file{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .adm-image{
          width: 100%;
          height: 100%;
        }

        img{
          width: 100%;
        }
      }

      .txt {
        margin-top: 12px;
        //font-family: HelveticaNeue;
        //font-size: 16px;
        //color: #1E2128;
        letter-spacing: 0;
        text-align: center;
        //line-height: 18px;
        padding: 0 10px;

        font-family: HelveticaNeue;
        font-size: 14px;
        color: #24292F;
        letter-spacing: 0;
        text-align: center;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 180px;
        //position: absolute;
        //left: 0;
        //right: 0;
        //bottom: 5px;

        //display: flex;

        //color: #FFFFFF;
        //background-color: rgba(0,0,0,0.65);
        .txt-nowrap {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .adm-ellipsis{
          transform-origin: center;
          //transform: scale(0.85);
          text-align: center;
          font-family: HelveticaNeue-Bold;
          font-size: 30px;
          color: #24292F;
          letter-spacing: 0;
        }
      }

      .--bottom-text-box{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
      }

      .--date{
        font-family: HelveticaNeue;
        font-size: 11px;
        color: #270000;
        letter-spacing: 0;
        text-align: center;
        opacity: 0.6;
      }
    }
  }

  .content-item {
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    //margin-top: 33px;
    padding: 16px 0 16px 0 ;
    position: relative;
    &:hover{
      background: #F6F8FA;
    }

    .content-item-type {
      display: flex;
      width: calc(100% - 60px);

      img {
        width: 32px;
        max-height: 42px;
        object-fit: contain;
        object-position: center;
      }

      .content-item-txt {
        margin-left: 16px;
        p {
          font-family: HelveticaNeue;
          font-size: 12px;
          color: #270000;
          letter-spacing: 0;
          line-height: 18px;
          max-width: 430px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        h4 {
          font-family: HelveticaNeue;
          font-size: 16px;
          color: #1E2128;
          line-height: 18px;
        }

        &>div {
          font-family: HelveticaNeue;
          font-size: 12px;
          color: #000029;
          line-height: 18px;
        }
      }
    }

    .content-item-more {
      img {
        width: 20px;
      }
    }
  }
}

.popup-con {
  .adm-popup-body {
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    // border-radius: 24px 24px 0 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: auto;
    padding-bottom: 56px;
    // padding: 0 24px;
    .popup-title {
      font-family: HelveticaNeue-Medium;
      font-size: 20px;
      color: #1E2128;
      letter-spacing: 0;
      line-height: 20px;
      margin: 27px 24px 0;
    }

    .popup-item {
      display: flex;
      padding: 15px 24px 15px;
      align-items: center;
      margin-top: 8px;

      p {
        margin-left: 15px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
      }
    }

    .popup-item:nth-child(3) {
      border-bottom: 1px solid #F4F4F4;
    }

    .popup-item-active {
      background: #F4F4F4;
    }
  }
}
