.recent-page {
    .ui-head-nav {
        padding: 19px 16px;
        border-bottom: 1px  #DFDFE1 solid;
    }
    height: 100vh;
    overflow-y: auto;

  .-head{
    height: 60px;
    width: 100%;
  }

  .-body{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
  }

  .content{

  }

  .-search-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px solid #DFDFE1;
    padding: 14px 16px;
    box-sizing: border-box;
    height: 60px;

    .-btn{
      width: 50px;
      display: block;
      box-sizing: border-box;
      padding-left: 10px;
    }

    .-sch{
      width: calc(100% - 50px);
      position: relative;
    }


    .sh-icon{
      position: absolute;
      top: 8px;
      left: 8px;
      padding-top: 1px;
      opacity: 0.5;

      img{
        width: 15px;
        vertical-align: middle;
      }
    }

    .adm-input{

    }

    .adm-input-element{
      outline: 1px solid rgba(0,0,0,0.15);
      border-radius: 15px;
      box-sizing: border-box;
      padding: 0 15px 0 30px;
      height: 32px;
    }
  }
}
