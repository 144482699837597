.video-preview-con {
  position: relative;
  background: #000000;

  .video-canvas {
    background: #000000;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    vertical-align: bottom;
  }

  .video-loading-con{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
