.details-popup-con {
     position: relative;
     top: 0;
     right: 0;
     width: 246px !important;
    //  div:last-child {
    //   border-top: 1px red solid;
    //  }
    //  div
    .popup-item:first-child {
      border-bottom: 1px #D0D7DE solid;
    }
    .popup-item:last-child {
      border-top: 1px #D0D7DE solid;
    }
     
    .adm-popup-body {
      // position: absolute;
      // top: 0;
      right: 0 !important;
      width: 246px !important;
      background: #FFFFFF;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
      border-radius: 12px;
        // border-radius: 24px 24px 0 0;
        // border-top-left-radius: 24px;
        // border-top-right-radius: 24px;
        height: auto;
        // padding-bottom: 56px;
        // padding: 0 24px;
        .popup-title {
        //   font-family: HelveticaNeue-Medium;
        //   font-size: 20px;
        //   color: #1E2128;
        //   letter-spacing: 0;
        //   line-height: 20px;
          padding: 27px 24px 12px;
          display: flex;
          border-bottom: 1px #DEDEDF solid;
          div:nth-child(1) {
            img {
                width: 32px;
                max-height: 40px;
                object-position: center;
                object-fit: cover;
              }
          }
          div:nth-child(2) {
            margin-left: 16px;
            h5 {
                font-family: HelveticaNeue;
                font-size: 16px;
                color: #1E2128;
                letter-spacing: 0;
                line-height: 18px;
            }
            p {
                font-family: HelveticaNeue;
                font-size: 12px;
                color: #000029;
                letter-spacing: 0;
                line-height: 18px;
            }
          }

        }
        .popup-item {
          display: flex;
          padding: 15px 24px 15px;
          align-items: center;
          margin-top: 8px;
          cursor: pointer;
          &:hover {
            background: #F4F4F4;
          }

          @media screen and (max-height: 700px){
            padding: 10px 24px 10px;
            margin-top: 4px;
          }

          p{
            margin-left: 15px;
            font-family: HelveticaNeue;
            font-size: 16px;
            color: #1E2128;
            letter-spacing: 0;
          }
        }
        .popup-item:nth-child(3) {
          // border-bottom:1px solid #F4F4F4;
        }
        .popup-item-active {
          background: #F4F4F4;
        }
        .underline {
            border: 1px #F4F4F4 solid;
            margin-top: 4px;

        }
      }
      .adm-popup-body-position-bottom {
        right: 235px !important;
        top: 318px;
       }
       .adm-mask {
        background-color: unset !important;
       }
      
}

.adm-popup-body-position-bottom {
  left: auto; 
  bottom: auto;
}