@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065: #5E6065;
@variable-37352F: #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-16ADFD: #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white: #fff;

.details-modal {
  // width: 464px !important;
  background-color: @variable-EAEEF2 !important;

  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
    padding: 0 32px;

    position: relative;
  }

  .a-opacity{
    transition: opacity 0.5s;
  }

  .loading-box{
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(255,255,255,.05);
    z-index: 1;

    //pointer-events: none;
  }

  .details-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    p:nth-child(2) {
      font-family: HelveticaNeue-Bold;
      font-size: 20px;
      color: @variable-1E2128;
      letter-spacing: 0;
      text-align: center;
    }

    p:last-child {
      width: 44px;
      height: 44px;
      background-color: @variable-white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .my-subscriptions-con {
    margin-top: 24px;

    .my-subscriptions-card {
      width: 100%;
      height: auto;
      background: @variable-white;
      border-radius: 12px;

      div:nth-child(1) {
        padding: 14px 25px 0;

        h4 {
          font-family: HelveticaNeue-Medium;
          font-size: 16px;
          color: @variable-1E2128;
          letter-spacing: 0;
          font-weight: bold;
        }
      }

      div {
        padding: 0 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 25px;

        p:nth-child(1) {
          font-family: HelveticaNeue;
          font-size: 14px;
          color: @variable-57606A;
          letter-spacing: 0;
        }

        p:nth-child(2) {
          font-family: HelveticaNeue-Medium;
          font-size: 14px;
          color: @variable-24292F;
          letter-spacing: 0;
          font-weight: bold;
        }
      }

      div:last-child {
        margin-top: 14px;
        padding: 0 !important;
        width: 100%;
        border-top: 1px solid @variable-EAEEF2;
        height: 40px;
        line-height: 40px;
        font-family: HelveticaNeue-Medium;
        font-size: 14px;
        color: @variable-16ADFD;
        letter-spacing: 0;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .cloud-device {
    margin-top: 29px;

    .cloud-device-title {
      display: flex;
      justify-content: space-between;

      p:nth-child(1) {
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: @variable-24292F;
        letter-spacing: 0;
        line-height: 16px;
        position: relative;
        margin-left: 10px;

        &::after {
          content: "";
          position: absolute;
          left: -10px;
          top: 2px;
          width: 3px;
          height: 12px;
          background: @variable-16ADFD;
          border-radius: 24px;
          border-radius: 5.5px;
        }
      }

      p:nth-child(2) {
        font-family: HelveticaNeue-Medium;
        font-size: 12px;
        color: @variable-16ADFD;
        letter-spacing: 0;
        text-align: right;
        line-height: 12px;
        text-decoration: underline;

        cursor: pointer;
      }
    }
  }

  .cloud-device-card {
    margin-top: 12px;
    background: @variable-white;
    border-radius: 8px;
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cloud-device-card-l {
      display: flex;
      margin-left: 6px;
      align-items: center;

      p:nth-child(1) {
        img {
          width: 60px;
          height: 60px;
        }
      }

      p:nth-child(2) {
        margin-left: 12px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: @variable-1E2128;
        letter-spacing: 0.19px;
      }
    }

    .cloud-device-card-r {
      margin-right: 16px;
    }
  }

  .cloud-features {
    margin-top: 30px;

    .cloud-features-title {
      font-family: HelveticaNeue-Medium;
      font-size: 16px;
      color: @variable-24292F;
      letter-spacing: 0;
      line-height: 16px;
      position: relative;
      margin-left: 10px;

      &::after {
        content: "";
        position: absolute;
        left: -10px;
        top: 2px;
        width: 3px;
        height: 12px;
        background: @variable-16ADFD;
        border-radius: 24px;
        border-radius: 5.5px;
      }
    }

    .cloud-features-con {
      margin-top: 10px;
      background: @variable-white;
      border-radius: 12px;
      height: auto;
      padding-bottom: 33px;

      div {
        width: 100%;

        p:nth-child(1) {
          svg {
            path {
              fill: @variable-57606A;
            }
          }
        }
      }

      .cloud-features-con-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 33px;

        p:nth-child(2) {
          font-family: HelveticaNeue;
          font-size: 12px;
          color: @variable-57606A;
          letter-spacing: 0;
          text-align: center;
          line-height: 11px;
          margin-top: 12px;
        }
      }
    }
  }

  .unsubscribe {
    margin-top: 16px;
    background: #FFFFFF;
    border-radius: 24px;
    height: 48px;
    width: 100%;
    font-family: HelveticaNeue-Medium;
    font-size: 16px;
    color: #57606A;
    letter-spacing: 0;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
  }

  .note {
    margin-top: 32px;
    text-align: center;

    p:nth-child(2) {
      margin-top: 8px;
      font-family: HelveticaNeue;
      font-size: 12px;
      color: @variable-6E7781;
      letter-spacing: 0;
      text-align: center;
      line-height: 18px;
    }
  }
}
