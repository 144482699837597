.bottom-operation {
  width: 464px;
  height: 83px;
  // background: #FFFFFF;
  // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 24px 24px 0 0;
  position: fixed;
  bottom: 0;
  right: 0;
  // left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  z-index: 99;

  .head-nav-start {
    margin-left: 32px;
    margin-right: 16px;
    // background: #FFFFFF;
    font-family: HelveticaNeue-Bold;
    font-size: 18px;
    color: #57606A;
    letter-spacing: 0;
    text-align: center;
    line-height: 36px;
    background: #F6F8FA;
    border-radius: 24px;
    cursor: pointer;
    .ant-btn {
      width: 144px;
      height: 36px;
      border-radius: 18px ;
      // box-shadow: 0 2px 4px 0 rgba(22, 173, 253, 0.04), 0 8px 16px 0 rgba(22, 173, 253, 0.16);
      font-family: HelveticaNeue-Bold;
      font-size: 18px;
      color: #57606A;
      border: none;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 8px;
      background: #F6F8FA !important;

      &:hover {
        color: #57606A !important;
      }
    }
  }

  // .head-nav-title {
  //   // width: 100%;
    
  // }

  .head-nav-end {
    margin-right: 32px;
    
    font-family: HelveticaNeue-Medium;
    font-size: 16px;
   
    letter-spacing: 0;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    .ant-btn {
      width: 144px;
      height: 36px;
      background: #16ADFD;
      box-shadow: 0 2px 4px 0 rgba(22, 173, 253, 0.04), 0 8px 16px 0 rgba(22, 173, 253, 0.16);
      border-radius: 18px;
      color: #FFFFFF;
      &:hover {
        color: #FFFFFF !important;
        background: #16ADFD !important;
      }
    }
  }
  .Icon-detail {
    margin-left: 8px;
  }
}

.bottom-operation-txt {
  width: 100%;
  height: 56px;
  position: fixed;
  bottom: 66px;
  padding-left: 35px;
   p {
    width: 400px;
    height: 56px;
    opacity: 0.75;
    background: #000000;
    border-radius: 8px;
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 25px;
   }
}