@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065: #5E6065;
@variable-37352F: #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD: #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white: #fff;

.ant-drawer-right>.ant-drawer-content-wrapper:has(.right-popup){
  box-shadow: none;
  //box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
}

.right-popup.page-storge-con {
  background: @variable-white !important;

  // height: calc(100% - 48px);

  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
      padding: 0 16px;
  }
}

.page-storage{
  height: 100%;

  .adm-button{
    line-height: normal;
  }

  .page-head-container{
    // padding: 0 16px;
    border-bottom: 1px solid #DFDFE1;
    height: 57px;
  }

  .details-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    p:nth-child(2) {
        font-family: HelveticaNeue-Bold;
        font-size: 20px;
        color: @variable-1E2128;
        letter-spacing: 0;
        text-align: center;
    }

    p:last-child {
        width: 44px;
        height: 44px;
        background-color: #F6F8FA;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
    }
}
  .page-body-container{
    // padding: 30px 0px;
    // padding-bottom: 30px;
    margin-top: 30px;
    box-sizing: border-box;
    // height: calc(100% - 57px);
    overflow-y: auto;
    background: #fff;
    border: 1px solid #EAEEF2;
    border-bottom: none;
    border-radius: 12px;
    div:last-child {
      margin-bottom: 0 !important;
    }
    .a-card-i {
     
      background-color: #F6F8FA;
      border-radius: 12px;
    }

  }

  // .adm-progress-bar-fill{
  //   background-color: #16ADFD !important;
  // }

  .a-inner-title{
    margin-bottom: 16px;

    .a-inner-icon{
      background: #00AAEE;
      //border-radius: 24px;
      border-radius: 5.5px;
      display: inline-block;
      height: 14px;
      width: 3px;
      margin-right: 10px;
    }

    .a-inner-label{
      font-family: HelveticaNeue-Medium;
      font-size: 18px;
      color: #1E2128;
      letter-spacing: 0;
    }
  }

  .a-card-i{
    // background: #F6F8FA;
    //box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04), 0 8px 16px 0 rgba(0,0,0,0.08);
    border-radius: 12px;
    padding-bottom: 12px;
    // margin-top: 30px;
  }

  .a-card-ii{
    background: #FFFFFF;
    //box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04), 0 8px 16px 0 rgba(0,0,0,0.08);
    border-radius: 12px;
    padding: 20px 16px;
    // border: 1px red solid;
    position: relative;
    border: 1px solid #EAEEF2;
    border-left: none;
    border-right: none;
  }

  .a-tag{
    position: absolute;
    top: -13px;
    right: -35px;
    transform: rotate(45deg) scale(0.8);
    //outline: #16ADFD 1px solid;
    width: 100px;
    height: 53px;
    line-height: 18px;
    text-align: center;
    //box-shadow: 0 0 6px 1px rgba(0,0,0,0.25);
    box-sizing: border-box;
    padding-top: 35px;
  }

  .a-list{
    padding-top: 5px;
    .a-item{
      padding: 16px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    .a-flex-i{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .a-icon{
      margin-right: 16px;

      img{
        vertical-align: bottom;
      }
    }

    .a-label{
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #1E2128;
      letter-spacing: 0;
    }

    .a-size{
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #5E6065;
      letter-spacing: 0;
      text-align: right;
    }
  }

  .a-plan-name{
    font-family: HelveticaNeue-Bold;
    font-size: 18px;
    color: #1E2128;
    letter-spacing: 0;
    margin-bottom: 20px;
  }

  .a-progress-bar{
    margin-bottom: 6px;
    margin-top: 12px;
  }

  .a-bar-size{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #5E6065;
    letter-spacing: 0;
    margin-bottom: 24px;
  }

  .a-plan-intro{
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #5E6065;
    letter-spacing: 0;
    line-height: 18px;
  }

  .a-button-i{
    border-top: 1px solid #DFDFE1;
    font-family: HelveticaNeue-Medium;
    font-size: 16px;
    color: #57606A;
    letter-spacing: 0;
    text-align: center;
    // padding: 19px;
    padding: 19px 0 6px 0;
    cursor: pointer;
    color: #57606A;
  }

  .-resub{
    margin-top: -29px;
  }

  .a-button-ii{
    border-top: 1px solid #DFDFE1;
    font-family: HelveticaNeue-Medium;
    font-size: 16px;
    color: #16ADFD;
    letter-spacing: 0;
    text-align: center;
    padding: 19px;
    margin: 25px -16px -21px;
  }

  .a-con-i{
    padding: 0 16px 24px;
  }

  .a-flex-ii{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .a-hot-i{
    font-family: HelveticaNeue-Medium;
    font-size: 10px;
    color: #6236FF;
    letter-spacing: 0;

    border-radius: 10px;
    background: rgba(98,54,255,0.1);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 106px;

    margin-bottom: 6px;

    img{
      margin-right: 6px;
      vertical-align: bottom;
    }
  }

  .a-btn-con{

    button{
      border-radius: 18px;

      font-family: HelveticaNeue-Medium;
      font-size: 16px;

      letter-spacing: 0;
      text-align: center;
      border: 0;
      outline: 0;
      width: 120px;
      height: 36px;

      &.a-default-btn{
        color: #5E6065;
        background: transparent;
        border: 1px solid #DFDFE1;
      }

      &.a-primary-btn{
        color: #FFFFFF;
        background: #16ADFD;
        border: 1px solid #16ADFD;
        box-shadow: 0 2px 4px 0 rgba(22,173,253,0.04), 0 8px 16px 0 rgba(22,173,253,0.16);
      }
    }
  }

  .a-price-con{


  }

  .a-price-num{
    margin-bottom: 4px;
  }

  .a-price-xiff{
    font-family: HelveticaNeue-Medium;
    font-size: 12px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 2px;
  }

  .a-price-nui{
    font-family: HelveticaNeue-Bold;
    font-size: 18px;
    color: #000F00;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 2px;
  }

  .a-price-unit{
    font-family: HelveticaNeue-Medium;
    font-size: 12px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 12px;
  }

  .a-price-desc{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #000029;
    letter-spacing: 0;
    line-height: 10px;
  }

  .a-impo{
    color: #ff3141;
  }

  .a-intro-ii{
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #5E6065;
    letter-spacing: 0;

    margin-bottom: 21px;
  }


  .a-card-iii{
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04), 0 8px 16px 0 rgba(0,0,0,0.08);
    border-radius: 18px;

    margin-top: 25px;

    padding: 20px 0 0;
    
    .a-plan-name{
      margin-bottom: 6px;
    }
  }

  .-note{
    padding: 15px 0 0;
    font-size: 12px;
    text-align: left;
    color: #16ADFD;
  }

  .--flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .c-plan-item{
    background: #FFFFFF;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 14px 0 0;

    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    border: 1px #EAEEF2 solid;
    border-left: none;
    border-right: none;
    .c-box{
      box-sizing: border-box;
      padding: 0 16px 12px;
    }

    .c-h1{
      font-family: HelveticaNeue-Medium;
      font-size: 16px;
      color: #1E2128;
      letter-spacing: 0;
      margin-bottom: 9px;
    }

    .c-row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;

      &:nth-last-of-type(1){
        margin-bottom: 0;
      }
    }

    .c-lb{
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #57606A;
      letter-spacing: 0;
    }

    .c-val{
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #24292F;
      letter-spacing: 0;
      text-align: right;
    }

    .c-btn{
      border-top: 1px solid #EAEEF2;
      height: 48px;
      line-height: 48px;
      box-sizing: border-box;

      font-family: HelveticaNeue-Medium;
      font-size: 16px;
      color: #16ADFD;
      letter-spacing: 0;
      text-align: center;

      cursor: pointer;
    }

    .c-tag{
      position: absolute;
      top: -13px;
      right: -35px;
      transform: rotate(45deg) scale(0.8);
      width: 100px;
      height: 53px;
      line-height: 18px;
      text-align: center;
      box-sizing: border-box;
      padding-top: 35px;
    }
  }

  .--wa{
    font-family: HelveticaNeue-Medium;
    font-size: 12px;
    color: #57606A;
    letter-spacing: 0;
    line-height: 18px;

    b{
      font-family: HelveticaNeue-Bold;
      font-size: 18px;
      color: #16ADFD;
    }
  }

  .--wb{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #270000;
    letter-spacing: 0;
    line-height: 18px;
  }

  .--size-l{
    padding-left: 30px;
    padding-right: 30px;
  }

  .--fs-btn-a{
    font-family: HelveticaNeue-Medium;
    font-size: 16px;
    color: #57606A;
    letter-spacing: 0;
    text-align: center;
  }
}
