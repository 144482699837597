.settings-con {
  background: #F4F4F4;
  box-sizing: border-box;

  .content{
    box-sizing: border-box;
    padding: 8px 16px 0;
  }

  .ui-head-nav{
    padding:  19px 16px;
    border-bottom: 1px #DFDFE1 solid;
  }

  .--desc{
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #57606A;
    letter-spacing: 0;
    line-height: 16px;

    padding: 11px 0 13px;
  }

  .--desc-a{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #57606A;
    letter-spacing: 0;
    line-height: 16px;

    padding: 11px 0 13px;
  }

  .--wds{
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #24292F;
    letter-spacing: 0;
    line-height: 16px;
  }

  .--flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 16px;
  }

  .adm-space-item{
    margin-bottom: 0;
    padding: 10px 0;
  }

  .adm-switch-handle{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: -10px;
    left: 0;
  }

  .adm-switch.adm-switch-checked .adm-switch-handle{
    left: calc(100% - 24px);
  }

  .content-title {
    //margin-top: 24px;
    font-family: HelveticaNeue-Bold;
    font-size: 18px;
    color: #1E2128;
    letter-spacing: 0;
    position: relative;
    padding-left: 12px;
    font-weight: bold;

    &:after {
      content: "";
      position: absolute;
      width: 3px;
      height: 14px;
      background: #00AAEE;
      border-radius: 24px;
      top: 21px;
      left: 0;
    }
  }

  .settings-con {
    display: flex;
    background: #FFFFFF;
    //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    min-height: 64px;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    margin-bottom: 12px;


    p:nth-child(1) {
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #1E2128;
      letter-spacing: 0;
      line-height: 20px;
      width: 260px;

      span {
        font-family: HelveticaNeue;
        font-size: 12px;
        color: #000029;
        letter-spacing: 0;
      }
    }

    .adm-radio-content{
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #57606A;
    }

    .adm-radio-custom-icon{
      position: relative;
      top: 3px;
    }

    .adm-radio-checked{


      .adm-radio-content{
        color: #16ADFD;
      }

      .adm-radio-icon{
        border-color: #16ADFD;
        background-color: #FFFFFF;

        &::before{
          background-color: #16ADFD;
        }
      }
    }

    .adm-radio-icon{
      display: inline-block;
      border: 1px solid var(--adm-color-light);
      border-radius: var(--icon-size);
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      color: var(--adm-color-text-light-solid);

      position: relative;

      &::before{
        content: '';
        display: inline-block;
        height: 70%;
        width: 70%;
        border-radius: 50%;
        position: absolute;
        top: 15%;
        left: 15%;
      }

      .--checked{

      }
    }
  }
}
