.a-billing-history-con{

  .a-billing-history-item{
    padding: 15px 36px 15px 24px;
    box-sizing: border-box;

    border-bottom: 1px solid #EAEEF2;

    position: relative;
    overflow: hidden;

    &:hover{
      background-color: rgba(22,173,253,0.05);
    }
  }

  .a-status-tag{
    position: absolute;
    top: 0;
    right: 0;

    font-size: 12px;
    width: 90px;
    text-align: center;
    box-sizing: border-box;
    padding: 3px 5px;

    background: #16ADFD;
    color: white;

    transform-origin: center;
    transform: rotate(45deg) translate(25px, -13px);
  }

  .a1-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .a1-mrn-btm{
    margin-bottom: 5px;
  }

  .a-fs{
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #57606A;
    letter-spacing: 0;
    line-height: 14px;
  }

  .c-fs{
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #24292F;
    letter-spacing: 0;
    line-height: 18px;
  }

  .e-fs{
    font-family: HelveticaNeue-Medium;
    font-size: 18px;
    color: #24292F;
    letter-spacing: 0;
    text-align: right;
    line-height: 18px;
  }
}

.ant-drawer .ant-drawer-content-wrapper:has(.a-billing-history-drawer){
  width: 500px !important;
}

.a-billing-history-drawer{

  .ant-drawer-title{
    font-family: HelveticaNeue-Bold;
    font-size: 20px;
    color: #24292F;
    letter-spacing: 0;
    text-align: center;
  }

  .ant-drawer-body{
    padding: 12px 0 24px 0;
  }
}
