.sort-by-modal {
    .adm-popup-body {
      background: #FFFFFF;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
      // border-radius: 24px 24px 0 0;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      height: auto;
      padding-bottom: 56px;
      // padding: 0 24px;
      .popup-title {
        font-family: HelveticaNeue-Medium;
        font-size: 20px;
        color: #1E2128;
        letter-spacing: 0;
        line-height: 20px;
        margin: 27px 24px 0;
      }
      .popup-item {
        display: flex;
        padding: 15px 24px 15px;
        align-items: center;
        margin-top: 8px;
        p{
          margin-left: 15px;
          font-family: HelveticaNeue;
          font-size: 16px;
          color: #1E2128;
          letter-spacing: 0;
        }
      }
      .popup-item:nth-child(3) {
        // border-bottom:1px solid #F4F4F4;
      }
      .popup-item-active {
        background: #F4F4F4;
      }
    }
  }