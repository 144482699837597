.payment-result-page{
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 0 24px 100px;

  .t-box{
    text-align: center;
    box-sizing: border-box;
    padding: 36px 0;

    .-icon{
      margin-bottom: 5px;
    }

    .h1{
      font-size: 36px;
      margin-bottom: 25px;
    }

    .w1{
      font-size: 18px;
    }
  }

  .b-box{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .row-item{
      display: flex;
      flex-direction: row;
      background-color: white;
      box-sizing: border-box;
      padding: 16px;
      border-radius: 12px;
      align-items: center;

      margin-bottom: 12px;
    }

    .ico-box{
      margin-right: 36px;
    }

    .name-box{
      font-size: 18px;
    }
  }

  .re-btn{
    background: #16ADFD;
    color: white;
    font-size: 20px;
    text-align: center;
    padding: 16px;
    border-radius: 30px;

    cursor: pointer;

    position: absolute;
    bottom: 35px;
    width: calc(100% - 48px);
    left: 50%;
    transform: translateX(-50%);
    max-width: 260px;
  }

  .loading-status-box{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 100px 0 0;
  }
}
