.forgot-password-con {
  width: 100%;
  // height: 100vh;
  height: calc(100vh - 60px);
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  .login-logo {
    margin-top: 100px;
    margin-bottom: 35px;

    img{
      vertical-align: bottom;
    }

    p:nth-child(1) {
      display: flex;
      justify-content: center;

      margin-bottom: 15px;
    }

    p:nth-child(2) {
      font-family: HelveticaNeue-Medium;
      font-size: 30px;
      color: #24292F;
      letter-spacing: 0;
      text-align: center;
      line-height: 30px;
      //position: relative;
      //top: 20px
    }
  }

  background-image: linear-gradient(180deg, #DDF4FF 0%, #DDF4FF 38%, #F6F8FA 82%, #F6F8FA 100%);

  .login-con {
    height: auto;

    .resend {
      font-family: HelveticaNeue;
      font-size: 18px;
      color: #16ADFD;
      letter-spacing: 0;
      text-align: right;
      position: absolute;
      top: 10px;
      right: 20px;
    }

    .border-pass {
      .ant-input {
        border: none !important;
      }
    }
  }

  .login-form {
    width: 418px;
    height: auto;

    background: #FFFFFF;
    border-radius: 16px;
    padding: 0 32px 32px 32px;
    overflow: hidden;

    .login-form-title {
      font-family: HelveticaNeue-Bold;
      font-size: 30px;
      color: #24292F;
      letter-spacing: 0;
      text-align: center;
      margin-top: 30px;
    }

    .forgot-password {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #57606A;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin-top: 8px;
    }

    .login-form-footer {
      text-align: center;
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #57606A;
      letter-spacing: 0;
      text-align: center;
      line-height: 18px;
    }

    .login-button-active {
      background: #16ADFD !important;
      margin-top: 30px;
      background: #D0D7DE;
      border-radius: 24px;
      font-family: HelveticaNeue-Medium;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      height: 48px;
      border: none;
      width: 100%;
      margin-top: 24px;

      .ant-button {
        width: 100%;
      }

      &:hover {
        background: #16ADFD !important;
        color: #fff !important;
      }
    }

    .login-button {
      margin-top: 30px;
      background: #D0D7DE;
      border-radius: 24px;
      font-family: HelveticaNeue-Medium;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      height: 48px;
      border: none;
      width: 100%;
      margin-top: 24px;

      .ant-button {
        width: 100%;

      }

      &:hover {
        background: #D0D7DE !important;
        color: #FFFFFF !important;
      }

    }

    .login-email {
      margin-top: 30px;

      div:nth-child(1) {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #57606A;
        letter-spacing: 0;
        line-height: 24px;
      }

      div:nth-child(2) {
        font-family: HelveticaNeue-Medium;
        font-size: 18px;
        color: #16ADFD;
        letter-spacing: 0;
        line-height: 24px;
      }

      div:nth-child(3) {
        margin-top: 12px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #57606A;
        letter-spacing: 0;
        line-height: 24px;
      }

      div:nth-child(4) {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;

        p {
          .ant-input {
            width: 48px;
            height: 56px;
            background: #FFFFFF;
            border: 1px solid #D0D7DE;
            border-radius: 3px;
            font-family: HelveticaNeue-Bold;
            font-size: 30px;
            color: #24292F;
            letter-spacing: 0;
            text-align: center;
          }
        }
      }
    }

    .login-txt {
      margin-top: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p:nth-child(1) {
        font-family: HelveticaNeue;
        font-size: 14px;
        color: #16ADFD;
        letter-spacing: 0;
        text-decoration: underline;
      }

      p:nth-child(2) {
        font-family: HelveticaNeue;
        font-size: 14px;
        color: #57606A;
        letter-spacing: 0;
        text-align: right;
        line-height: 20px;
        text-decoration: underline;
      }
    }

    .login-up {
      margin-top: 14px;

      p {
        position: relative;
      }

      P:nth-child(1) {
        font-family: HelveticaNeueCE-Roman;
        font-size: 16px;
        color: #24292F;
        letter-spacing: 0;
        line-height: 24px;
      }

      p:nth-child(2), p:nth-child(3) {
        font-family: HelveticaNeueCE-Roman;
        font-size: 14px;
        color: #57606A;
        letter-spacing: 0;
        line-height: 24px;
        position: relative;
        margin-left: 16px;

        &::after {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          opacity: 0.5;
          background: #1E2128;
          left: -16px;
          top: 10px;
          border-radius: 50%;
        }
      }
    }

    .ant-form {
      margin-top: 30px;

      .ant-input {
        width: 100%;
        height: 48px;
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #8C959F;
        letter-spacing: 0;
        background: #FFFFFF;
        border: 1px solid #D0D7DE;
        border-radius: 3px;
      }
    }


  }

 


}
.login-footer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  // position: absolute;
  // left: 0;
  // bottom: 0;

  div {
    p {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #57606A;
      letter-spacing: 0;
      text-align: center;
      line-height: 18px;
      margin-left: 24px;
    }
  }

  div:nth-child(2) {
    display: flex;

    p:nth-child(1), p:nth-child(2) {
      margin-left: 24px;
    }
  }

  .a-link-sty a {
    color: #57606A;
  }
}

@media screen and (max-width: 600px) {
  body {
    margin: 0;
    padding: 0;
  }

  .forgot-password-con {
    width: 100%;
    height: 100vh;
    // padding-bottom: 200px;
    overflow: hidden;

    .logo {
      font-size: 116px !important;
    }
  }

  .forgot-password-con .login-logo p:nth-child(2) {
    font-size: 14px;
    margin-top: 14px;
  }

  .forgot-password-con .login-form {
    width: 90% !important;
    padding: 16px;
  }

  .forgot-password-con .login-form .login-form-title {
    font-size: 14px;
    margin-top: 16px;
  }

  .forgot-password-con .login-form .forgot-password {
    font-size: 12px;
  }

  .forgot-password-con .login-form .ant-form .ant-input {
    // height: 35px;
    // line-height: 35px;
  }

  .forgot-password-con .login-form .login-button, .forgot-password-con .login-form .login-button-active {
    // height: 35px;
    // line-height: 35px;
    margin-top: 0;
  }

  .login-footer div p {
    font-size: 12px;
    margin-left: 0 !important;
  }

  .login-footer div:nth-child(2) p:nth-child(1), .login-footer div:nth-child(2) p:nth-child(2) {
    margin-left: 6px;
  }

  .login-footer {
    flex-direction: column;
  }

  .forgot-password-con .login-logo {
    margin-top: 16px;
  }

  .forgot-password-con .login-con {
    margin-top: -24px;
  }

  .forgot-password-con .login-con .resend {
    top: 3px
  }
}
