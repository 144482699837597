@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065: #5E6065;
@variable-37352F: #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-16ADFD: #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white: #fff;

.security-subscribe-modal-lj {
  // width: 464px !important;
  background-color: @variable-EAEEF2 !important;

  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
    padding: 0 16px;
  }

  .set-meal {
    border: 1px solid rgba(255, 255, 255, 0.50);
    border-radius: 12px;
    height: 108px;
    margin-top: 24px;
    display: flex;
    background: @variable-white;

    div {
      flex: 1;
      width: 100%;
      padding: 0 8px;
      cursor: pointer;

      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: @variable-24292F;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        margin-top: 4px;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 12px;
        color: @variable-57606A;
        letter-spacing: 0;
        line-height: 20px;

        span:nth-child(2) {
          margin-left: 14px;
        }
      }
    }

    .active {
      background: @variable-16ADFD;
      border-radius: 12px 0 0 12px;

      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: @variable-white;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        margin-top: 4px;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 12px;
        color: @variable-white;
        letter-spacing: 0;
        line-height: 20px;

        span:nth-child(1) {
          svg {
            g {
              fill: @variable-white;
            }
          }
        }

        span:nth-child(2) {
          margin-left: 14px;
        }
      }
    }

    .vault-active1 {
      background: @variable-16ADFD;
      border-radius: 0 12px 12px 0;

      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: @variable-white;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        margin-top: 4px;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 12px;
        color: @variable-white;
        letter-spacing: 0;
        line-height: 20px;

        span:nth-child(1) {
          svg {
            g {
              fill: @variable-white;
            }
          }
        }

        span:nth-child(2) {
          margin-left: 14px;
        }
      }
    }

    .active-premium {
      background: @variable-16ADFD;
      // border-radius: 0 12px 12px 0;
      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: @variable-white;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        margin-top: 4px;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 12px;
        color: @variable-white;
        letter-spacing: 0;
        line-height: 20px;

        span:nth-child(1) {
          svg {
            g {
              fill: @variable-white;
            }
          }
        }

        span:nth-child(2) {
          margin-left: 14px;
        }
      }
    }

    .active-platium {
      background: @variable-16ADFD;
      border-radius: 0 12px 12px 0;

      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: @variable-white;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        margin-top: 4px;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 12px;
        color: @variable-white;
        letter-spacing: 0;
        line-height: 20px;

        span:nth-child(1) {
          svg {
            g {
              fill: @variable-white;
            }
          }
        }

        span:nth-child(2) {
          margin-left: 14px;
        }
      }
    }
  }

  .details-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    p:nth-child(2) {
      font-family: HelveticaNeue-Bold;
      font-size: 20px;
      color: @variable-1E2128;
      letter-spacing: 0;
      text-align: center;
    }

    p:last-child {
      width: 44px;
      height: 44px;
      background-color: @variable-white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .my-subscriptions-con {
    margin-top: 24px;

    .my-subscriptions-card {
      width: 100%;
      height: auto;
      background: @variable-white;
      border-radius: 12px;

      div:nth-child(1) {
        padding: 14px 25px 0;

        h4 {
          font-family: HelveticaNeue-Medium;
          font-size: 16px;
          color: @variable-1E2128;
          letter-spacing: 0;
          font-weight: bold;
        }
      }

      div {
        padding: 0 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 25px;

        p:nth-child(1) {
          font-family: HelveticaNeue;
          font-size: 14px;
          color: @variable-57606A;
          letter-spacing: 0;
        }

        p:nth-child(2) {
          font-family: HelveticaNeue-Medium;
          font-size: 14px;
          color: @variable-24292F;
          letter-spacing: 0;
          font-weight: bold;
        }
      }

      div:last-child {
        margin-top: 14px;
        padding: 0 !important;
        width: 100%;
        border-top: 1px solid @variable-EAEEF2;
        height: 40px;
        line-height: 40px;
        font-family: HelveticaNeue-Medium;
        font-size: 14px;
        color: @variable-16ADFD;
        letter-spacing: 0;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .cloud-device {
    margin-top: 29px;

    .cloud-device-title {
      display: flex;
      justify-content: space-between;

      p:nth-child(1) {
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: @variable-24292F;
        letter-spacing: 0;
        line-height: 16px;
        position: relative;
        margin-left: 10px;

        &::after {
          content: "";
          position: absolute;
          left: -10px;
          top: 2px;
          width: 3px;
          height: 12px;
          background: @variable-16ADFD;
          border-radius: 24px;
          border-radius: 5.5px;
        }
      }

      p:nth-child(2) {
        font-family: HelveticaNeue-Medium;
        font-size: 12px;
        color: @variable-16ADFD;
        letter-spacing: 0;
        text-align: right;
        line-height: 12px;
        text-decoration: underline;
      }
    }
  }

  .cloud-device-card {
    margin-top: 12px;
    background: @variable-white;
    border-radius: 8px;
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cloud-device-card-l {
      display: flex;
      margin-left: 6px;
      align-items: center;

      p:nth-child(1) {
        img {
          width: 60px;
          height: 60px;
        }
      }

      p:nth-child(2) {
        //margin-left: 12px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: @variable-1E2128;
        letter-spacing: 0.19px;
      }
    }

    .cloud-device-card-r {
      margin-right: 16px;
    }
  }

  .cloud-features {
    margin-top: 30px;

    .cloud-features-title {
      font-family: HelveticaNeue-Medium;
      font-size: 16px;
      color: @variable-24292F;
      letter-spacing: 0;
      line-height: 16px;
      position: relative;
      margin-left: 10px;

      &::after {
        content: "";
        position: absolute;
        left: -10px;
        top: 2px;
        width: 3px;
        height: 12px;
        background: @variable-16ADFD;
        border-radius: 24px;
        border-radius: 5.5px;
      }
    }

    .cloud-features-con {
      background: #FFFFFF;
      border-radius: 8px;
      margin-top: 12px;

      .security-plan {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px #EAEEF2 solid;

        .security-plan-l {
          p {
            font-family: HelveticaNeue;
            font-size: 16px;
            color: #5E6065;
            letter-spacing: 0;
            line-height: 20px;
          }
        }

        .security-plan-r {
          p {
            font-family: HelveticaNeue-Medium;
            font-size: 16px;
            color: #24292F;
            letter-spacing: 0;
            text-align: right;
            line-height: 20px;
          }
        }
      }

      .plan-select {
        padding: 8px 16px 16px 16px;

        .plan-select-item-active {
          background: #FFFFFF;
          border: 2px solid #16ADFD !important;
          box-shadow: 0 8px 16px 0 rgba(22, 173, 253, 0.16);
        }

        .plan-select-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;
          height: 64px;
          border: 1px solid #D0D7DE;
          border-radius: 4px;
          margin-top: 8px;
          box-sizing: border-box;

          .plan-select-con-l {
            p {
              font-family: HelveticaNeue;
              font-size: 16px;
              color: #24292F;
              letter-spacing: 0;
            }
          }

          .plan-select-con-r {
            p:nth-child(1) {
              font-family: HelveticaNeue-Medium;
              font-size: 16px;
              color: #24292F;
              letter-spacing: 0;
              text-align: right;
            }

            p:nth-child(2) {
              font-family: HelveticaNeue;
              font-size: 12px;
              color: #5E6065;
              letter-spacing: 0;
              text-align: right;

              span {
                font-family: HelveticaNeue;
                font-size: 12px;
                color: #16ADFD !important;
                letter-spacing: 0;
                text-align: right;
                line-height: 12px;
              }
            }
          }
        }
      }
    }

  }

  .policy, .note {
    margin-top: 20px;

    h4 {
      font-family: HelveticaNeue-Medium;
      font-size: 14px;
      color: #24292F;
      letter-spacing: 0;
      line-height: 20px;
    }

    p {
      font-family: HelveticaNeue-Roman;
      font-size: 12px;
      color: #5E6065;
      letter-spacing: 0;
      line-height: 20px;
    }

    a{
      color: #5E6065;
      text-decoration: underline;
    }
  }

  .footer-button {
    padding: 32px 24px;

    .ant-btn {
      width: 100%;
      background: #16ADFD;
      border-radius: 44px;
      height: 44px;
      font-family: HelveticaNeue-Bold;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;

      &:hover {
        background: #16ADFD !important;
        color: #FFFFFF !important;
      }
    }
  }

  .a-front-point{
    position: relative;
    box-sizing: border-box;
    padding-left: 9px;

    &::before{
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      background: #5E6065;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 7px;
    }
  }
}
