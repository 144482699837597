@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065: #5E6065;
@variable-37352F: #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD: #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white: #fff;
.ant-drawer-open {
  z-index: 999;
}
.page-storge-con {
  background: @variable-white !important;
  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
      padding: 0 32px !important;
  }
}

.page-clean-up-medrive{
  height: 100%;
  // background: #F4F4F4;

  .page-head-container{
    padding: 0 16px;
    border-bottom: 1px solid #DFDFE1;
    height: 57px;
  }

  .page-body-container{
    padding: 30px 24px;
    box-sizing: border-box;
    height: calc(100% - 57px);

    overflow-y: auto;
  }
  .page-body-container-tom {
    padding : 0!important;
    border: none !important
  }

  .a-title-i{
    font-family: HelveticaNeue-Bold;
    font-size: 24px;
    color: #1E2128;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .a-desc-i{
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #5E6065;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
  }

  .a-card-iii{
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04), 0 8px 16px 0 rgba(0,0,0,0.08);
    border-radius: 18px;
    overflow: hidden;

    margin-top: 25px;

    padding: 22px 0 0;

    .a-plan-name{
      margin-bottom: 6px;
    }
  }
  .a-card-iii-tom {
    background: #FFFFFF;
    border: 1px solid #EAEEF2;
    border-radius: 12px;
  }

  .a-button-i{
    background: #F4F4F4;

    font-family: HelveticaNeue-Medium;
    font-size: 16px;
    color: #16ADFD;
    letter-spacing: 0;
    text-align: center;

    padding: 19px;
  }
  .a-button-i-tom {
    background-color: #fff !important;
    padding: 16px !important;
    color: #16ADFD !important;
  }

  .a-con-i{
    padding: 0 16px 24px;

    .a-card-list{
      padding: 17px 0 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .a-card-item{
      background: #F4F4F4;
      border-radius: 6px;
      width: 56px;
      height: 56px;

      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin: 2px;
    }

    .adm-image{
      width: 100%;
      height: 100%;

      .adm-image-img{
        width: 100%;
        height: 100%;
      }
    }

    .a-card-name{
      font-family: HelveticaNeue-Medium;
      font-size: 20px;
      color: #1E2128;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 2px;
    }

    .a-intro-ii{
      font-family: HelveticaNeue;
      font-size: 12px;
      color: #000029;
      letter-spacing: 0;
      line-height: 18px;

      span{
        //font-family: HelveticaNeue-Medium;
        font-size: 12px;
        color: #16ADFD;
        letter-spacing: 0;
        text-align: center;
      }
    }
    .a-intro-ii-tom {
      margin-bottom: 0 !important;
    }
  }
}

.details-modal-back {
   p:nth-child(1) {
    width: 44px;
    height: 44px;
    background-color: #F6F8FA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
     img {
       transform: rotate(270deg);
     }
   }
   
}