@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065: #5E6065;
@variable-37352F: #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD: #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white: #fff;

.page-storge-con {
  // margin-top: 48px;
  background: @variable-white !important;
  .ant-drawer-header {
    display: none;
  }
  .head-nav-title1 {
    display: flex;
    border-bottom: 1px #DDE2E8 solid;
    div {
      font-family: HelveticaNeue;
      font-size: 14px;
      
      letter-spacing: 0;
      p {
        span {
          color: #16ADFD;
        }
      }
    }
  }
   

  .ant-drawer-body {
      padding: 0 32px;
  }
}
.vault-head-contain-tom {
  position: sticky;
    top: 190px;
    z-index: 1;
    background: #fff;
}

.page-storge-con-tom {
   .ant-drawer-body {
     padding: 0 !important;
   }
   .details-modal-title {
     padding: 0 24px;
   }
   .head-nav-title1 {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 16px;
    div:nth-child(1), div:nth-child(6), div:nth-child(12) {
      margin-left: 24px;
    }
    div:nth-child(n+6), div:nth-child(n+12){
      margin-top: 12px;
    }
    
   }
}

.middle-page {
  // max-width: 750px;
  // height: 100vh;
  height: auto;
  margin: 0 auto;
  overflow-y: auto;
  min-height: 500px;
  //border: 1px #ccc solid;
  position: relative;
 


  .hd-box{
    height: 61px;
    margin-top: 32px;
    .ui-head-nav{
      padding: 19px 16px;
      border-bottom: 1px #DFDFE1 solid;
    }
  }

  .mn-box{
    height: calc(100% - 61px);
    box-sizing: border-box;

    &.mn-ht{
      padding-bottom: 83px;
    }

    .mn-list{
      height: calc(100% - 35px);
      overflow-y: auto;
    }
  }

  .header {
    width: 100%;
    height: 52px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    border-bottom: 1px #F4F4F4 solid;

    .header-menu {
      img {
        width: 20px;
        margin-left: 16px;
        vertical-align: bottom;
      }
    }

    .header-txt {
      margin-left: 15px;
      font-family: HelveticaNeue-Bold;
      font-size: 24px;
      color: #1E2128;
      letter-spacing: 0;
    }


  }

  .header-search {
    // width: 305px;

    height: 32px;
    opacity: 0.6;
    background: #DFDFE1;
    border-radius: 16px;
    position: relative;
    margin: 12px 16px 16px;

    img {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      left: 16px;
    }

    .header-search-input {
      width: 100%;
      height: 32px;
      background-color: transparent;
      letter-spacing: 0;

      .adm-input-element {
        text-indent: 38px;
      }

      input::-webkit-input-placeholder {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
      }
    }

  }

  .header-pause {
    background: #1E2128;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -8px;

    p {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 16px;
    }

    .adm-button {
      width: 96px;
      height: 24px;
      background: #F4F4F4;
      border-radius: 12.5px;
      line-height: 8px;

      span {
        font-family: HelveticaNeue-Medium;
        font-size: 12px;
        color: #16ADFD;
        letter-spacing: 0;
      }
    }
  }

  .upgrade-storage {
    background: #1E2128;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -8px;

    p:nth-child(1) {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 16px;

      span {
        font-family: HelveticaNeue;
        font-size: 14px;
        color: #16ADFD;
        letter-spacing: 0;
        text-decoration: underline;
      }
    }

    p:nth-child(2) {
      color: #fff;
      font-size: 18px;
      font-family: HelveticaNeue;
      font-weight: bold;
    }
  }

  .add {
    position: fixed;
    right: 12px;
    bottom: 12px;

    img {
      width: 56px;
    }
  }
}

.header-search-input:focus-visible {
  border-radius: 16px;
  outline: 1px #DFDFE1 solid;
  /* opacity: 0; */
}


.md-content {
  // padding: 28px 0 0px;
  box-sizing: border-box;
  height: 100%;
  .md-content-input {
    width: 90%;
    height: 36px;
    border-radius: 4px;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-left: 6px;
      cursor: pointer;
    }
    .ant-input  {
      width: 80%;
      border: 1px solid #16ADFD;
      height: 36px;
      margin-left: 24px;
      text-indent: 0px;
      margin-right: 12px;
    }
  }
  .content-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 24px;

    div:nth-child(1) {
      display: flex;

      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 20px;
        color: #1E2128;
      }

      img {
        width: 14px;
        margin-left: 8px;
      }
    }

    div:nth-child(2) {
      img {
        width: 18px;
      }
    }
  }

  .content-data {
    margin: 107px auto 0;
    text-align: center;

    .content-data-img {
      width: 240px;
      height: 198px;
      margin: 0 auto;
    }

    .content-data-txt {
      h4 {
        margin-top: 12px;
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
        letter-spacing: 0;
        margin-top: 7px;
      }
    }
  }

  .content-two {
    margin-top: -12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 24px;

    .content-two-item {
      width: 152px;
      height: 152px;
      background: #FFFFFF;
      border-radius: 8px;
      margin-top: 24px;
      box-sizing: border-box;
      padding-top: 29px;

      .more {
        display: flex;
        justify-content: flex-end;
        margin: 12px;

        img {
          width: 24px;
        }
      }

      .file {
        display: flex;
        justify-content: center;

        img {
          width: 72px;
        }
      }

      .txt {
        margin-top: 12px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
        text-align: center;
        line-height: 18px;
        padding: 0 15px;
      }
    }
  }
  .mn-list-tom {
    .content-item {
      margin-left: 0 !important;
    }
  }

  .content-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-top: 33px;
    padding: 16px 24px;
    &:hover{
      background: #F6F8FA;
    }

    .content-item-type {
      display: flex;
      width: calc(100% - 60px);

      img {
        width: 32px;
      }

      .content-item-txt {
        margin-left: 16px;

        h4 {
          font-family: HelveticaNeue;
          font-size: 16px;
          color: #1E2128;
          line-height: 18px;
        }

        &>div {
          font-family: HelveticaNeue;
          font-size: 12px;
          color: #000029;
          line-height: 18px;
        }
      }
    }

    .content-item-more {
      img {
        width: 24px;
      }
    }
  }
}

.popup-con {
  .adm-popup-body {
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    // border-radius: 24px 24px 0 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: auto;
    padding-bottom: 56px;
    // padding: 0 24px;
    .popup-title {
      font-family: HelveticaNeue-Medium;
      font-size: 20px;
      color: #1E2128;
      letter-spacing: 0;
      line-height: 20px;
      margin: 27px 24px 0;
    }

    .popup-item {
      display: flex;
      padding: 15px 24px 15px;
      align-items: center;
      margin-top: 8px;

      p {
        margin-left: 15px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
      }
    }

    .popup-item:nth-child(3) {
      border-bottom: 1px solid #F4F4F4;
    }

    .popup-item-active {
      background: #F4F4F4;
    }
  }
}

.search-panel-box{
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  // position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  min-height: 100vh;
  margin-top: 32px;
  //pointer-events: none;

  // background-color: #F4F4F4;

  .-inpage{
    // max-width: 750px;
    height: 100%;
    height: 100%;
    margin: 0 auto;
    overflow-y: auto;
    position: relative;

    //border: 1px solid blueviolet;

    .-head-search-box{
      box-sizing: border-box;
      height: 60px;
      border-bottom: 1px #DFDFE1 solid;

      padding: 19px 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .-body-search-box{
      height: calc(100% - 61px);
      overflow-y: auto;
    }

    .-icon{
      width: 14px;
      margin-right: 20px;

      img{
        vertical-align: middle;
      }
    }

    .-input{
      box-sizing: border-box;
      padding-left: 40px;
      width: calc(100% - 34px);
      position: relative;
      border-radius: 16px;
      background-color: #FFFFFF;

      .adm-input-element{
        height: 32px;
        box-sizing: border-box;
        padding-right: 10px;

        font-family: HelveticaNeue;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
        line-height: 12px;
      }

      .-search-icon{
        position: absolute;
        top: 8.5px;
        left: 15px;

        img{
          vertical-align: middle;
        }
      }
    }
  }
}
