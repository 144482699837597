.large-files-modal {
    position: relative;
    .large-files-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        padding: 0 32px;
        z-index: 9;
       position: sticky;
        p:nth-child(1) {
            width: 44px;
            height: 44px;
            background-color: #F6F8FA;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                width: 18px;
                transform: rotate(270deg);
            }
        }
        p:nth-child(2) {
            font-family: HelveticaNeue-Bold;
            font-size: 20px;
            color: #1E2128;
            letter-spacing: 0;
            text-align: center;
        }
        p:last-child {
            width: 44px;
            height: 44px;
            background-color: #F6F8FA;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
        }
    }
    .ant-drawer-body {
        padding: 0 !important;
    }
    .content-title-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div:nth-child(1) {
            padding-left: 40px;
            cursor: pointer;
        }
        div:nth-child(2) {
            width: 11%;
        }
    }
    .large-files-modal-button {
        height: 44px;
        position: absolute;
        width: 100%;
        bottom: 20px;
        text-align: center;
        z-index: 1;
        .ant-btn {
            width: 90%;
            height: 44px;
            background: #16ADFD;
            border-radius: 44px;
            margin: 0 auto;
            font-family: HelveticaNeue-Bold;
            font-size: 18px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            &:hover {
                background: #16ADFD!important;
                color: #FFFFFF!important;
            }
        }
    }
    .content-item:hover {
        background: #16ADFD09 !important;
    }
    .large-files-modal-page {
        padding-bottom: 30px;
    }
}