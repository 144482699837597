@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065: #5E6065;
@variable-37352F: #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD: #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white: #fff;

.change-password-modal {
    background-color: @variable-white  !important;
    position: relative;
    .ant-drawer-header {
        display: none;
    }
    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-content {
        padding: 0 40px;
    }

    .change-password-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;
        p:nth-child(2) {
            font-family: HelveticaNeue-Bold;
            font-size: 20px;
            color: @variable-1E2128;
            letter-spacing: 0;
            text-align: center;
        }

        p:last-child {
            width: 44px;
            height: 44px;
            background-color: @variable-EAEEF2;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .change-password-modal-from {
        margin-top: 40px;
        
        .ant-input{
            height: 44px;
            background: @variable-white;
            // border: 1px solid @variable-D0D7DE;
            border-radius: 2px;
            .ant-picker-suffix {
                display: none;
            }

            font-family: HelveticaNeue;
            font-size: 14px;
            color: @variable-8C959F;
            letter-spacing: 0.58px;

            .ant-picker-input {
                input {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-8C959F;
                    letter-spacing: 0.58px;
                }
            }

            .ant-select-selector {
                border: none;
                position: relative;

                &::after {
                    background-color: @variable-D0D7DE;
                    content: "";
                    height: 44px;
                    width: 1px;
                    position: absolute;
                    top: 0;
                    right: 35px;
                    visibility: visible
                }
            }

            .ant-select-arrow {
                svg {
                    fill: #57606A;
                }
            }
        }
    }


    .change-password-modal-footer {
        width: 100%;
        height: 48px;
        padding-bottom: 24px;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        .ant-btn {
            width: 185px;
            border-radius: 24px;
            height: 48px;
            
        }
        .ant-btn:first-child {
            border: 1px solid @variable-D0D7DE;
            background: @variable-white;
            font-family: HelveticaNeue-Bold;
            font-size: 18px;
            color: @variable-57606A;
            letter-spacing: 0;
            text-align: center;
        }
        .ant-btn:last-child {
            background: @variable-16ADFD;
            color: @variable-white;
            font-family: HelveticaNeue-Bold;
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
            &:hover {
                background: @variable-16ADFD;
                color: @variable-white !important;
            }
        }
    }

}