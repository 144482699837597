@variable-bg: #16ADFD;
@variable-gray: #57606A;

.navContainer {
     width: 100%;
    //  padding-top: 8px;
    .navItemContianer {
        .ant-menu-inline {
            border-inline-end: none
        }
        .ant-menu-item {
            margin-inline: 0 !important;
            width: 100% !important;
            .ant-menu-title-content {
                font-family: HelveticaNeue;
                font-size: 14px;
                color: @variable-gray;
                letter-spacing: 0;
             }
        }
        .ant-menu, .ant-menu-item, .ant-menu-submenu {
            border-radius: 0 !important;
        }
        .ant-menu-item-selected {
             svg {
                g {
                    fill: @variable-bg;
                }
             }
             .ant-menu-title-content {
                font-family: HelveticaNeue;
                font-size: 14px;
                color: @variable-bg;
                letter-spacing: 0;
             }
        }
        
    }
}

:where(.css-dev-only-do-not-override-14qglws).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-14qglws).ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #57606A;
}