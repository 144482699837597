@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065:  #5E6065;
@variable-37352F:  #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-F6F8FA: #F6F8FA;
@variable-6E7781: #6E7781;
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD:  #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white:  #fff;
.ui-home-head-nav-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  // margin-top: 16px;
  padding-top: 40px;
  .--m-clr{
    color: #16ADFD;
  }

  .--back-btn-box{
    width: 50px;
    text-align: left;

    img{
      //user-select: none;
    }
  }

  .--dis-size-box{
    //width: calc(100% - 100px);
    // width: 65%;
    // max-width: 230px;
  }

  .--setting-box{
    width: 50px;
    text-align: right;
  }

  .--name{
    font-family: HelveticaNeue-Bold;
    font-size: 18px;
    color: #24292F;
    letter-spacing: 0;
    text-align: center;
    line-height: 18px;

    margin-bottom: 8px;
  }

  .--desc{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #24292F;
    letter-spacing: 0;
    text-align: center;
    line-height: 10px;
    transform: scale(0.85);
  }

  .--pro-box{
    margin-bottom: 2px;
  }

  .adm-progress-bar{
    --track-width: var(--adm-progress-bar-track-width, 6px);
  }

  .adm-progress-bar-trail{
    background: #D0D7DE;
    // .adm-progress-bar-fill {
    //    background-color: #16ADFD;
    // }
  }
}
.vault-all {
  font-family: HelveticaNeue-Bold;
  font-size: 30px;
  color: #24292F;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 40px;
}

.vault-con {
  .vault-con-title {
      padding-top: 32px;
      font-family: HelveticaNeue-Bold;
      font-size: 30px;
      color: @variable-24292F;
      letter-spacing: 0;
  }
  .vault-con-user-l {
    display: flex;
    .ant-btn {
      width: 192px;
      height: 48px;
      border-radius: 6px;
    }
    .a-upload-btn {
      // width: 192px;
      // height: 48px;
      width: 176px;
      height: 80px;
      border-radius: 6px;
      background: @variable-16ADFD;
      font-family: HelveticaNeue;
      font-size: 16px;
      color: @variable-white;
      letter-spacing: 0;
      display: flex;
      // align-items: center;
      flex-direction: column;
      cursor: pointer;
      .ant-upload-list {
        display: none;
      }
      .ant-upload-wrapper {
        width: 100%;
      }
      .ant-upload {
        border: none
      }
      .ant-upload-drag-container {
        background: @variable-16ADFD;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: @variable-white;
        letter-spacing: 0;
      }
      svg {
          path {
              fill: @variable-white;
          }
      }
    }
    //.ant-btn:nth-child(1) {
    //  background: @variable-16ADFD;
    //  font-family: HelveticaNeue;
    //  font-size: 16px;
    //  color: @variable-white;
    //  letter-spacing: 0;
    //  svg {
    //      path {
    //          fill: @variable-white;
    //      }
    //  }
    //}
    .ant-btn {
      background: @variable-white;
      font-family: HelveticaNeue;
      font-size: 16px;
      color: @variable-1E2128;
      letter-spacing: 0;
      width: 176px;
      height: 80px;
      border: 1px #8C959F dashed;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
      padding-top: 16px
    }
  }
  .vault-con-user-r {
    position: relative;
    div:nth-child(1) {
        // background: #D0D7DE;
       border-radius: 6px;
       width: 320px;
       height: 12px;
       position: relative;
       p {
        position: absolute;
        width: 40%;
        height: 12px;
        background: @variable-16ADFD;
        border-radius: 6px;
       }
    }
    div:nth-child(2) {
        margin-top: 9px;
        display: flex;
        justify-content: space-between;
        margin-left: 6px;
        p {
            font-family: HelveticaNeue;
            font-size: 12px;
            color: @variable-24292F;
            letter-spacing: 0;
            span {
                font-family: HelveticaNeue;
                font-size: 12px;
                color: @variable-16ADFD;
                letter-spacing: 0;
                text-align: center;
                margin-left: 12px;
            }
        }
    }

    .adm-progress-bar-fill{
      transition: all 0ms;
    }
    .management {
      cursor: pointer;
      border: 1px solid #D0D7DE;
      border-radius: 12px;
      padding: 2px 13px;
    }
    .no-subscription {
      position: absolute;
      top: -24px;
      left: 0;
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #24292F;
      letter-spacing: 0;
    }
   }
}

.vault-head-contain-x{
  position: sticky;
  top: 0;
  z-index: 9;
  background: #fff;

  .content-title {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 15px;
    // padding: 24px 24px 15px;
    padding: 24px 0 0 36px;
    box-sizing: border-box;
    height: 48px;
    position: relative;

    .controls {
      display: flex;
      position: absolute;
      left: 48px;
    }
    .select-all {
      margin-right: 20px;
      position: absolute;
      left: 0px;
      top: 24px;
      cursor: pointer;
    }
    .sort-download {
      margin-left: 48px;
      display: flex;
      align-items: center;
      line-height: 24px;
      position: relative;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        margin-left: 8px;
      }
    }
    .con-after {
      &::after {
        content: "";
        position: absolute;
        top: 6px;
        right: -18px;
        width: 2px;
        height: 12px;
        background-color: #D0D7DE;
      }

    }
    .move-to-trash {
      margin-left: 24px;
      display: flex;
      align-items: center;
      line-height: 24px;
      position: relative;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        margin-left: 8px;
      }
    }

    div:nth-child(1) {
      display: flex;

      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 18px;
        color: #1E2128;
      }

      img {
        width: 12px;
        //margin-left: 8px;
      }
    }

    div:nth-child(2) {
      img {
        width: 18px;
      }
    }
    .center-search {
      display: flex;
      .search-input {
        margin-right: 6px;
        position: relative;
        .ant-input {
          width: 256px;
          height: 36px;
          background: #FFFFFF;
          border: 1px solid #16ADFD;
          border-radius: 18px;
          font-family: HelveticaNeue;
          font-size: 16px;
          color: #24292F;
          letter-spacing: 0;
          line-height: 18px;
          text-indent: 28px;
        }
        img {
          position: absolute;
          top: 10px;
          left: 10px
        }
      }
      .search-cancel {
        cursor: pointer;
        img {
          width: 36px;
          height: 36px;
        }
      }
    }

  }
}

.large-file-page,
.trash-page,
.vault-starred-page,
.vault-home-page{

  .vault-head-contain{
    // top: 194px;
    // top: 245px;
    z-index: 9;
    position: sticky;
    // background-color: #EAEEF2;
    background-color: #fff;
  }

  .content-title{
    padding-top: 16px;
    padding-bottom: 16px;
    height: 70px;
    align-items: center;
  }
}

//.vault-starred-page{
//  .vault-head-contain{
//    top: 194px;
//  }
//}

.ant-btn-primary.ant-btn-solid{
  background: @variable-16ADFD;
}

.ant-btn-default.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-default.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover{
  color: @variable-16ADFD;
  border-color: @variable-16ADFD;
}

.ant-btn-primary.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):hover{
  background: @variable-16ADFD;
}
// .adm-progress-bar-fill {
//   background: @variable-16ADFD !important;
// }