.subscribe-modal-cvr{

  .cvr-sub-modal-main-box{
    height: calc(100vh - 90px);

    iframe{
      width: 100%;
      height: 100%;
    }
  }
}
