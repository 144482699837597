.navigation {
    display: flex;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div:nth-child(1) {
      margin-left: 0px;
    }
    div {
      margin-left: 16px;
      // padding: 0px 16px;
      width: 110px;
      height: 36px;
      background: #F6F8FA;
      border-radius: 18px;
      cursor: pointer;
      span {
        margin-left: 6px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #57606A;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
    .navigation-active {
      background: #24292F;
      span {
        color: #FFFFFF;
      }
    }
  }