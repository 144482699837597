@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065: #5E6065;
@variable-37352F: #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-16ADFD: #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white: #fff;

.change-cloud-device-modal {
  // width: 464px !important;
  background-color: @variable-EAEEF2 !important;

  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .details-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    box-sizing: border-box;
    padding: 0 32px;

    p:nth-child(2) {
      font-family: HelveticaNeue-Bold;
      font-size: 20px;
      color: @variable-1E2128;
      letter-spacing: 0;
      text-align: center;
    }

    p:last-child {
      width: 44px;
      height: 44px;
      background-color: @variable-white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .desc-info-com{
    text-align: left;
    box-sizing: border-box;
    //padding-top: 50px;

    box-sizing: border-box;
    padding: 0 32px;

    .-a{
      font-size: 12px;
      margin-bottom: 15px;
      font-family: HelveticaNeue;
    }

    .-b{
      font-size: 16px;
      font-family: HelveticaNeue-Medium;
      color: @variable-24292F;
    }
  }


  .cloud-device {
    margin-top: 30px;

    box-sizing: border-box;
    height: calc(100vh - 68px - 35px);

    .cloud-device-title {
      display: flex;
      justify-content: space-between;

      p:nth-child(1) {
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: @variable-24292F;
        letter-spacing: 0;
        line-height: 16px;
        position: relative;
        margin-left: 10px;

        &::after {
          content: "";
          position: absolute;
          left: -10px;
          top: 2px;
          width: 3px;
          height: 12px;
          background: @variable-16ADFD;
          border-radius: 24px;
          border-radius: 5.5px;
        }
      }
    }

    .txt {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: @variable-57606A;
      letter-spacing: 0;
      line-height: 18px;
      margin-top: 4px;
    }
  }

  .cloud-device-card {
    background: @variable-white;
    border-radius: 8px;
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 12px;

    &:nth-last-of-type(1){
      margin-bottom: 0;
    }

    .cloud-device-card-l {
      display: flex;
      margin-left: 6px;
      align-items: center;

      p:nth-child(1) {
        img {
          width: 60px;
          height: 60px;

          user-select: none;
        }
      }

      p:nth-child(2) {
        margin-left: 12px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: @variable-1E2128;
        letter-spacing: 0.19px;
      }
    }

    .cloud-device-card-r {
      margin-right: 16px;
      cursor: pointer;
    }
  }

  .sub-title-box{
    box-sizing: border-box;
    padding: 0 32px;
  }

  .-dev-list-con{
    height: calc(100% - 130px);
    margin-bottom: 20px;
    margin-top: 10px;

    box-sizing: border-box;
    padding: 0 32px;

    overflow-y: auto;
  }

  .-btn-box{
    width: 100%;
    box-sizing: border-box;
    padding: 0 32px;

    .ant-btn-primary.ant-btn-solid{
      background: #16ADFD;
    }

  }
}
