@variable-24292F: #24292F;
@variable-0A0000: #0A0000; 
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065:  #5E6065; 
@variable-37352F:  #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;  
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD:  #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white:  #fff;

.my-orders {
    padding: 32px 0;
    .my-orders-title {
        
        font-family: HelveticaNeue-Bold;
        font-size: 30px;
        color: @variable-24292F;
        letter-spacing: 0;
        font-weight: bold;
    }
    .my-orders-tab {
        margin-top: 30px;
        display: flex;
        height: 50px;
        border-bottom: 1px @variable-D0D7DE solid;
        .active {
            border-bottom: 3px @variable-16ADFD solid;
            color:  @variable-24292F;
            font-weight: bold;
            position: relative;
            top: 1px
        }
        p {
            font-family: HelveticaNeue-Medium;
            font-size: 24px;
            color: #57606A;
            letter-spacing: 0;
            margin-left: 40px;
        }
        p:nth-child(1) {
            margin-left: 0px !important;
        }
    }
    .my-orders-card-one {
        height: auto;
        background: @variable-white;
        border-radius: 12px;
        margin-top: 16px;
        .my-orders-card-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            padding: 0 40px;
            border-bottom: 1px solid @variable-EAEEF2;
            P:nth-child(1) {
                font-family: HelveticaNeue-Medium;
                font-size: 16px;
                color: @variable-1E2128;
                letter-spacing: 0;
            }
            p:nth-child(2) {
                font-family: HelveticaNeue;
                font-size: 12px;
                color: @variable-8C959F;
                letter-spacing: 0;
                text-align: right;
                line-height: 14px;
            }
            .active {
                color: @variable-16ADFD !important;
            }
        }
        .my-orders-card-con {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 40px;
            .my-orders-card-con-one {
                width: 55%;
                .my-orders-card-con-one-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .my-orders-card-con-one-l {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        div:nth-child(1) {
                            width: 56px;
                            height: 56px;
                            img {
                                width: 100%;
                            }
                        }
                        div:nth-child(2) {
                            margin-left: 24px;
                            p:nth-child(1) {
                                font-family: HelveticaNeue-Medium;
                                font-size: 18px;
                                color: @variable-24292F;
                                letter-spacing: 0;
                                line-height: 18px;
                            }
                            p:nth-child(2) {
                                font-family: HelveticaNeue;
                                font-size: 14px;
                                color: @variable-57606A;
                                letter-spacing: 0;
                                line-height: 20px;
                            }
                        }
                    }
                    .my-orders-card-con-one-r {
                        p {
                            font-family: HelveticaNeue-Medium;
                            font-size: 18px;
                            color: @variable-24292F;
                            letter-spacing: 0;
                            text-align: right;
                            line-height: 18px;
                            span {
                                font-family: HelveticaNeue;
                                font-size: 14px;
                                color: @variable-57606A;
                                letter-spacing: 0;
                                line-height: 18px;
                                margin-right: 16px;
                                text-decoration: line-through;
                            }
                        }
                    }
                }
            }
           
            .my-orders-card-con-two {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex: 1;
                .marginRight {
                    margin-right: 16px;
                }
                .ant-btn {
                    width: 160px;
                    height: 32px;
                    border-radius: 16px;
                }
                .ant-btn:nth-child(1) {
                    background: @variable-white;
                    border: 1px solid @variable-D0D7DE;
                    font-family: HelveticaNeue-Bold;
                    font-size: 14px;
                    color: @variable-57606A;
                    letter-spacing: 0;
                    text-align: center;
                   
                }
            
                .ant-btn:last-child {
                    background: @variable-white;
                    font-family: HelveticaNeue-Bold;
                    font-size: 14px;
                    color: @variable-16ADFD;
                    color: @variable-57606A;
                    text-align: center;
                    border: 1px solid @variable-D0D7DE;
                }
                .check-out {
                    background: @variable-16ADFD !important;
                    color: @variable-white !important;
                }
            }
            .my-orders-card-con-two-active {
                .ant-btn:last-child {
                    background: @variable-white;
                    font-family: HelveticaNeue-Bold;
                    font-size: 14px;
                    color: @variable-16ADFD;
                    letter-spacing: 0;
                    text-align: center;
                    border: 1px solid @variable-16ADFD;

                }
            }
            .my-orders-card-con-three {
                margin-left: 115px;
                margin-top: -70px;
                p:nth-child(1) {
                    font-family: HelveticaNeue-Medium;
                    font-size: 18px;
                    color: @variable-24292F;
                    letter-spacing: 0;
                    line-height: 18px;
                }
                p:nth-child(2) {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-57606A;
                    letter-spacing: 0;
                    line-height: 25px;
                }
            }
        }
    }
}