.device-center-memories{
  box-sizing: border-box;
  padding: 30px 0;


  .--head-box{
    width: 100%;
  }

  .--body-box{
    box-sizing: border-box;
    padding: 24px 0;
    width: 100%;
  }

  .--foot-box{
    width: calc(100% - 276px);
  }

  .title-box{

  }

  .intro-box{
    width: calc(100% - 500px);
  }

  .t1{
    font-family: HelveticaNeue-Medium;
    font-size: 30px;
    color: #24292F;
    letter-spacing: 0;
    line-height: 30px;
  }

  .w1{
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #57606A;
    letter-spacing: 0;
  }

  .--video-box{
    width: calc(100% - 276px);
    //min-height: 500px;
    height: 600px;

    .video-js{
      width: 100%;
      height: 100%;
    }
  }

  .--video-list{
    width: 256px;
    overflow-y: auto;
    max-height: 601px;
  }

  .--video-item{
    width: 100%;
    height: 144px;
    margin-bottom: 6px;
    cursor: pointer;
    box-sizing: border-box;
    border: 2px solid transparent;

    transition: border 0.35s;

    &:nth-last-of-type(1){
      margin-bottom: 0;
    }

    &:hover{
      border-color: #16adfd;
    }

    &.--current{
      border-color: #16adfd;
    }

    .ant-image{
      width: 100%;
    }

    .ant-image-img{
      width: 100%;
      vertical-align: bottom;
    }
  }

  .ant-btn-primary.ant-btn-solid{
    background: #16ADFD;
  }

  .ant-btn-default.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):hover,
  .ant-btn-default.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover{
    color: #16ADFD;
    border-color: #16ADFD;
  }

  .ant-btn-primary.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):hover{
    background: #16ADFD;
  }

  .video-js .vjs-mute-control,
  .video-js .vjs-fullscreen-control{
    display: inline-block;
  }

  .vjs-picture-in-picture-control{
    display: none;
  }

  .a-loadmore-flag{
    width: 100%;
    height: 1px;
  }
}
