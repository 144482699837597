@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065:  #5E6065;
@variable-37352F:  #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD:  #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white:  #fff;
.cloud-plan {
    padding: 32px 0;
    .cloud-plan-banner {
        margin-top: 24px;
        position: relative;
        img {
            border-radius: 16px;
            height: 300px;
        }
        p {
            position: absolute;
            top: 12px;
            right: 12px;
            background: rgba(0,0,0,0.35);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                g {
                    fill: @variable-white
                }
            }
        }

    }
    .cloud-plan-title {
        font-family: HelveticaNeue-Bold;
        font-size: 30px;
        color: @variable-24292F;
        letter-spacing: 0;
        font-weight: bold;
    }
    .cloud-plan-con-card {
        background-image: linear-gradient(180deg, #0065E1 0%, #00A2FF 82%, #07FFFC 100%);
        border-radius: 12px;
        border-radius: 12px;
        //margin-top: 24px;
        min-height: 394px;
        padding: 0 30px 10px;
      height: unset;



        .cloud-plan-con-card-title {
            padding-top: 19px;
            text-align: center;
            opacity: 0.95;
             h4 {
                font-family: HelveticaNeue-Medium;
                font-size: 22px;
                color: @variable-white;
                letter-spacing: 0;
                margin-bottom: 5px;
             }

             p {
                margin-bottom: 0;
                margin-top: 0;
                font-family: HelveticaNeue;
                font-size: 12px;
                color: @variable-white;
                letter-spacing: 0;
                text-align: center;
                line-height: 18px;
             }
        }
        .cloud-plan-con-card-feature {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
          flex-wrap: wrap;
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: calc(100% / 6);
                height: 100px;
                box-sizing: border-box;
                padding: 0 5px;

              @media screen and (max-width: 1400px){
                width: calc(100% / 3);
                height: 85px;
              }

                p:nth-child(1) {
                    width: 44px;
                    height: 44px;
                    background: rgba(255,255,255,0.01);
                    border: 1px solid rgba(255,255,255,0.25);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 5px;
                }
                p:nth-child(2) {
                    font-family: HelveticaNeue;
                    font-size: 12px;
                    color: @variable-white;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 16px;
                  //white-space: nowrap;
                }
            }
        }
        .cloud-plan-con-card-con {
            margin-top: 19px;
            display: flex;
            justify-content: space-between;
            .subscribe-card {
                background: @variable-white;
                // width: 192px;
                // width: calc((100% - 12px) / 3);
                width: calc((100% - 12px) / 2);
                // border: 1px solid rgba(255,255,255,0.50);
                border-radius: 4px;
                // height: 200px;
                height: 165px;
                position: relative;
                .subscribe-card-title {
                    font-family: HelveticaNeue-Medium;
                    font-size: 16px;
                    color: @variable-24292F;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 30px;
                    margin-top: 6px;
                }
                .subscribe-card-con {
                    padding: 0 16px;
                    // height: 100px;
                    height: 70px;
                    p {
                        line-height: 20px;
                        span:nth-child(2) {
                            font-family: HelveticaNeue;
                            font-size: 14px;
                            color: @variable-57606A;
                            letter-spacing: 0;
                            line-height: 12px;
                            margin-left: 6px;
                        }
                    }
                }
                //.subscribe-starting {
                //    padding: 0 16px;
                //    font-family: HelveticaNeue;
                //    font-size: 12px;
                //    color: @variable-57606A;
                //    letter-spacing: 0;
                //    line-height: 12px;
                //    span {
                //        font-family: HelveticaNeue-Medium;
                //        font-size: 16px;
                //        color: @variable-16ADFD;
                //        letter-spacing: 0;
                //        line-height: 12px;
                //    }
                //}
                .subscribe-card-btn {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 32px;
                    // background: @variable-2F25F7;
                    background-color: #0550AE;
                    border-radius: 0 0 4px 4px;
                    text-align: center;
                    font-family: HelveticaNeue-Medium;
                    font-size: 14px;
                    color: @variable-white;
                    letter-spacing: 0;
                    line-height: 32px;
                    cursor: pointer;
                }
            }

        }

      .cloud-plan-con-card-con-3{
        .subscribe-card {
          background: @variable-white;
          width: calc((100% - 12px) / 3);
          height: 200px;

          .subscribe-card-con {
            height: 100px;
          }
        }
      }
    }
    .my-subscriptions-title {
        font-family: HelveticaNeue-Medium;
        font-size: 24px;
        color: @variable-24292F;
        letter-spacing: 0;
        margin: 36px 0 12px;
        position: relative;
        margin-left: 10px;
        &::after {
           content: "";
           position: absolute;
           left: -10px;
           top: 4px;
           width: 3px;
           height: 18px;
           background: @variable-16ADFD;
           border-radius: 24px;
           border-radius: 5.5px;
        }
    }
    .my-subscriptions-con {
        .my-subscriptions-card {
            width: 100%;
            height: auto;
            background: @variable-white;
            border-radius: 12px;
            margin-top: 12px;

            border: 1px solid #EAEEF2;

            div:nth-child(1) {
                padding: 14px 25px 0;
                h4 {
                    font-family: HelveticaNeue-Medium;
                    font-size: 16px;
                    color: @variable-1E2128;
                    letter-spacing: 0;
                }
            }
            div {
                padding: 0 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 25px;
                p:nth-child(1) {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-57606A;
                    letter-spacing: 0;
                }
                p:nth-child(2) {
                    font-family: HelveticaNeue-Medium;
                    font-size: 14px;
                    color: @variable-24292F;
                    letter-spacing: 0;
                    font-weight: bold;
                }
            }
            div:last-child {
                margin-top: 14px;
                padding: 0 !important;
                width: 100%;
                border-top: 1px solid @variable-EAEEF2;
                height: 40px;
                line-height: 40px;
                font-family: HelveticaNeue-Medium;
                font-size: 14px;
                color: @variable-16ADFD;
                letter-spacing: 0;
                display: flex;
                justify-content: center;
                cursor: pointer;
            }
        }
        .no-devices {
            width: 100%;
            background: @variable-white;
            border-radius: 12px;
            margin-top: 12px;
            height: 176px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p:nth-child(1) {
                img {
                    width: 72px;
                    height: 42px;
                }
            }
            p:nth-child(2) {
                font-family: HelveticaNeue;
                font-size: 18px;
                color: @variable-57606A;
                letter-spacing: 0;
                line-height: 18px;
                margin-top: 18px;
            }
        }
    }
}

.your-cart1 {
    padding: 8px 0px;
    .your-cart-title {
        display: flex;
         p:nth-child(2) {
            font-family: HelveticaNeue-Bold;
            font-size: 30px;
            color: @variable-24292F;
            letter-spacing: 0;
            font-weight: bold;
            margin-left: 12px;
         }
    }
    .set-meal {
        border: 1px solid rgba(255,255,255,0.50);
        border-radius: 12px;
        height: 108px;
        margin-top: 24px;
        display: flex;
        background: @variable-white;
        div {
            flex: 1;
            width: 100%;
            padding: 0 40px;
            h4 {
                font-family: HelveticaNeue-Medium;
                font-size: 16px;
                color: @variable-24292F;
                letter-spacing: 0;
                text-align: center;
                line-height: 30px;
                margin-top: 4px;
            }
            p {
                font-family: HelveticaNeue;
                font-size: 12px;
                color: @variable-57606A;
                letter-spacing: 0;
                line-height: 20px;
                span:nth-child(2) {
                    margin-left: 14px;
                }
            }
        }
        .active {
            background: @variable-16ADFD;
            border-radius: 12px 0 0 12px;
            h4 {
                font-family: HelveticaNeue-Medium;
                font-size: 16px;
                color: @variable-white;
                letter-spacing: 0;
                text-align: center;
                line-height: 30px;
                margin-top: 4px;
            }
            p {
                font-family: HelveticaNeue;
                font-size: 12px;
                color: @variable-white;
                letter-spacing: 0;
                line-height: 20px;
                span:nth-child(1) {
                    svg {
                        g {
                            fill: @variable-white;
                        }
                    }
                }
                span:nth-child(2) {
                    margin-left: 14px;
                }

            }
        }
    }
    .your-cart-card {
        background: @variable-white;
        border-radius: 12px;
        width: 100%;
        height: 104px;
        margin: 12px 0 34px;
        // padding: 0 42px 0 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .your-cart-card-l {
            display: flex;
            align-items: center;
            margin-left: 42px;
            div:nth-child(1) {
                img {
                    width: 56px;
                    height: 56px;
                }
            }
            div:nth-child(2) {
                margin-left: 38px;
                p:nth-child(1) {
                    font-family: HelveticaNeue;
                    font-size: 18px;
                    color: @variable-120000;
                    line-height: 20px;
                }
                p:nth-child(2) {
                    font-family: HelveticaNeue-Medium;
                    font-size: 24px;
                    color: @variable-120000;
                    letter-spacing: 0;
                    line-height: 22px;
                }
            }
        }
        .your-cart-card-m {
            display: flex;
            align-items: center;
            div:nth-child(1) {
                font-family: HelveticaNeue;
                font-size: 14px;
                color: @variable-5E6065;
                letter-spacing: 0;
            }
            div:nth-child(2) {
                margin-left: 8px;
                display: flex;
                width: 160px;
                height: 32px;
                background: @variable-white;
                border: 1px solid @variable-EAEEF2;
                border-radius: 16px;
                p:nth-child(1),p:nth-child(3) {
                    width: 40px;
                    height: 32px;
                }
                p:nth-child(2) {
                    width: 80px;
                    height: 32px;
                    font-family: HelveticaNeue-Bold;
                    font-size: 18px;
                    color: @variable-24292F;
                    letter-spacing: 0.22px;
                }
                p {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .active {
                    svg {
                        g {
                            fill: @variable-16ADFD;
                        }
                    }
                }
            }
        }
        .your-cart-card-r {
            display: flex;
            margin-right: 24px;
            p {
                font-family: HelveticaNeue-Bold;
                font-size: 24px;
                color: @variable-16ADFD;
                letter-spacing: 0;
                span {
                    font-family: HelveticaNeue-Medium;
                    font-size: 16px;
                    color: @variable-120000;
                    letter-spacing: 0;
                    text-align: right;
                    line-height: 29px;
                }
            }
            p:nth-child(1) {
                margin-right: 48px;
            }
        }
    }
    .shop {
        .shop-title {
            font-family: HelveticaNeue;
            font-size: 16px;
            color: @variable-37352F;
            letter-spacing: 0;
            margin-bottom: 2px;
        }
        .shop-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 104px;
            width: 100%;
            // padding: 0 40px 0 24px;
            margin-top: 12px;
            background-color: @variable-white;
            border-radius: 8px;
            .shop-card-l {
                display: flex;
                align-items: center;
                margin-left: 24px;
                div:nth-child(1) {
                    img {
                        width: 56px;
                    }
                }
                div:nth-child(2) {
                    font-family: HelveticaNeue;
                    font-size: 24px;
                    color: @variable-1E2128;
                    letter-spacing: 0.29px;
                    margin-left: 38px;
                }
            }
            .shop-card-r {
                display: flex;
                margin-left: 8px;
                display: flex;
                width: 160px;
                height: 32px;
                background: @variable-white;
                border: 1px solid @variable-EAEEF2;
                border-radius: 16px;
                margin-right: 40px;
                p:nth-child(1),p:nth-child(3) {
                    width: 40px;
                    height: 32px;
                }
                p:nth-child(2) {
                    width: 80px;
                    height: 32px;
                    font-family: HelveticaNeue-Bold;
                    font-size: 18px;
                    color: @variable-24292F;
                    letter-spacing: 0.22px;
                }
                p {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .active {
                    svg {
                        g {
                            fill: @variable-16ADFD;
                        }
                    }
                }
            }
        }
    }
    .bundle {
        .bundle-title {
            margin-top: 24px;
            font-family: HelveticaNeue;
            font-size: 16px;
            color: @variable-37352F;
            letter-spacing: 0;
        }
        .bundle-con {
            margin-top: 14px;
            .bundle-con-card {
                background: @variable-white;
                border-radius: 8px;
                height: 104px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                div:nth-child(1) {
                    margin-left: 24px;
                    h4 {
                        font-family: HelveticaNeue-Medium;
                        font-size: 24px;
                        color: @variable-1E2128;
                        letter-spacing: 0.29px;
                        line-height: 24px;
                        margin-top: 12px;
                        margin-bottom: 14px;
                    }
                    p {
                        font-family: HelveticaNeue;
                        font-size: 14px;
                        color: @variable-37352F;
                        letter-spacing: 0;
                        line-height: 20px;
                    }
                }
                div:nth-child(2) {
                    display: flex;
                    margin-top: 24px;
                }
            }
            .bundle-con-card-active {
                background: @variable-E7F6FE;
                border: 2px solid @variable-16ADFD;
                border-radius: 8px;
            }
        }

    }
    .note {
        margin-top: 32px;
        h4 {
            font-family: HelveticaNeue;
            font-size: 20px;
            color: @variable-1E2128;
            letter-spacing: 0.29px;
            line-height: 24px;
        }
        p {
            font-family: HelveticaNeue;
            font-size: 16px;
            color: @variable-270000;
            letter-spacing: 0.19px;
            line-height: 24px;
        }
    }
    .cloud-service {
        .cloud-service-title {
            font-family: HelveticaNeue;
            font-size: 16px;
            color: @variable-37352F;
            letter-spacing: 0;

        }
        .cloud-service {
            margin-top: 2px;
            .cloud-service-card {
                background: @variable-white;
                border-radius: 8px;
                width: 100%;
                height: 72px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;
                .cloud-service-card-l {
                    display: flex;
                    margin-left: 42px;
                    align-items: center;
                    p:nth-child(1) {
                        img {
                            width: 60px;
                            height: 60px;
                        }
                    }
                    p:nth-child(2) {
                        margin-left: 38px;
                        font-family: HelveticaNeue;
                        font-size: 18px;
                        color: @variable-24292F;
                        letter-spacing: 0.22px;
                    }
                }
                .cloud-service-card-r {
                    margin-right: 24px;
                }
            }
        }
    }
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 98px;
        .footer-l {
            display: flex;
            align-items: center;
            p:nth-child(2) {
                font-family: HelveticaNeue;
                font-size: 14px;
                color: #6E7781;
                letter-spacing: 0;
                line-height: 20px;
                margin-left: 16px;
            }
        }
        .footer-r {
            display: flex;
            .ant-btn {
                width: 192px;
                height: 44px;
                // &:hover {
                //     border: none
                // }
            }
            .ant-btn:nth-child(1) {
                margin-right: 24px;
                background: @variable-white;
                border-radius: 24px;
                font-family: HelveticaNeue-Bold;
                font-size: 18px;
                color: @variable-57606A;
                letter-spacing: 0;
                text-align: center;
                border: 1px @variable-white solid;
                 &:hover {
                    border: 1px @variable-white solid;
                }
            }
            .ant-btn:nth-child(2) {
                background: @variable-16ADFD;
                border-radius: 24px;
                font-family: HelveticaNeue-Bold;
                font-size: 18px;
                color: @variable-white;
                letter-spacing: 0;
                text-align: center;
                border: 1px @variable-16ADFD solid;
                 &:hover {
                    border: 1px @variable-16ADFD solid;
                }
            }
        }
     }
     .check-out {
        margin-top: 24px;
        .check-out-l {
            background: @variable-white;
            border-radius: 12px;
            height: 1800px;
            overflow: hidden;
            padding: 0 40px;
            .title {
                margin-top: 35px;
                font-family: HelveticaNeue-Medium;
                font-size: 24px;
                color: @variable-24292F;
                letter-spacing: 0;
            }
            .user {
                margin-top: 21px;
                display: flex;
                align-items: center;
                div:nth-child(1) {
                    img {
                        width: 72px;
                        height: 72px;
                    }
                }
                div:nth-child(2) {
                    margin-left: 24px;
                    p:nth-child(1) {
                        font-family: HelveticaNeue-Medium;
                        font-size: 18px;
                        color: @variable-24292F;
                        letter-spacing: 0;
                        line-height: 25px;
                        text-transform: uppercase;
                    }
                    p:nth-child(2) {
                        font-family: HelveticaNeue;
                        font-size: 14px;
                        color: @variable-57606A;
                        letter-spacing: 0.58px;
                    }
                }
            }
            .payment {
                margin-top: 40px;
                h4 {
                    font-family: HelveticaNeue-Medium;
                    font-size: 24px;
                    color: @variable-24292F;
                    letter-spacing: 0;
                    line-height: 30px;
                }
                p {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-57606A;
                    letter-spacing: 0.58px;
                    line-height: 20px;
                }
            }
            .credit-card {
                margin-top: 24px;
                background: @variable-white;
                border: 1px solid @variable-D0D7DE;
                border-radius: 6px;
                width: 100%;
                .credit-card-title {
                    height: 56px;
                    display: flex;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 12px 0 24px;
                    background: rgba(22,173,253,0.10);
                    border: 1px solid @variable-D0D7DE;
                    border-radius: 6px 6px 0 0;
                    div {
                        display: flex;
                        align-items: center;
                    }
                    div:nth-child(1) {
                        p:nth-child(2) {
                            font-family: HelveticaNeue-Medium;
                            font-size: 18px;
                            color: @variable-24292F;
                            letter-spacing: 0;
                            margin-left: 12px;
                        }
                    }
                    div:nth-child(2) {
                        p:nth-child(1), p:nth-child(2) {
                            width: 64px;
                            height: 32px;
                            margin-right: 8px;
                            img {
                                width: 100%;
                            }
                        }
                        p:nth-child(3) {
                            width: 32px;
                            height: 32px;
                            background: @variable-white;
                            border: 1px solid @variable-white;
                            border-radius: 2px;
                            text-align: center;
                            line-height: 32px;
                            font-family: HelveticaNeue;
                            font-size: 14px;
                            color: @variable-57606A;
                            letter-spacing: 0.58px;
                        }
                    }
                }
                .credit-card-form {
                    padding: 24px 24px 0 24px;
                    .ant-input, .ant-picker {
                        height: 44px;
                        background: @variable-white;
                        border: 1px solid @variable-D0D7DE;
                        border-radius: 2px;
                        .ant-picker-suffix {
                            display: none;
                        }
                        font-family: HelveticaNeue;
                        font-size: 14px;
                        color: @variable-8C959F;
                        letter-spacing: 0.58px;
                        .ant-picker-input {
                            input {
                                font-family: HelveticaNeue;
                                font-size: 14px;
                                color: @variable-8C959F;
                                letter-spacing: 0.58px;
                            }
                        }
                    }

                }
            }
            .or {
                margin-top: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p:nth-child(1), p:nth-child(3) {
                    width: 46%;
                    height: 1px;
                    background-color: @variable-D0D7DE;
                }
                p:nth-child(2) {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-57606A;
                    letter-spacing: 0.58px;
                }
            }
            .payment-method {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                div {
                    width: 225px;
                    height: 44px;
                    img {
                        width: 100%;
                    }
                }
            }
            .shipping-address {
                margin-top: 35px;
                .title {
                    font-family: HelveticaNeue-Medium;
                    font-size: 24px;
                    color: @variable-24292F;
                    letter-spacing: 0;
                }
                .shipping-address-form {
                    margin-top: 24px;
                    .ant-input, .ant-picker, .ant-select{
                        height: 44px;
                        background: @variable-white;
                        border: 1px solid @variable-D0D7DE;
                        border-radius: 2px;
                        .ant-picker-suffix {
                            display: none;
                        }
                        font-family: HelveticaNeue;
                        font-size: 14px;
                        color: @variable-8C959F;
                        letter-spacing: 0.58px;
                        .ant-picker-input {
                            input {
                                font-family: HelveticaNeue;
                                font-size: 14px;
                                color: @variable-8C959F;
                                letter-spacing: 0.58px;
                            }
                        }
                        .ant-select-selector {
                            border: none;
                            position: relative;
                            &::after {
                               background-color: @variable-D0D7DE;
                               content: "";
                                height: 44px;
                                width: 1px;
                                position: absolute;
                                top: 0;
                                right: 35px;
                                visibility: visible
                            }
                        }
                        .ant-select-arrow {
                            svg {
                                fill: #57606A;
                            }
                        }
                    }
                }
            }
            .check-out-footer {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                div {
                    .ant-btn {
                        width: 192px;
                        height: 44px;
                        border-radius: 24px;
                    }
                    .ant-btn:nth-child(1) {
                        background: @variable-white;
                        border: 1px solid @variable-D0D7DE;
                        border-radius: 24px;
                        font-family: HelveticaNeue-Bold;
                        font-size: 18px;
                        color: @variable-57606A;
                        letter-spacing: 0;
                        text-align: center;
                        margin-right: 24px;
                    }
                    .ant-btn:nth-child(2) {
                        background: @variable-16ADFD;
                        // border: 1px solid @variable-D0D7DE;
                        border-radius: 24px;
                        font-family: HelveticaNeue-Bold;
                        font-size: 18px;
                        color: @variable-white;
                        letter-spacing: 0;
                        text-align: center;
                    }
                }
            }
        }
        .check-out-r {
            background: @variable-white;
            border-radius: 12px;
            height: 1800px;
            padding: 0 40px;
            overflow: hidden;
            .check-out-card {
                margin-top: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                div:nth-child(1) {
                    display: flex;
                    align-items: center;
                    div:nth-child(1) {
                        img {
                            width: 56px;
                            height: 56px;
                        }
                    }
                    div:nth-child(2) {
                        margin-left: 14px;
                        p:nth-child(1) {
                            font-family: HelveticaNeue;
                            font-size: 18px;
                            color: @variable-24292F;
                            letter-spacing: 0;
                            line-height: 18px;
                            text-align: left;
                        }
                        p:nth-child(2) {
                            font-family: HelveticaNeue;
                            font-size: 14px;
                            color: @variable-57606A;
                            letter-spacing: 0;
                            line-height: 20px;
                            text-align: left;
                            text-decoration: none
                        }
                        p:nth-child(3) {
                             span {
                                font-family: HelveticaNeue;
                                font-size: 12px;
                                color: @variable-16ADFD;
                                letter-spacing: 0;
                                line-height: 12px;
                                margin-left: 6px;
                             }
                            text-align: left;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
                div:nth-child(2) {
                   p:nth-child(1) {
                    font-family: HelveticaNeue-Medium;
                    font-size: 18px;
                    color: @variable-24292F;
                    letter-spacing: 0;
                    text-align: right;
                    line-height: 18px;
                   }
                   p:nth-child(2) {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-57606A;
                    letter-spacing: 0;
                    text-align: right;
                    line-height: 14px;
                    text-decoration:line-through
                }
                }
            }
            .hr {
                margin-top: 40px;
                border: 1px solid @variable-EAEEF2;
                width: 100%;
            }
            .subtotal-items {
                margin-top: 48px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                div:nth-child(1) {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-57606A;
                    letter-spacing: 0;
                }
                div:nth-child(2) {
                    p:nth-child(1) {
                        font-family: HelveticaNeue-Medium;
                        font-size: 18px;
                        color: @variable-16ADFD;
                        letter-spacing: 0;
                        text-align: right;
                        line-height: 18px;
                    }
                    p:nth-child(2) {
                        font-family: HelveticaNeue;
                        font-size: 14px;
                        color: @variable-57606A;
                        letter-spacing: 0;
                        text-align: right;
                        line-height: 14px;
                        text-decoration:line-through
                    }
                }
            }
            .subtotal {
                margin-top: 35px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                div:nth-child(1) {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-57606A;
                    letter-spacing: 0;
                }
                div:nth-child(2) {
                    p:nth-child(1) {
                        font-family: HelveticaNeue-Medium;
                        font-size: 18px;
                        color: @variable-24292F;
                        letter-spacing: 0;
                        text-align: right;
                        line-height: 18px;
                    }
                }
            }
            .total {
                margin-top: 29px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                div:nth-child(1) {
                     p:nth-child(1) {
                        font-family: HelveticaNeue-Medium;
                        font-size: 18px;
                        color: @variable-24292F;
                        letter-spacing: 0;
                     }
                     p:nth-child(2) {
                        display: flex;
                        align-items: center;
                        span:nth-child(2) {
                            font-family: HelveticaNeue;
                            font-size: 12px;
                            color: @variable-16ADFD;
                            letter-spacing: 0;
                            line-height: 12px;
                            margin-left: 4px;
                        }
                     }
                }
                div:nth-child(2) {
                    font-family: HelveticaNeue-Bold;
                    font-size: 30px;
                    color: @variable-16ADFD;
                    letter-spacing: 0;
                    text-align: right;
                    line-height: 30px;
                }
            }
        }
        #checkoutTom {
            // padding: 24px 0;
            overflow: hidden;
        }
     }
}

#finix-button-container {
    display: none !important;
}

.subscribe-checkout-modal{
  background: #ffffff !important;
}
