.file-page{
  height: 100vh;
  position:  fixed;
  background-color: #000;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  .file-page-title {
     display: flex;
     justify-content: space-between;
     align-items: center;
     height: 60px;
    //  padding: 12px 24px;
    padding-top: 12px;
     position: fixed;
     width: 100%;
     z-index: 2;
     .file-page-name {
      font-family: HelveticaNeue-Bold;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 18px;
     }
     p:last-child{
      cursor: pointer;
     }
  }
  .file-operation {
    position: absolute;
    left: 0;
    bottom: 24px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    div {
      display: flex;
      .underline {
        margin-right: 16px;
        &::after{
          // width: 2px;
          height: 16px;
          content: "";
          position: absolute;
          top: 12px;
          right: -18px;
          border: 1px solid rgba(255,255,255,0.50);
        }
      }
      p {
        position: relative;
        margin-left: 16px;
        img {
          width: 44px;
          cursor: pointer;
        }
      }
    }
  }
  .hd-box{
    height: 61px;

    .ui-head-nav {
      // border-bottom: 1px solid #dfdfe1;
      padding: 19px 16px;
    }
  }

  .mn-box{
    box-sizing: border-box;
    // height: calc(100% - 460px);
    // margin-top: 100px;
    // padding-bottom: 83px;
    overflow-y: auto;

    display: flex;
    background: #000;
    position: relative;
    
  }
  .file-left {
    position: absolute;
    top: 45%;
    left: 24px;
    cursor: pointer;
  }
  .file-right {
    position: absolute;
    top: 45%;
    right: 24px;
    cursor: pointer;
  }

  .file-content{
    #contentPage {
      height: 100vh
    }
    margin: auto;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    //height: inherit;
    text-align: center;
    &.-img{
      height: inherit;
    }

    video{
      // width: 100%;
      max-height: calc(100vh - 360px);
      vertical-align: super;
      background-color: #000F00;
    }

    .adm-image{
      // height: 100%;
      height: 620px;
      width: 100%;
    }
  }

  .a-full-card{
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #FFFFFF;

    height: 100%;
    background-color: #000000;
    box-sizing: border-box;
    padding: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .a-no-video{
    height: 100%;

    display: flex;
  }
}
