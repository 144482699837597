.me-drive-modal {
  .adm-popup-body {
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    height: auto;
    padding-bottom: 32px;
    // padding: 0 24px;
    .popup-title {
      font-family: HelveticaNeue-Bold;
      font-size: 24px;
      color: #1E2128;
      letter-spacing: 0;
      line-height: 20px;
      margin: 27px 24px 20px;
    }

    .popup-item {
      display: flex;
      padding: 15px 24px 15px;
      align-items: center;
      //margin-top: 8px;
      p {
        margin-left: 15px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
      }
    }

    .popup-item:nth-child(3) {
      // border-bottom:1px solid #F4F4F4;
    }

    .popup-item-active {
      background: #F4F4F4;
    }

    .underline {
      background: #DFDFE1;
      width: 100%;
      height: 1px;
      margin: 15px 0;
    }

    .popup-upgrade {
      padding: 20px 24px 0;

      .adm-progress-bar-trail {
        border-radius: 6px;
        // opacity: 0.7;
        background: #DFDFE1;
      }

      .adm-progress-bar-fill {
        //background: #16ADFD;
      }

      .popup-upgrade-footer {
        display: flex;
        justify-content: space-between;

        p {
          font-family: HelveticaNeue;
          font-size: 12px;
          color: #5E6065;
          letter-spacing: 0;
          margin-top: 16px;
        }

        .adm-button {
          background: #FFFFFF;
          border: 1px solid #DFDFE1;
          border-radius: 12px;
          margin-top: 12px;
          //width: 80px;
          height: 24px;
          line-height: 8px;

          span {
            font-family: HelveticaNeue-Medium;
            font-size: 12px;
            color: #16ADFD;
            letter-spacing: 0;
            text-align: center;
          }
        }
      }
    }
  }
}
