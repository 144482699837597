.clipbox[data-v-f6624a4c] {
  position: absolute;
  bottom: 45px;
  width: 350px;
  min-height: 100px;
  background: #f7f7f7;
  right: 50px;
  -webkit-box-shadow: 0 0 15px 1px hsla(0,0%,100%,.15);
  box-shadow: 0 0 15px 1px hsla(0,0%,100%,.15);
  border-radius: 10px;
  padding-top: 25px;
  padding-bottom: 45px;
  overflow: hidden;

  *{
    padding: 0;
    margin: 0;
    border: 0;
  }

  .close[data-v-f6624a4c] {
    position: absolute;
    right: 3px;
    top: -3px;
    color: #666;
    padding: 10px;
    font-size: 20px;
    cursor: default;
  }

  .title[data-v-f6624a4c] {
    font-size: 15px;
    color: #000;
    text-align: center;
    margin-bottom: 10px;
    line-height: 15px;
  }

  .ctxt[data-v-f6624a4c] {
    font-size: 12px;
    color: #000;
    text-align: center;
  }

  .wtxt[data-v-f6624a4c] {
    font-size: 12px;
    color: #666;
  }

  .line[data-v-f6624a4c] {
    display: block;
    width: 100%;
    height: 1px;
    background: #d3d3d3;
    margin: 15px 0 35px;
    position: relative;
  }

  .prograss-box[data-v-f6624a4c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
  }

  .bar-box[data-v-f6624a4c] {
    width: 230px;
    height: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5px 0;
  }

  .bar[data-v-f6624a4c] {
    position: relative;
    background: #d3d3d3;
    height: 5px;
    width: 100%;
    border-radius: 3px;
    margin-top: 7px;
  }

  .show-time[data-v-f6624a4c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px 55px;
    position: relative;

    .loadering-status[data-v-f6624a4c] {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      color: #666;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 5px 5%;
      font-size: 12px;
      text-align: center;
    }

    .circle[data-v-f6624a4c] {
      margin-bottom: 15px;
      margin-left: -15px;
      -webkit-animation: rotate360-data-v-f6624a4c 3s linear 0s infinite;
      animation: rotate360-data-v-f6624a4c 3s linear 0s infinite
    }

  }

  .btn[data-v-f6624a4c] {
    height: 45px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: default;
    background: rgba(15, 45, 81, 0.75);

    span[data-v-f6624a4c] {
      font-size: 15px;
      color: #fff
    }
  }

  .ant-slider-handle{
    top: -3px;
  }

  .ant-slider .ant-slider-handle:hover::after{
    box-shadow: 0 0 0 4px rgba(15, 45, 81, 0.75);
  }

  .ant-slider .ant-slider-track{
    background-color: rgba(15, 45, 81, 0.45);
  }
}

@-webkit-keyframes rotate360-data-v-f6624a4c {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn)
  }
}

@keyframes rotate360-data-v-f6624a4c {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn)
  }
}
