@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065: #5E6065;
@variable-37352F: #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD: #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white: #fff;
.account-con {
  .title {
    margin-top: 32px;

    h4 {
      font-family: HelveticaNeue-Bold;
      font-size: 30px;
      color: @variable-24292F;
      letter-spacing: 0;
      line-height: 36px;
    }

    p {
      font-family: HelveticaNeue;
      font-size: 18px;
      color: @variable-24292F;
      letter-spacing: 0;
      margin-top: 12px;
    }
  }

  .account-content {
    margin-top: 36px;

    .account-content-information {
      .account-content-information-title {
        font-family: HelveticaNeue-Medium;
        font-size: 24px;
        color: @variable-24292F;
        letter-spacing: 0;
        position: relative;
        margin-left: 12px;

        &::after {
          content: "";
          position: absolute;
          left: -10px;
          top: 4px;
          width: 3px;
          height: 18px;
          background: @variable-16ADFD;
          border-radius: 5.5px;
        }
      }

      .card {
        background: @variable-white;
        border-radius: 12px;
        width: 100%;
        height: 476px;
        margin-top: 16px;
        overflow: hidden;

        .user {
          text-align: center;
          margin-top: 65px;

          img {
            width: 144px;
            height: 144px;
          }

          p {
            font-family: Helvetica65-Medium;
            font-size: 24px;
            color: @variable-1E2128;
            letter-spacing: 0;
            text-align: center;
            line-height: 28px;
            margin-top: 16px;
          }
        }

        .card-button {
          padding: 0 80px;
          display: flex;
          flex-direction: column;
          margin-top: 46px;

          .ant-btn {
            width: 100%;
            height: 60px;
            border-radius: 48px;
          }

          .ant-btn:nth-child(1) {
            background: @variable-16ADFD;
            font-family: Helvetica65-Medium;
            font-size: 18px;
            color: @variable-white;
            letter-spacing: 0;
            text-align: center;
            line-height: 24px;
          }

          .ant-btn:nth-child(2) {
            background: @variable-white;
            font-family: Helvetica65-Medium;
            font-size: 18px;
            color: @variable-57606A;
            letter-spacing: 0;
            text-align: center;
            line-height: 24px;
            margin-top: 16px;
            border: 1px solid @variable-D0D7DE;

            &:hover {
              border: 1px solid @variable-D0D7DE;
            }
          }
        }
      }
    }

    .title-pre-a{
      font-family: HelveticaNeue-Medium;
      font-size: 24px;
      color: @variable-24292F;
      letter-spacing: 0;
      position: relative;
      margin-left: 12px;

      &::after {
        content: "";
        position: absolute;
        left: -10px;
        top: 4px;
        width: 3px;
        height: 18px;
        background: @variable-16ADFD;
        border-radius: 5.5px;
      }
    }

    .shipping-address {
      .shipping-address-title {
        font-family: HelveticaNeue-Medium;
        font-size: 24px;
        color: @variable-24292F;
        letter-spacing: 0;
        position: relative;
        margin-left: 12px;

        &::after {
          content: "";
          position: absolute;
          left: -10px;
          top: 4px;
          width: 3px;
          height: 18px;
          background: @variable-16ADFD;
          border-radius: 5.5px;
        }
      }

      .shipping-address-card {
        height: 168px;
        background: @variable-white;
        border-radius: 12px;
        margin-top: 20px;

        .shipping-address-card-title {
          padding: 0 44px;
          height: 70px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid @variable-EAEEF2;

          p:nth-child(1) {
            font-family: HelveticaNeue-Medium;
            font-size: 18px;
            color: @variable-24292F;
            letter-spacing: 0;
            line-height: 18px;
          }

          p:nth-child(2) {
            .ant-btn {
              background: #FFFFFF;
              border: 1px solid @variable-D0D7DE;
              border-radius: 28px;
              width: 120px;
              height: 40px;
              font-family: HelveticaNeue-Medium;
              font-size: 16px;
              color: @variable-16ADFD;
              letter-spacing: 0;
              text-align: center;
              line-height: 16px;
            }
          }

        }

        .shipping-address-card-con {
          padding: 0 44px;
          margin-top: 24px;

          p:nth-child(1) {
            font-family: HelveticaNeue;
            font-size: 16px;
            color: @variable-24292F;
            letter-spacing: 0;
            line-height: 25px;
          }

          p:nth-child(2) {
            font-family: HelveticaNeue;
            font-size: 14px;
            color: @variable-57606A;
            letter-spacing: 0;
            line-height: 25px;
          }

          .shipping-address-card-con-active {
            color: @variable-16ADFD !important;
          }
        }
      }
    }

    .payment-content {
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      margin-bottom: 16px;
      margin-top: 20px;
      border: 1px solid #EAEEF2;
      .payment-card {
        display: flex;
        padding: 24px 36px;
        align-items: center;

        .card-img-con {
          background: #ffffff;
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #eaeef2;
          width: 64px;
          height: 64px;
          margin-right: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .card-num {
          font-weight: 400;
          font-size: 18px;
          color: #24292f;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 4px;
        }

        .time {
          font-weight: 400;
          font-size: 14px;
          color: #57606a;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .line {
        height: 1px;
        background-color: #eaeef2;
      }

      .btn-con {
        //display: flex;
        //justify-content: flex-end;
        padding: 16px 18px 16px 0px;

        text-align: center;
        color: #16adfd;
        cursor: pointer;
        font-size: 16px;

        .history {
          width: 240px;
          height: 40px;
          background: #ffffff;
          border-radius: 28px 28px 28px 28px;
          border: 1px solid #d0d7de;
          font-weight: 400;
          font-size: 16px;
          color: #16adfd;
          text-align: center;
          font-style: normal;
          text-transform: none;
          margin-right: 12px;
        }

        .remove {
          width: 120px;
          height: 40px;
          background: #ffffff;
          border-radius: 28px 28px 28px 28px;
          border: 1px solid #d0d7de;
          font-weight: 400;
          font-size: 16px;
          color: #57606a;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }

    .add-payment {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25px 0px;
      border-radius: 12px 12px 12px 12px;
      border: 2px dashed #d0d7de;
      font-weight: 400;
      font-size: 18px;
      color: #16adfd;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-bottom: 236px;
    }


  }

  &:has(.z-payment-management-box){
    height: calc(100% - 32px);

    .account-content{
      height: calc(100% - 110px);
    }

    .cls-t-2503201336s{
      height: 100%;
    }

    .shipping-address-visa-title{
      margin-bottom: 10px;
    }

    .z-payment-management-box{
      height: calc(100% - 35px);
    }
  }

  .shipping-address-visa{


    &:has(.z-payment-management-box){
      height: 100%;
    }
  }
}

.z-payment-management-box{
  width: 100%;
  height: 100%;

  iframe{
    width: 100%;
    height: 100%;
  }
}
