.favourite-con {
  // max-width: 750px;
  // height: 100vh;
  // margin: 0 auto;
  // overflow-y: auto;
  // position: relative;
  // margin-top: 32px;
  .header {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    border-bottom: 1px #F4F4F4 solid;

    .header-menu {
      img {
        width: 20px;
        margin-left: 16px;
        vertical-align: bottom;
      }
    }

    .header-txt {
      margin-left: 15px;
      font-family: HelveticaNeue-Bold;
      font-size: 24px;
      color: #1E2128;
      letter-spacing: 0;
    }


  }

  .header-search {
    // width: 305px;

    height: 32px;
    opacity: 0.6;
    background: #DFDFE1;
    border-radius: 16px;
    position: relative;
    margin: 12px 16px 12px;
    //box-sizing: border-box;

    img {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      left: 16px;
    }

    .header-search-input {
      width: 100%;
      height: 32px;
      background-color: transparent;
      letter-spacing: 0;

      .adm-input-element {
        text-indent: 38px;
      }

      input::-webkit-input-placeholder {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
      }
    }

  }

  .header-pause {
    background: #1E2128;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: -8px;

    p {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 16px;
    }

    .adm-button {
      width: 96px;
      height: 24px;
      background: #F4F4F4;
      border-radius: 12.5px;
      line-height: 8px;

      span {
        font-family: HelveticaNeue-Medium;
        font-size: 12px;
        color: #16ADFD;
        letter-spacing: 0;
      }
    }
  }

  .upgrade-storage {
    background: #1E2128;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: -8px;

    p:nth-child(1) {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 16px;

      span {
        font-family: HelveticaNeue;
        font-size: 14px;
        color: #16ADFD;
        letter-spacing: 0;
        text-decoration: underline;
      }
    }

    p:nth-child(2) {
      color: #fff;
      font-size: 18px;
      font-family: HelveticaNeue;
      font-weight: bold;
    }
  }

  .add {
    position: fixed;
    right: 12px;
    bottom: 12px;

    img {
      width: 56px;
    }
  }


}

.header-search-input:focus-visible {
  border-radius: 16px;
  outline: 1px #DFDFE1 solid;
  /* opacity: 0; */
}

.fav-file-head-box{
 background-color: #eaeef2;
  height: 60px;
  position: sticky;
  top:0px;
 z-index: 1;
  &:has(.header-pause), &:has(.upgrade-storage){
    //height: 158px;
  }

  .ui-head-nav{
    padding: 19px 16px;
  }
}


.fav-content {
  padding: 0px 0 0px;
  box-sizing: border-box;
  height: calc(100% - 100px);
  .right-context-menu {
    margin-left: 70px !important;
  }
  .content-title {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 15px;
    padding: 20px 24px 15px;
    box-sizing: border-box;
    .adm-space {
      cursor: pointer;
    }
    div:nth-child(1) {
      display: flex;

      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 20px;
        color: #1E2128;
      }

      img {
        width: 14px;
        margin-left: 8px;
      }
    }

    div:nth-child(2) {
      img {
        width: 18px;
      }
    }
  }

  .file-item-list{
    height: calc(100% - 55px);
    padding-bottom: 30px;
    // overflow-y: auto;
    box-sizing: border-box;
  }

  .content-data {
    margin: 107px auto 0;
    text-align: center;

    .content-data-img {
      width: 240px;
      height: 198px;
      margin: 0 auto;
    }

    .content-data-txt {
      h4 {
        margin-top: 12px;
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
        letter-spacing: 0;
        margin-top: 7px;
      }
    }
  }

  .content-two {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // padding: 0 24px;
    // padding: 0 10px;

    .content-two-item {
      width: 152px;
      height: 152px;
      background: #FFFFFF;
      border-radius: 8px;
      margin-top: 24px;
      border: 1px solid #EAEEF2;
      .more {
        display: flex;
        justify-content: flex-end;
        margin: 12px;

        img {
          width: 20px;
        }
      }

      .file {
        display: flex;
        justify-content: center;

        img {
          width: 72px;
        }
      }

      .txt {
        margin-top: 12px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
        text-align: center;
        line-height: 18px;
        padding: 0 15px;
      }
    }
  }

  .content-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-top: 33px;
    // padding: 16px 24px;
    padding: 16px 0 16px 0px;

    &:hover{
      background: #F6F8FA;
    }

    .content-item-type {
      display: flex;
      width: calc(100% - 60px);
      margin-left: 24px;
      img {
        width: 32px;
        max-height: 42px;
        object-fit: contain;
        object-position: center;
      }

      .content-item-txt {
        margin-left: 16px;

        h4 {
          font-family: HelveticaNeue;
          font-size: 16px;
          color: #1E2128;
          line-height: 18px;
        }

        &>div {
          font-family: HelveticaNeue;
          font-size: 12px;
          color: #000029;
          line-height: 18px;
        }
      }
    }

    .content-item-more {
      img {
        width: 20px;
      }
    }
  }
}

.popup-con {
  .adm-popup-body {
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    // border-radius: 24px 24px 0 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: auto;
    padding-bottom: 56px;
    // padding: 0 24px;
    .popup-title {
      font-family: HelveticaNeue-Medium;
      font-size: 20px;
      color: #1E2128;
      letter-spacing: 0;
      line-height: 20px;
      margin: 27px 24px 0;
    }

    .popup-item {
      display: flex;
      padding: 15px 24px 15px;
      align-items: center;
      margin-top: 8px;

      p {
        margin-left: 15px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
      }
    }

    .popup-item:nth-child(3) {
      border-bottom: 1px solid #F4F4F4;
    }

    .popup-item-active {
      background: #F4F4F4;
    }
  }
}
