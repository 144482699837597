.page-upload{
  height: 100%;
  background: #F4F4F4;

  .page-head-container{
    padding: 0 16px;
    border-bottom: 1px solid #DFDFE1;
    height: 57px;
  }

  .page-body-container{
    padding: 28px 0 30px;
    box-sizing: border-box;
    height: calc(100% - 57px);

    overflow-y: auto;
  }

  .a-part-container{

    &:not(:nth-last-of-type(1)){
      margin-bottom: 25px;
    }
  }

  .a-title-box{
    font-family: HelveticaNeue-Medium;
    font-size: 20px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 0 24px;

    margin-bottom: 12px;

    .adm-checkbox.adm-checkbox-indeterminate{

      .adm-checkbox-icon{

        &>svg{
          display: block;
          width: 50%;
          height: 50%;
          margin: 25%;
          background-color: #16ADFD;

          path{
            display: none;
          }
        }
      }
    }
  }

  .a-opt-slt-all{
    font-family: HelveticaNeue-Medium;
    font-size: 16px;
    color: #16ADFD;
    letter-spacing: 0;
    text-align: right;
    line-height: 18px;
    cursor: pointer;

    &._ys{
      opacity: 0.65;
    }
  }

  .a-main-box{
    padding: 0 24px;
  }

  .a-file-list{
    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 12px;
    grid-template-columns: repeat(6, minmax(auto, 110px));

    justify-content: space-between;
    //grid-template-rows: repeat(auto-fill, 110px);

    @media screen and (max-width: 800px){
      grid-template-columns: repeat(6, minmax(auto, 110px));
    }

    @media screen and (max-width: 600px){
      grid-template-columns: repeat(5, minmax(auto, 110px));
    }

    @media screen and (max-width: 590px){
      grid-template-columns: repeat(4, minmax(auto, 110px));
    }

    @media screen and (max-width: 480px){
      grid-template-columns: repeat(3, minmax(auto, 110px));
    }
  }

  .a-file-item{
    //width: 110px;
    height: 110px;

    position: relative;

    background-color: #5E6065;


    .adm-checkbox .adm-checkbox-icon{
      background: rgba(0,0,0,0.50);
      border: 1px solid rgba(255,255,255,0.90);
      border-radius: 3px;
    }
  }

  .a-file-icon{

  }

  .a-collect-icon{
    position: absolute;
    top: 4px;
    right: 4px;

    img{
      vertical-align: bottom;
      width: 20px;
    }
  }

  .a-check-box{
    position: absolute;
    bottom: 4px;
    right: 4px;
  }

  .adm-checkbox .adm-checkbox-icon{
    background: #FFFFFF;
    border: 1px solid #DFDFE1;
    border-radius: 3px;
  }

  .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon{
    border-color: #16ADFD;
    background-color: #16ADFD;
  }
}
