.search-con {
  // height: 100vh;
  height: auto;
  // background-color: #F4F4F4;
  margin-top: 32px;
  .a-header{
    height: 60px;
    padding: 0 16px;
    border-bottom: 1px #DFDFE1 solid;
    background-color: #F4F4F4;
  }

  .search-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    // border: 1px red solid;
    border-bottom: 1px #DFDFE1 solid;
    // background-color: #F4F4F4;
    padding: 0 32px;

    .search-header-l {
      img {
        width: 20px;
      }
    }

    .search-header-r {
      width: 100%;
      height: 32px;
      flex: 1;
      // background-color: transparent;
      letter-spacing: 0;
      background: #FFFFFF;
      border-radius: 16px;
      // border: 1px red solid;
      margin-left: 16px;
      position: relative;

      img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 8px;
        left: 16px;
      }

      .adm-input-element {
        text-indent: 38px;
        line-height: 32px;
      }

      input::-webkit-input-placeholder {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
      }
    }
  }

  .search-item {
    height: calc(100vh - 60px);
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;

    .search-item-no-data {
      text-align: center;

      img {
        width: 61px;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
        letter-spacing: 0;
        margin-top: 24px;
      }
    }
  }

  .search-con-item {
    padding: 10px 0;
    box-sizing: border-box;

    height: calc(100% - 61px);
    overflow-y: auto;

    .content-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 16px 24px;

      &:hover {
        background: #F6F8FA;
      }

      .content-item-type {
        display: flex;
        width: calc(100% - 60px);

        img {
          width: 32px;
          max-height: 42px;
          object-fit: contain;
          object-position: center;
        }

        .content-item-txt {
          margin-left: 16px;

          h4 {
            font-family: HelveticaNeue;
            font-size: 16px;
            color: #1E2128;
            line-height: 18px;
          }

          p {
            font-family: HelveticaNeue;
            font-size: 12px;
            color: #000029;
            line-height: 18px;
          }
        }
      }

      .content-item-more {
        img {
          width: 20px;
        }
      }
    }
  }

}
