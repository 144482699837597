.ui-head-nav-tom{
  padding: 16px 0;
  width: 60%;
  // margin-top: 32px;
  // display: flex;
  // justify-content: space-between;

  //  border-bottom: 1px #D0D7DE solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // height: 60px;
  height: auto;
  box-sizing: border-box;

  .head-nav-start{
    width: 65%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    img{
      vertical-align: middle;
    }
  }

  .head-nav-end{
    //width: 40%;
  }

  .head-nav-title{
    font-family: HelveticaNeue-Bold;
    font-size: 30px;
    color: #24292F;
    letter-spacing: 0;
    line-height: 18px;
    // width: calc(100% - 40px);
    display: flex;
    // flex-wrap: wrap;
    div:nth-child(n+14){
      margin-top: 12px;
    }
    div {
      font-family: HelveticaNeue;
      font-size: 14px;
      letter-spacing: 0;
      p {
        cursor: pointer;
        span {
          color: #16ADFD;
          font-family: HelveticaNeue;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 14px;
        }
      }
    }
    .head-nav-title-actvie {
      font-family: HelveticaNeue;
      font-size: 18px;
      color: #57606A;
      letter-spacing: 0;
      line-height: 14px;
      margin-top: 2px;
    }
    
    .adm-ellipsis{
      font: inherit;
    }
  }

  .head-nav-icon{
    margin-right: 16px;
    width: 20px;
    cursor: pointer;
    display: inline-block;
    img {
      width: 16px;
    }
  }
}
