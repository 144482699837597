// @variable-bg: #16ADFD;
@variable-bg: #F6F8FA;
@variable-color-white: #fff;
@variable-color-eaeef2: #eaeef2;
@variable-color-24292F: #24292F;
@variable-color-57606A: #57606A;

.top-bar {
    // height: 48px;
    height: 64px;
    width: 100%;
    background-color: @variable-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-bar-l {
        margin-left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        div:nth-child(1) {
            margin-right: 12px;
        }
        div:nth-child(2) {
            font-family: HelveticaNeue-Bold;
            font-size: 20px;
            color: @variable-color-24292F;
            letter-spacing: 0;
        }
    }
    .top-bar-r {
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .message {
           margin-right: 60px;
           path {
            fill: #57606A; 
           }
        }
        .user {
            display: flex;
            display: flex;
            justify-content: center;
            align-items: center;
            .user-txt {
                margin-right: 12px;
                text-align: right;
                h4 {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-color-24292F;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                p {
                    font-family: HelveticaNeue;
                    font-size: 10px;
                    color: @variable-color-57606A;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }
            .user-img {
                margin-right: 8px;
                img {
                    width: 32px;
                    height: 32px;
                }
            }
            .user-down {
                cursor: pointer;
            }
        }
    }
}