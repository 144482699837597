.details {
  .ui-head-nav {
    margin: 0 16px;
  }

  // padding: 0 16px;
  .details-con {
    border-top: 1px #F4F4F4 solid;

    .details-con-img {
      width: 176px;
      height: 176px;
      margin: 24px auto;
      overflow: hidden;
      position: relative;

      .adm-image{
        height: 100%;
        width: 100%;
      }

      .a-ply-btn{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        width: 100%;

      }
    }

    .details-content {
      .details-content-item {
        margin: 0 16px;
        display: flex;
        border-bottom: 1px #F4F4F4 solid;
        border-radius: 1.5px;
        padding-bottom: 8px;
        margin-top: 8px;

        div:nth-child(1) {
          flex: 1;

          p:nth-child(1) {
            font-family: HelveticaNeue;
            font-size: 12px;
            color: #5E6065;
            letter-spacing: 0;
            line-height: 14px;
          }

          p:nth-child(2) {
            font-family: HelveticaNeue;
            font-size: 18px;
            color: #000F00;
            letter-spacing: 0;
            line-height: 24px;
            word-break: break-all;
          }
        }

        div:nth-child(2) {
          width: 20px;
          height: 20px;
          padding-top: 6px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
