.--file-preview-page{


  .--head{
    height: 60px;
    background: black;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    //position: sticky;
    //top: 0;
  }

  .--body{
    box-sizing: border-box;
    //padding-top: 60px;
    height: calc(100vh - 60px);
  }

  .--iframe{
    width: 100%;
    height: 100%;
    vertical-align: bottom;
  }

  .--title{
    text-align: center;
    color: #FFFFFF;
    font-size: 24px;
  }
}
