@variable-color-white: #fff;
@variable-color-F6F8FA: #F6F8FA;
@variable-color-gray: #EAEEF2;
.outterBodyContainer {
   display: flex;   
   .outterNavContainer {
     width: 240px;
     background-color: @variable-color-F6F8FA;
     height: calc( 100vh - 64px);
     .navItemContianer {
        .ant-menu {
          background-color: @variable-color-F6F8FA;
          margin-top: -4px;
           .ant-menu-item-selected {
            background-color:  @variable-color-white ; 
            path {
              fill: #57606A;
            }
           }
        }
     }
   }
   .contentContainer {
    flex: 1;
    background-color:  @variable-color-white;
    height: calc(100vh - 64px);
    overflow-y: auto;
    padding: 0 150px;
    // padding: 0 140px;
    position: relative;
   }
}
@media screen and (max-width: 1500px) {
  .contentContainer {
    // .cloud-plan 
    padding: 0 24px !important;
  }
}

:where(.css-dev-only-do-not-override-14qglws).ant-menu .ant-menu-item, :where(.css-dev-only-do-not-override-14qglws).ant-menu .ant-menu-submenu, :where(.css-dev-only-do-not-override-14qglws).ant-menu .ant-menu-submenu-title{
   border-radius: 0 !important;
}