.play-list-comp{
  height: 100%;
  position: relative;
  width: 340px;

  .o-btn{
    background-color: #24292F;
    display: block;
    width: 39px;
    height: 72px;

    position: absolute;
    top: calc(50% - 36px);
    left: -35px;
    border-top-left-radius: 36px;
    border-bottom-left-radius: 36px;

    cursor: pointer;

    .o-btn-icon{
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAHFJREFUSA1jYBhR4P///05AbE8TTwMNTgfif0D8lOoWIBkOZP7PoqoFaIbnjRoODoHRYMGaEIDBAspEoHQOAlRLLUxYbaOVINDlsNxKVV+guHfUEpTgIMQZtsFF3eIaFoxIwUX9CgfJEtpVmTBLBh0NADaNAPfz64EGAAAAAElFTkSuQmCC);
      background-repeat: no-repeat;
      background-position: 7px 0px;

      display: block;
      width: 24px;
      height: 24px;

      position: absolute;
      top: calc(50% - 12px);
      right: 0;

      &.rt-180{
        background-position: 2px 0px;
        transform: rotate(180deg);
      }
    }


  }

  .o-list{
    box-sizing: border-box;
    padding: 10px 10px;
    height: 100%;
    overflow-y: auto;
  }

  .o-item-date-label{
    padding: 15px 10px 10px;
    font-size: 21px;
    color: #FFFFFF;
    font-family: HelveticaNeue-Medium;
  }

  .o-item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 10px;
    cursor: pointer;
    border-radius: 6px;
    overflow: hidden;

    position: relative;

    transition: background-color 0.35s;

    &:hover{
      background: rgba(255,255,255,0.2);
    }
  }

  .--end-load-more-flag{

  }

  .o-end-icon{
    position: absolute;
    width: 32px;
    height: 32px;

    right: 10px;
    top: calc(50% - 16px);
    pointer-events: none;

    .ant-image{
      width: 100%;
      height: 100%;
    }
  }

  .o-icon{
    border-radius: 6px;
    height: 72px;
    width: 128px;
    overflow: hidden;

    margin-right: 16px;

    .ant-image{
      width: 100%;
      height: 100%;
    }
  }

  .o-cont{

  }

  .o-t1{
    font-size: 16px;
    font-family: HelveticaNeue-Medium;
    margin-bottom: 6px;
    color: #ffffff;
  }

  .o-t2{
    font-size: 14px;
    font-family: HelveticaNeue;
    color: #ffffff;
  }
}
