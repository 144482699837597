.page-plan{
  height: 100%;
  background: #F4F4F4;

  .adm-button{
    line-height: normal;
  }

  .page-head-container{
    padding: 0 16px;
    border-bottom: 1px solid #DFDFE1;
    height: 57px;
  }

  .page-body-container{
    padding: 30px 24px;
    box-sizing: border-box;
    height: calc(100% - 57px);

    overflow-y: auto;

    &.-no-head{
      height: 100%;
      padding-top: 60px;
    }
  }

  .adm-progress-bar-fill{
    //background-color: #16ADFD;
  }

  .adm-button-disabled{
    opacity: 0.75;
  }

  .header {
    width: 100%;
    height: 52px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    border-bottom: 1px #F4F4F4 solid;

    .header-menu {
      img {
        width: 20px;
        //margin-left: 16px;
        vertical-align: bottom;
      }
    }

    .header-txt {
      margin-left: 15px;
      font-family: HelveticaNeue-Bold;
      font-size: 24px;
      color: #1E2128;
      letter-spacing: 0;
    }


  }

  .a-loading-bx{
    text-align: center;
    height: 100%;
    position: relative;

    .adm-spin-loading{
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .a-lb-i-note{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #FA741B;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 3px;
  }

  .a-title-i{
    font-family: HelveticaNeue-Bold;
    font-size: 24px;
    color: #1E2128;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    margin-bottom: 22px;
  }

  .a-decs-i{
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #5E6065;
    letter-spacing: 0;
    text-align: center;
  }

  .a-group-btn{
    font-family: HelveticaNeue-Medium;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 18px;

    display: flex;
    flex-direction: row;

    margin-bottom: 16px;


    button{
      background: transparent;
      border: 1px solid #5E6065;
      width: calc(100% / 2);
      height: 40px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.a-active-btn{
        background: #16ADFD;
        border: 1px solid #16ADFD;
      }
    }

    .a-btn-start{
      border-radius: 8px 0 0 8px;
      border-right: 0;
    }

    .a-btn-end{
      border-radius: 0px 8px 8px 0px;
      border-left: 0;
    }

    .a-btn-label{
      font-family: HelveticaNeue-Medium;
      font-size: 16px;
      color: #1E2128;
      letter-spacing: 0;
      text-align: center;
      line-height: 13px;
    }

    .a-btn-intro{
      font-family: HelveticaNeue;
      font-size: 11px;
      color: #000029;
      letter-spacing: 0;
      text-align: center;
      line-height: 13px;
      margin-top: 3px;
    }

    .a-active-btn{

      .a-btn-label{
        color: #FFFFFF;
      }

      .a-btn-intro{
        color: #FFFFFF;
      }
    }
  }

  .a-card-ii{
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04), 0 8px 16px 0 rgba(0,0,0,0.08);
    border-radius: 18px;
    padding: 15px 0 20px;

    margin-bottom: 16px;
    box-sizing: border-box;
    border: 2px solid #FFFFFF;

    position: relative;

    &.a-act{
      border: 2px solid #16ADFD;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04), 0 8px 16px 0 rgba(0,0,0,0.08);
    }

    .a-ps-end{
      position: absolute;
      top: -4px;
      right: -4px;
    }
  }

  .a-card-hgt{
    height: 128px;
  }

  .a-flex-col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .a-plan-name{
      margin-bottom: 0;
    }
  }

  .a-pad-top{
    padding-top: 20px;
  }

  .a-acrd-body{
    padding: 0 16px;
  }

  .a-plan-name{
    font-family: HelveticaNeue-Bold;
    font-size: 18px;
    color: #1E2128;
    letter-spacing: 0;
    margin-bottom: 28px;
  }

  .a-progress-bar{
    margin-bottom: 6px;
  }

  .a-bar-size{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #5E6065;
    letter-spacing: 0;
    margin-bottom: 24px;
  }

  .a-plan-intro{
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #5E6065;
    letter-spacing: 0;
    line-height: 18px;
  }

  .a-flex-ii{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .a-btn-con{

    button{
      border-radius: 18px;

      font-family: HelveticaNeue-Medium;
      font-size: 16px;

      letter-spacing: 0;
      text-align: center;
      border: 0;
      outline: 0;
      width: 120px;
      height: 36px;

      box-sizing: border-box;

      &.a-default-btn{
        color: #5E6065;
        background: transparent;
        border: 1px solid #DFDFE1;
      }

      &.a-primary-btn{
        color: #FFFFFF;
        background: #16ADFD;
        border: 1px solid #16ADFD;
        box-shadow: 0 2px 4px 0 rgba(22,173,253,0.04), 0 8px 16px 0 rgba(22,173,253,0.16);
      }

      &.a-default-btn-i{
        color: #16ADFD;
        background: transparent;
        border: 2px solid #16ADFD;
      }
    }
  }

  .a-price-con{


  }

  .a-price-num{
    margin-bottom: 4px;
  }

  .a-price-xiff{
    font-family: HelveticaNeue-Medium;
    font-size: 12px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 2px;
  }

  .a-price-nui{
    font-family: HelveticaNeue-Bold;
    font-size: 18px;
    color: #000F00;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 2px;
  }

  .a-price-unit{
    font-family: HelveticaNeue-Medium;
    font-size: 12px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 12px;
  }

  .a-price-desc{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #000029;
    letter-spacing: 0;
    line-height: 10px;
  }

  .a-button-i{
    font-family: HelveticaNeue-Medium;
    font-size: 18px;
    color: #1E2128;
    letter-spacing: 0;

    border-bottom: 1px solid #DFDFE1;
    margin-bottom: 18px;
    text-align: center;
    padding-bottom: 12px;
  }


  .a-ft-bot-box{
    padding-top: 8px;

    .a-lb-title{
      font-family: HelveticaNeue-Medium;
      font-size: 12px;
      color: #1E2128;
      letter-spacing: 0;
      line-height: 18px;
    }

    .a-lb-intro{
      font-family: HelveticaNeue;
      font-size: 12px;
      color: #000029;
      letter-spacing: 0;
      line-height: 18px;

      margin-bottom: 20px;
    }

    a{
      color: #16ADFD;
      text-decoration: underline;
    }
  }

}



