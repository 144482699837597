@variable-24292F: #24292F;
@variable-0A0000: #0A0000; 
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065:  #5E6065; 
@variable-37352F:  #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2; 
@variable-D0D7DE: #D0D7DE;
@variable-6E7781: #6E7781;
@variable-16ADFD:  #16ADFD;
@variable-2F25F7: #2F25F7;
@variable-white:  #fff;

.prompt-conter {
    background: @variable-EAEEF2;
    border-radius: 12px !important;
    width: 520px !important;
    // height: 360px !important;
    .ant-modal-content {
        background: @variable-EAEEF2;
        border-radius: 12px !important;
        .ant-modal-header {
            display: none;
        }
        .ant-modal-close {
            display: none;
        }
    }
    .prompt {
        text-align: center;
        font-family: HelveticaNeue-Bold;
        font-size: 30px;
        color: #24292F;
        letter-spacing: 0
    }
    .prompt-con {
        .ant-input  {
            height: 44px;
            background: @variable-white;
            border: 1px solid @variable-D0D7DE;
            border-radius: 2px;
        }
       
    }
    .prompt-footer {
        margin-top: 33px;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
            .ant-btn {
                width: 192px;
                height: 44px;
            }
            .ant-btn:nth-child(1) { 
                margin-right: 24px;
                background: @variable-white;
                border-radius: 24px;
                font-family: HelveticaNeue-Bold;
                font-size: 18px;
                color: @variable-57606A;
                letter-spacing: 0;
                text-align: center;
                border: 1px @variable-white solid;
                 &:hover {
                    border: 1px @variable-white solid;
                }
            }
            .ant-btn:nth-child(2) { 
                background: @variable-16ADFD;
                border-radius: 24px;
                font-family: HelveticaNeue-Bold;
                font-size: 18px;
                color: @variable-white;
                letter-spacing: 0;
                text-align: center;
                border: 1px @variable-16ADFD solid;
                 &:hover {
                    border: 1px @variable-16ADFD solid;
                }
            }
    }
}