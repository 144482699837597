@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065: #5E6065;
@variable-37352F: #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-D0D7DE: #D0D7DE;
@variable-6E7781: #6E7781;
@variable-16ADFD: #16ADFD;
@variable-2F25F7: #2F25F7;
@variable-white: #fff;

.file-upload-information {
  background: #FFF;
  border-radius: 6px !important;
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 385px !important;
  z-index: 999;

  box-shadow: 0 2px 32px 0 rgba(0,0,0,0.10);

  border-radius: 6px;

  .file-information-modal-title {
    border-bottom: 1px #D0D7DE solid;

    height: 45px;
    line-height: 45px;
    position: relative;

    .a-title{
      font-family: HelveticaNeue-Bold;
      font-size: 20px;
      color: @variable-1E2128;
      letter-spacing: 0;
      text-align: center;
    }
  }

  .close-btn-a{
    position: absolute;
    right: 5px;
    top: 0;

    width: 44px;
    height: 44px;
    background-color: @variable-white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .ant-modal-content {
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.10);
    border-radius: 6px;
    padding: 0;

    .ant-modal-header {
      display: none;
    }

    .ant-modal-close {
      display: none;
    }
  }

  .prompt {
    font-family: HelveticaNeue-Medium;
    font-size: 18px;
    color: #24292F;
    text-align: center;
    border-bottom: 1px #D0D7DE solid;
    height: 50px;
    line-height: 50px;
  }

  .prompt-con {
    display: flex;
    padding: 22px 32px;

    div:nth-child(1) {
      width: 32px;
      height: 32px;
    }

    div:nth-child(2) {
      width: 248px;

      p:nth-child(1) {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
        line-height: 20px;
      }

      p:nth-child(2) {
        width: 100%;
        background: #D0D7DE;
        height: 4px;
        margin-top: 12px;
        position: relative;

        span {
          background-color: #16ADFD;
          position: absolute;
          top: 0;
          left: 0;
          height: 4px;
        }
      }
    }
  }

  .upload-file-items {
    box-sizing: border-box;
    padding: 10px 16px;

    //min-height: 300px;
    overflow-y: auto;
    //max-height: 410px;
    height: 410px;
  }

  .upload-file-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    box-sizing: border-box;
    padding: 15px 0;
  }

  .icon-name-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: calc(100% - 80px);
  }

  .icon-box {
    width: 24px;

    margin-right: 15px;
  }

  .name-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: calc(100% - 40px);

    position: relative;

  }

  .-name {
    width: 100%;

    position: absolute;
    left: 0;
    top: -6px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .progress-box {
    width: 100%;
  }

  .opt-box {
    width: 80px;
    height: 30px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .opt-btn {
      width: 30px;
      display: inline-block;
      margin-left: 10px;

      img {
        width: 100%;
        vertical-align: bottom;
      }
    }
  }

  .ant-progress .ant-progress-bg {
    background-color: #16adfd;
  }

  .ant-progress.ant-progress-status-success .ant-progress-bg {
    background-color: #16adfd;
  }

  .ant-progress .ant-progress-bg {
    transition: all 0ms;
    border-radius: 0;
  }

  .ant-progress-outer{
    transform: scaleY(0.65);
  }

  .file-size{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #57606A;
    letter-spacing: 0;

    transform: scale(0.8);
    transform-origin: left center;

    position: absolute;
    left: 0;
    bottom: -12px;
  }

  .error-msg-a{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: rgb(224, 32, 32);
    letter-spacing: 0;

    text-align: left;
    width: 100%;
    padding-top: 3px;
  }

  .info-msg-a{
    font-size: 12px;
    transform: scale(0.8);
    position: absolute;
    right: 0;
    bottom: -8px;
  }
}

.upload-win-opt-box {

}
