.device-center-play-video-container{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(255,255,255,1);
  z-index: 999;

  .top-box{
    background: #FFFFFF;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.10);
    width: 100%;
    height: 64px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    padding: 0 30px;

    .btn-close{
      cursor: pointer;
    }

    .dev-name{
      font-family: Helvetica65-Medium;
      font-size: 24px;
      color: #1E2128;
      letter-spacing: 0;

      position: relative;
      cursor: default;

      display: flex;
      flex-direction: row;
      align-items: center;

      .dp-icon{
        margin-left: 16px;
        position: relative;
        top: 3px;
      }
    }

    .btn-setting{
      cursor: not-allowed;
    }
  }

  .video-out-box{
    background: black;
    height: calc(100% - 64px);
  }


  .nvr-channel-box{
    max-width: 800px;
    width: 90vw;
    box-sizing: border-box;
    position: absolute;
    background: #FFFFFF;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 25px);
    border-radius: 6px;

    user-select: none;

    .nvr-box-title{
      text-align: center;
      padding: 10px 0;
    }

    .nvr-channel-list{
      //display: flex;
      //flex-direction: row;
      //flex-wrap: wrap;

      box-sizing: border-box;
      padding: 0 16px 12px;

      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 12px 10px;
    }

    .nvr-channel-item{
      //width: calc((100% - 50px) / 6);
      border: 1px rgba(0,0,0,0.05) solid;
      border-radius: 9px;

      //margin-right: 10px;
      text-align: center;
      box-sizing: border-box;

      //margin-bottom: 12px;
      cursor: pointer;
      padding-bottom: 5px;

      //background: rgba(0,0,0,0.15);
    }

    .nvr-channel-item-active{
      border-color: #16adfd;

      .nvr-channel-name{
        color: #16adfd;
      }
    }

    .nvr-dev-img{

      img{
        width: 50%;
        vertical-align: bottom;
      }
    }

    .nvr-channel-name{
      font-size: 14px;
    }
  }
}
