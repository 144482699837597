.free-trial-subscribe-modal{

  .free-trial-sub-modal-main-box{
    height: calc(100vh - 90px);

    iframe{
      width: 100%;
      height: 100%;
    }
  }
}
