.device-center-alerts{
  box-sizing: border-box;
  padding: 17px 0 30px;
  // padding: 0 0 30px;

  .--head-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .ant-btn span{
      font-weight: bold;
    }
  }

  .--body-box{
    box-sizing: border-box;
    padding: 32px 0;
  }

  .dev-name-items{

  }

  .dev-name-item{
    font-family: HelveticaNeue-Medium;
    font-size: 14px;
    color: #57606A;
    letter-spacing: 0;
    text-align: center;
    // background: #FFFFFF;
    border-radius: 16px;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 15px;
    background: #F6F8FA;
    border-radius: 16px;

    margin-right: 12px;
    cursor: pointer;

    user-select: none;

    &:nth-last-of-type(1){
      margin-right: 0;
    }
  }

  .dev-name-item-active{
    color: #FFFFFF;
    // background: #16ADFD;
    background-color:#24292F;
    font-family: HelveticaNeue-Medium;
  }

  .fliter-box{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .grid-items{
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 16px;
    column-gap: 6%;
  }

  .dev-alert-item{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    height: 72px;
    //outline: 1px solid gainsboro;

    cursor: pointer;
  }

  .date-selector{
    height: 32px;
    background: #16ADFD;
    border-radius: 16px;

    width: 175px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .change-date-btn-l{
      padding: 8px 5px 8px 12px;
      display: inline-block;

      cursor: pointer;
    }

    .change-date-btn-r{
      padding: 8px 12px 8px 5px;
      display: inline-block;

      cursor: pointer;
    }

    .ant-picker{
      color: #FFFFFF;
    }

    .ant-picker-outlined{
      background: #16ADFD;
      border: 0;
    }

    .date-input{
      cursor: pointer;

      input{
        cursor: pointer;
      }
    }
  }

  .edit-btn{
    width: 18px;
    margin-left: 24px;
    cursor: pointer;
    user-select: none;

    img{
      width: 100%;
      vertical-align: bottom;
    }
  }

  .option-status-box{
    width: 22px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .option-item{
      width: 22px;
      height: 22px;

      img{
        width: 100%;
        vertical-align: bottom;
      }
    }

    .v-line{
      background: #D0D7DE;
      width: 1px;
      height: calc(100% - 22px);

      position: relative;
      top: 4px;

    }
  }

  .words-image-box{
    width: calc(100% - 22px);
    box-sizing: border-box;
    padding:  0 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .words-box{


      .h1{
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: #24292F;
        letter-spacing: 0;

        margin-bottom: 6px;
      }

      .w1{
        font-family: HelveticaNeue;
        font-size: 12px;
        color: #57606A;
        letter-spacing: 0;
        line-height: 16px;
      }
    }

    .image-box{
      width: 128px;
      height: 72px;
      border-radius: 6px;
      overflow: hidden;

      .ant-image{
        width: 100%;
        height: 100%;
      }

      img{
        width: 100%;
        height: 100%;
        vertical-align: bottom;
        object-position: center;
        object-fit: cover;
      }

      .ant-image-mask{
        opacity: 0;
      }
    }
  }

  .loading-status-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-data-box{
    text-align: center;

    background: rgba(255,255,255,0.5);
    box-sizing: border-box;
    padding: 25px 0;
    border-radius: 8px;

    .text-18{
      font-family: HelveticaNeue;
      font-size: 18px;
      color: #57606A;
      letter-spacing: 0;
      line-height: 18px;

      margin-top: 15px;
    }
  }

  .ant-btn-default.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):hover,
  .ant-btn-default.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover{
    color: #16ADFD;
    border-color: #16ADFD;
  }
}

.alert-video-modal{

  .ant-modal-footer{
    display: none;
  }

  .ant-modal-content{
    padding: 10px 10px 10px 10px;
  }

  .ant-modal-close{
    top: 6px;
    right: 5px;
  }
}
