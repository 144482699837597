.a-md-cont-241230valeyolu{

  .a-md-icon{

  }

  .a-md-ws{
    font-family: HelveticaNeue-Bold;
    font-size: 22px;
    line-height: 1.25;
  }

  .a-md-textarea{
    font-size: 16px;
  }

  textarea{
    outline: none;
    font-size: 100%;
  }

  textarea:active, textarea:focus{
    border-color: #16ADFD;
  }
}

.ant-modal:has(.a-md-cont-241230valeyolu) {


  .ant-modal-footer{
    text-align: center;
    margin-top: 25px;
    margin-bottom: 10px;

    .ant-btn{
      padding: 4px 55px;
      border-radius: 25px;
      height: 36px;
    }

    .ant-btn+.ant-btn{
      margin-inline-start: 20px;
    }
  }
}
