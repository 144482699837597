.content-all {
  position: relative;
  box-sizing: border-box;
  // padding-left: 12px;
  .content-select {
    position: absolute;
    top: 24px;
    left: 0;
    // left: -31px;
    cursor: pointer;
    z-index: 1;
  }
}
.adm-pull-to-refresh-content {
  .content-all-tom {
    border-bottom: 1px #EAEEF2 solid;
  }
  .content-all-tom:last-child {
    border-bottom: none;
  }
}
.content-all-tom {
  .content-item-date, .content-item-size {
    width: 15% !important;
    display: flex;
    align-items: center;
   } 
   .content-item-more {
    display: flex;
    width: 15% !important;
    justify-content: flex-end;
    img {
      margin-right: 24px;
    }
   }
}

.content-two{
  // margin-top: -20px;
  .-card-checkbox{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  
   .adm-image {
    display: flex;
    justify-content: center;
   }
  .adm-image-img{
    width: 72px !important;
    height: 72px !important;
    margin-top: 20px;
  }

  .vblock-player-btn{
    position: absolute;
    // top: 50%;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .file:has(.-dir){
    height: calc(100% - 30px);
    display: flex;
    justify-content: center;
    align-items: center;
   
  }

  .file {
    cursor: pointer;
  }
  .file.-folder{
    height: calc(100% - 30px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-select{
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
    z-index: 8;
  }

  .more{
    width: 60px !important;
    right: 0;
  }
}


.content-item{
  //  margin-top: 12px;
   .content-item-type {
    position: relative;
    width: 40% !important;
    display: flex;
    align-items: center;
    // padding-left: 24px;
    .content-item-txt {
      .icon-favourite-on {
        width: 20px;
        position: absolute;
        right: 24px;
        cursor: pointer;
      }
    }
   }
  
   .right-context-menu {
    position: absolute;
    width: calc(100% - 90px);
    height: 64px;
    display: flex;
    align-items: center;
    margin-left: 40px;
    // background-color: #fff;
    background: #F6F8FA;
    top: 0;
    // top: 8px;
    .right-context-menu-con {
      display: flex;
      // margin-left: 40px;
      .ant-input {
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #16ADFD;
        border-radius: 4px;
        width: 220px;
      }
       p:nth-child(2), p:nth-child(3) {
            width: 36px;
            height: 36px;
            margin-left: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
       }
    }

   }
  .adm-image{
     width: 32px;
     height: 32px;
  }
}
.content-item-active {
  background-color: #FFFFFF;
}

.vlist-file-icon-box{
  position: relative;
  display: block;
  width: 32px;
  max-height: 42px;

  .vlist-player-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.content-two-item {
  
  .right-context-menu-con {
    display: flex;
    // margin-left: 40px;
    .ant-input {
      // height: 46px;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid #16ADFD;
      border-radius: 4px;
      width: 200px;
      margin-left: 5px;
    }
  }
  .slelect {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 999;
    p:nth-child(1) {
      margin-right: 4px;
    }
    p {
      width: 28px;
      height: 28px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
}

// .right-context-menu {
//   position: absolute;
//   width: calc(100% - 65px);
//   height: 64px;
//   display: flex;
//   align-items: center;
//   margin-left: 40px;
//   background-color: #fff;
//   bottom: -12px;
//   margin-left: 0px;
//   .right-context-menu-con {
//     display: flex;
//     // margin-left: 40px;
//     .ant-input {
//       height: 46px;
//       background: #FFFFFF;
//       border: 1px solid #16ADFD;
//       border-radius: 4px;
//       width: 200px;
//     }
//      p:nth-child(2), p:nth-child(3) {
//           width: 46px;
//           height: 46px;
//           margin-left: 12px;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           cursor: pointer;
//      }
//   }

//  }
.content-item-more {
  cursor: pointer;
}
.right-context-menu-tom {
   position: absolute;
   bottom: 8px;
   left: 0;
}