.page-device-center{


  .main-container{

  }

  .h1-title{
    font-family: HelveticaNeue-Bold;
    font-size: 30px;
    color: #24292F;
    letter-spacing: 0;

    // padding: 30px 0;
    padding: 30px 0 0;
  }

  .device-center-nav{

    .ant-menu-item{
      font-family: HelveticaNeue-Medium;
      font-size: 24px;
      color: #57606A;
      letter-spacing: 0;

      &:nth-of-type(1){
        padding-left: 0;

        &::after{
          left: 0;
        }
      }
    }

    &.ant-menu-light{
      background: transparent;
    }

    &.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected{
      color: #24292F;

      &::after{
        //border-bottom-color: #16ADFD;
        //left: 0;
      }
    }
  }
}
