.e-vplayer-ii-container{
  //width: 1000px;
  //height: 600px;
  background: #000000;
  height: 100%;

  .e-video-box{
    min-height: 390px;
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    flex-direction: row;

    &.e-has-player-bar{
      height: calc(100% - 100px);
    }
  }

  .e-video-bar-box{
    height: 100px;
    width: 100%;
    box-sizing: border-box;
  }

  .e-video-preview-box{
    width: 100%;
    height: calc(100% - 50px);
  }

  .a-btn-live{
    display: inline-block;
    background: #FFFFFF;
    padding: 10px 35px;
    width: 200px;
    box-sizing: border-box;
    border-radius: 3px;
    text-align: center;
    margin-left: calc(50% - 100px);
    margin-top: 5px;
    cursor: pointer;
  }

  .e-vplayer-control-box{
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 5px 25px 5px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    background: rgba(0,0,0,0.65);

    .vplayer-control-box{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }

    .vplayer-display-box{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }

    .vplayer-option-box{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }

    .v-btn{
      max-width: 20px;
      display: inline-block;
      cursor: pointer;

      &:not(:nth-last-of-type(1)){
        margin-right: 25px;
      }

      &>img{
        width: 100%;
        vertical-align: baseline;
        height: auto !important;
      }

      &>svg{
        width: 100%;
        vertical-align: baseline;
      }

      i{
        font-style: normal;
      }
    }

    .v-btn-player{

    }

    .v-btn-sound{

    }

    .v-btn-live{
      color: #ffffff;
      display: inline-block;
      padding: 0px 5px;
      text-align: center;
      border: 1px solid #ffffff;
      border-radius: 1px;
      max-width: unset;
    }

    .v-btn-calendar{
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      max-width: 100px;

      .v-dis-date{
        color: #FFFFFF;
        font-size: 12px;
        padding-left: 6px;
        position: relative;
        //top: -3px;
      }

      .ant-picker{
        padding: 0;
        border-radius: 0;
        background: transparent;
        border: 0;

        input{
          display: none;
        }

        .ant-picker-suffix{
          margin-inline-start: 0;
        }
      }
    }

    .v-btn-duration{
      max-width: 80px;
      color: #ffffff;
      font-size: 12px;

      display: inline-flex;
      flex-wrap: nowrap;
      flex-direction: row;
      border: 1px solid #ffffff;
      border-radius: 1px;

      i{
        display: inline-block;
        padding: 0px 5px;
        width: 25px;
        text-align: center;
      }

      .v-act{
        background-color: #FFFFFF;
        color: #000000;
      }
    }

    .v-btn-definition{
      max-width: 80px;
      color: #ffffff;
      font-size: 12px;

      display: inline-flex;
      flex-wrap: nowrap;
      flex-direction: row;
      border: 1px solid #ffffff;
      border-radius: 1px;

      i{
        display: inline-block;
        padding: 0px 5px;
        width: 25px;
        text-align: center;
      }

      .v-act{
        background-color: #FFFFFF;
        color: #000000;
      }
    }

    .v-btn-montage{

    }

    .v-btn-full{

    }
  }

  .vp-win-area{
    position: absolute;
    right: 0;
    left: 0;
  }


  .ii-video-preview-box{
    height: 100%;
    width: calc(100% - 340px);

    position: relative;
  }

  .ii-play-list-box{
    height: 100%;
    width: 340px;

    background-color: #24292F;
    position: relative;
    z-index: 1;

    //transition: width 0.15s;
  }

  .ii-play-list-hide{
    width: 0px;
  }

  &:has(.ii-play-list-hide){
    .ii-video-preview-box{
      width: 100%;
    }

    .ii-play-list-box{
      width: 0px;
    }
  }
}

.a-date-has-recode{
  //font-weight: bold;

}

.ant-picker-cell{
  .a-date-has-recode{
    position: relative;

    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      padding: 4px;
      border-radius: 50%;
      background: #16ADFD;

      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) scale(0.6);
      opacity: 0.6;
    }
  }
}
.ant-picker-cell-in-view{

  .a-date-has-recode{

    &::before{
      opacity: 1;
    }
  }
}
