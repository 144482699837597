.trash-page{
   position: relative;
   //height: calc(100vh - 68px);
   .file-item-list {
    padding-bottom: 100px !important;
   }
   .content-title-header {
    display: flex;
    border-bottom: 1px #D0D7DE solid;
    height: 35px;
    position: relative;
    margin-top: 16px;
    .select-all {
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
    div:nth-child(1) {
      width: 40%;
      padding-left: 30px;
      .order-img {
        width: 12px;
        height: 15px;
        margin-left: 8px;
      }
    }
    div {
      width: 20%;
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #24292F;
      line-height: 20px;
    }
  }
  .ui-head-nav{
    padding: 19px 16px;
  }
  .vault-head-contain {
    // top: 194px;
    top: 0
  }
  .content-two {
    // margin-top: -12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // padding: 0 24px;

    .content-two-item {
      width: 152px;
      height: 152px;
      background: #FFFFFF;
      border-radius: 8px;
      margin-top: 24px;
      position: relative;
      overflow: hidden;

      .more {
        display: flex;
        justify-content: flex-end;
        margin: 0px;
        position: absolute;
        width: 100%;
        z-index: 1;
        padding: 2px 2px;
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.85));
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .a-more-btn{
          display: inline-flex;
          //height: 12px;
          padding: 6px 10px;
        }

        img {
          width: 20px;
          vertical-align: bottom;
        }
      }

      .file {
        //display: flex;
        //justify-content: center;

        img {
          width: 72px;
          vertical-align: middle;
        }
      }

      .real-file{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .adm-image{
          width: 100%;
          height: 100%;
        }

        img{
          width: 100%;
        }
      }

      .txt {
        margin-top: 12px;
        //font-family: HelveticaNeue;
        //font-size: 16px;
        //color: #1E2128;
        letter-spacing: 0;
        text-align: center;
        //line-height: 18px;
        padding: 0 10px;

        font-family: HelveticaNeue;
        font-size: 14px;
        color: #24292F;
        letter-spacing: 0;
        text-align: center;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        //position: absolute;
        //left: 0;
        //right: 0;
        //bottom: 5px;

        //display: flex;

        //color: #FFFFFF;
        //background-color: rgba(0,0,0,0.65);
        .txt-nowrap {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .adm-ellipsis{
          transform-origin: center;
          //transform: scale(0.85);
          text-align: center;
          font-family: HelveticaNeue-Bold;
          font-size: 30px;
          color: #24292F;
          letter-spacing: 0;
        }
      }

      .--bottom-text-box{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
      }

      .--date{
        font-family: HelveticaNeue;
        font-size: 11px;
        color: #270000;
        letter-spacing: 0;
        text-align: center;
        opacity: 0.6;
      }
    }
  }

  .head-nav-end:has(.trash-search-box){
    position: relative;
    width: 35%;

    .trash-search-box{
      position: absolute;
      right: 0;
      top: -10px;
      width: 175%;
      //max-width: unset;

    }

    .sh-icon{
      position: absolute;
      top: 4px;
      left: 8px;
      padding-top: 1px;
      opacity: 0.5;

      img{
        width: 15px;
        vertical-align: middle;
      }
    }

    .adm-input{

    }

    .adm-input-element{
      outline: 1px solid rgba(0,0,0,0.15);
      border-radius: 15px;
      box-sizing: border-box;
      padding: 0 15px 0 30px;

    }
  }

  &:has(.empty-trash){
    .content{
      height: calc(100% - 150px);
    }
  }
  .controls-tom {
    div:nth-child(1) {
      margin-left: 0 !important;
    }
    .move-to-trash-selected {
      span {
        color: #16ADFD;
        margin-left: 0 !important;
      }
    }
  }
  .page-con {
    padding-top: 40px;
    padding-bottom: 16px;
    .page-con-title {
      font-family: HelveticaNeue-Bold;
      font-size: 30px;
      color: #24292F;
      letter-spacing: 0;
      line-height: 30px;
    }
    
  }
  .no-file-found {
    // margin:  10px auto;
    text-align: center;
    width: 100%;
    padding-top: 180px;
  }
  .content-title-header {
    display: flex;
    border-bottom: 1px #D0D7DE solid;
    height: 35px;
    position: relative;
    margin-top: 16px;
    .select-all {
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px !important;
      height: 18px !important;
      cursor: pointer;
    }
    div:nth-child(1) {
      width: 40% !important;
      padding-left: 30px;
      .order-img {
        width: 12px;
        height: 15px;
        margin-left: 8px;
      }
    }
    div {
      width: 20% !important;
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #24292F;
      line-height: 20px;
    }
  }
  .content-title {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    .move-to-line {
      position: relative;
      width: 1px;
      height: 12px;
      background: #D0D7DE;
      margin-top: 6px;
      margin-left: 20px;
    }
  }
  .page-con-item {
    display: flex;
    align-items: center;
    .page-con-search {
      background: #F6F8FA;
      border-radius: 18px;
      width: 320px;
      height: 36px;
      margin-right: 16px;
      line-height: 36px;
      position: relative;
      .adm-input-element {
        height: 36px;
        line-height: 36px;
        padding-left: 40px;
      }
    }
    .search-img {
      position: absolute;
      top: 10px;
      left: 14px;
      width: 17.5px;
      height: 17.5px;
    }
  }



}



.empty-trash {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    height: 48px;
    background: #fff;
    align-items: center;
    div:nth-child(1) {
        display: flex;
        img {
            width: 14px;
        }
        p {
            margin-left: 12px;
            font-family: HelveticaNeue;
            font-size: 14px;
            // color: #5E6065;
            color: #24292F;
            letter-spacing: 0;
            line-height: 18px;
        }
    }
    .adm-button {
       width: 96px;
       height: 24px;
       background: #F4F4F4;
       border: 1px solid #DFDFE1;
       border-radius: 12.5px;
       font-family: HelveticaNeue-Medium;
       font-size: 12px;
       color: #16ADFD;
       letter-spacing: 0;
       line-height: 10px;
    }
}
.empty-trash {
  position: fixed;
  bottom: 40px;
  height: 56px;
  left: 417px;
  position: fixed;
  background: #fff;
  width: calc(100% - 605px);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.10);
    border-radius: 6px;
   div:nth-child(1) {
     margin-left: 40px;
     display: flex;
     align-items: center;
     p:nth-child(1) {
      width: 36px;
      height: 36px;
      background-color: #EAEEF2;
      border-radius: 50%;
      display: flex;
     align-items: center;
     justify-content: center;
     }
   }
   .adm-button {
      width: 256px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #EAEEF2;
      border-radius: 18px;
      font-family: HelveticaNeue-Medium;
      font-size: 16px;
      color: #16ADFD;
      letter-spacing: 0;
      text-align: center;
   }
 }
