.page-file-items{
  height: 100%;
  background: #F4F4F4;

  .page-head-container{
    padding: 0 16px;
    border-bottom: 1px solid #DFDFE1;
    height: 57px;
  }

  .page-body-container{
    padding: 22px 0 30px;
    box-sizing: border-box;
    height: calc(100% - 57px);

    overflow-y: auto;
  }

  .a-c-intro{
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #5E6065;
    letter-spacing: 0;
    text-align: center;

    margin-bottom: 28px;
  }

  .a-c-list-con{

  }

  .a-c-ahead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-family: HelveticaNeue-Medium;
    font-size: 16px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 16px;

    padding: 0 24px 13px;

    .a-c-lbx{

    }

    .a-c-rbx{

    }

    img{
      vertical-align: bottom;
    }
  }

  .a-c-chg-sty{
    display: inline-block;
    width: 18px;
    height: 18px;
  }

  .a-c-aitem{
    padding: 8px 24px;

    //&:has(.adm-checkbox-checked){
    //  background: #E7E7E8;
    //}

    .adm-checkbox{
      width: 100%;
    }

  }

  .a-c-asize{
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #1E2128;
    letter-spacing: 0;
    text-align: right;
  }

  .a-c-icon-name{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .a-c-aicon{
    width: 56px;
    height: 56px;
    border-radius: 6px;
    background-color: rgba(200,200,200);
    margin-right: 12px;
  }

  .a-c-aname{
    display: flex;
    flex-direction: column;
  }

  .a-c-aflex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .a-fs-h1{
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 18px;
  }

  .a-fs-h3{
    font-family: HelveticaNeue;
    font-size: 12px;
    color: #000029;
    letter-spacing: 0;
    line-height: 18px;
  }


  .a-c-alist{

    .a-c-aitem {

      &:has(.adm-checkbox-checked) {
        background: #E7E7E8;
      }
    }

    .adm-checkbox-content{
      width: calc(100% - 30px);
      padding-left: 0;
    }

    .adm-checkbox-icon{
      margin-right: 10px;
    }
  }

  .a-c-ablock{
    padding: 0 24px;

    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 12px;
    grid-template-columns: repeat(4, minmax(auto, 152px));

    justify-content: space-between;

    @media screen and (max-width: 800px){
      grid-template-columns: repeat(4, minmax(auto, 152px));
    }

    @media screen and (max-width: 600px){
      grid-template-columns: repeat(3, minmax(auto, 152px));
    }

    @media screen and (max-width: 450px){
      grid-template-columns: repeat(2, minmax(auto, 50%));
    }

    @media screen and (max-width: 300px){
      grid-template-columns: repeat(1, minmax(auto, 100%));
    }

    .a-c-aitem{
      box-sizing: border-box;
      padding-left: 0;
      padding-right: 0;

      position: relative;
    }

    .a-c-aicon{
      width: 100%;
      height: 152px;
      border-radius: 8px;
      margin-right: 0;

      margin-bottom: 10px;
    }

    .a-c-icon-name{
      flex-direction: column;

    }

    .a-c-aname{
      align-items: center;
      justify-content: center;
    }

    .adm-checkbox{
      width: 100%;
      --gap: 0;
      position: relative;
    }

    .adm-checkbox-icon{
      background: rgba(0,0,0,0.50);
      border: 1px solid rgba(255,255,255,0.90);
      border-radius: 3px;
    }

    .adm-checkbox .adm-checkbox-icon{
      position: absolute;
      top: 123px;
      right: 10px;
    }

    .adm-checkbox-content{
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
  }

  .adm-checkbox .adm-checkbox-icon{
    border-radius: 3px;
  }

  .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon{
    border-color: #16ADFD;
    background-color: #16ADFD;
  }

  .adm-checkbox{
    --icon-size: 20px;
  }
}
