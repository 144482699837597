@variable-24292F: #24292F;
@variable-0A0000: #0A0000; 
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065:  #5E6065; 
@variable-37352F:  #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;  
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD:  #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white:  #fff;

.order-details {
//    padding: 0 150px;
   .order-details-title {
      margin-top: 32px;
      display: flex;
      align-items: center;
      p:nth-child(1) {
       cursor: pointer; 
     }
      p:nth-child(2) {
        font-family: HelveticaNeue-Bold;
        font-size: 30px;
        color: @variable-24292F;
        letter-spacing: 0;
        margin-left: 12px;
      }
   }
   .order-details-con {
    background: @variable-white;
    border-radius: 12px;
    height: 780px;
    overflow: hidden;
    margin-top: 24px;
    // padding-bottom: 200px;
    .order-information {
        // margin-top: 36px;
        padding: 36px 40px 0;
        .order-information-title {
            font-family: HelveticaNeue-Medium;
            font-size: 18px;
            color: @variable-24292F;
            letter-spacing: 0;
        }
        .order-information-card {
            background: #FFFFFF;
            border: 1px solid @variable-D0D7DE;
            border-radius: 5px;
            padding: 0 24px 24px 24px;
            margin-top: 20px;
            .order-information-card-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 24px;
                .order-information-card-item-l {
                    display: flex;
                    align-items: center;
                    div:nth-child(1) {
                        img {
                            width: 56px;
                            height: 56px;
                        }
                    }
                    div:nth-child(2) {
                        margin-left: 24px;
                        p{
                            font-family: HelveticaNeue-Medium;
                            font-size: 18px;
                            color: @variable-24292F;
                            letter-spacing: 0;
                            line-height: 18px;
                        }
                        p:nth-child(2) {
                            font-family: HelveticaNeue;
                            font-size: 14px;
                            color: @variable-57606A;
                            letter-spacing: 0;
                            line-height: 25px;
                        }
                    }
                }
                .order-information-card-item-r {
                    p {
                        font-family: HelveticaNeue-Medium;
                        font-size: 18px;
                        color: @variable-24292F;
                        letter-spacing: 0;
                        text-align: right;
                        line-height: 18px;
                        span {
                            font-family: HelveticaNeue;
                            font-size: 14px;
                            color: @variable-57606A;
                            letter-spacing: 0;
                            text-align: right;
                            line-height: 18px;
                            margin-right: 16px;
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }
    }
    .information {
        margin-top: 36px;
        padding:  0 40px;
        display: flex;
        justify-content: space-between;
        .information-item {
            h4 {
                font-family: HelveticaNeue-Medium;
                font-size: 18px;
                color: @variable-24292F;
                letter-spacing: 0;
            }
            div:nth-child(2) { 
                margin-top: 16px;
                p{
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-57606A;
                    letter-spacing: 0;
                    line-height: 20px;
                    .active {
                        font-family: HelveticaNeue;
                        font-size: 14px;
                        color: @variable-16ADFD;
                        letter-spacing: 0;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    .hr {
        border: 1 solid @variable-D0D7DE;
        margin-top: 20px;
    }
    .tracking-tab {
        margin-top: 36px;
        padding: 0 40px;
        .tracking-tab-title {
            display: flex;
            p {
                margin-left: 40px;
                font-family: HelveticaNeue-Medium;
                font-size: 18px;
                color: @variable-57606A;
                letter-spacing: 0;
            }
            p:nth-child(1) {
                margin-left: 0;
            }
            .active {
                color: @variable-16ADFD;
            }
        }
    }
    .tracking-tab-con {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        padding: 0 40px;
        .tracking-tab-con-one {
            position: relative;
            width: 16.6%;
            height: 60px;
            div:nth-child(1) {
                display: flex;
                justify-content: center;
                p {
                    width: 56px;
                    height: 56px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #FFFFFF;
                    border: 1px solid @variable-EAEEF2;
                    border-radius: 50%;
                }
                
            }
            div:nth-child(2) {
                margin-top: 6px;
                p {
                    font-family: HelveticaNeue;
                    font-size: 14px;
                    color: @variable-24292F;
                    letter-spacing: 0;
                    text-align: center;
                }
                p:nth-child(2) {
                    font-family: HelveticaNeue;
                    font-size: 12px;
                    color: @variable-57606A;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 18px;
                }
            }
            .shipping {
                margin-top: 12px;
                font-family: HelveticaNeue;
                font-size: 12px;
                color: @variable-0A0000;
                letter-spacing: 0;
                text-align: center;
                line-height: 18px;
                position: absolute;
                bottom: -60px;
                span {
                    color: @variable-16ADFD;
                }
            }
            .comments {
                margin-top: 12px;
                font-family: HelveticaNeue;
                font-size: 12px;
                color: @variable-57606A;
                letter-spacing: 0;
                line-height: 18px;
                position: absolute;
                bottom: -150px;
                text-align: left;
                span {
                    color: @variable-0A0000;
                }
                .active {
                    color: @variable-16ADFD;
                }
            }
            .line {
                border: 2px solid @variable-D0D7DE;
                position: absolute;
                top: 28px;
                left: 140px;
                width: 60%;
            }
        }
        .tracking-tab-con-one-active {
            div:nth-child(1) {
                p {
                    background-color: @variable-16ADFD;
                }
            }
            
        }
    }
    .order-details-check-out {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        .ant-btn {
            background: @variable-16ADFD;
            border-radius: 16px;
            width: 160px;
            height: 32px;
            font-family: HelveticaNeue-Medium;
            font-size: 14px;
            color: @variable-white;
            letter-spacing: 0;
            text-align: center;
        }
    }
   }
}