@variable-24292F: #24292F;
@variable-0A0000: #0A0000;
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065:  #5E6065;
@variable-37352F:  #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-F6F8FA: #F6F8FA;
@variable-6E7781: #6E7781;
@variable-8C959F: #8C959F;
@variable-D0D7DE: #D0D7DE;
@variable-16ADFD:  #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white:  #fff;

.con {
  min-height: 60vh;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  .content-title-header {
    display: flex;
    border-bottom: 1px #D0D7DE solid;
    height: 35px;
    position: relative;
    margin-top: 16px;
    .select-all {
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
    div:nth-child(1) {
      width: 40%;
      padding-left: 30px;
      .order-img {
        width: 12px;
        height: 15px;
        margin-left: 8px;
      }
    }
    div {
      width: 15%;
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #24292F;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .a-header{
    width: 100%;
    height: 52px;
    align-items: center;
    // border-bottom: 1px #F4F4F4 solid;
    padding: 0 16px;
    box-sizing: border-box;

    .ui-head-nav .head-nav-start{
      width: 100%;
    }
    .ui-head-nav .head-nav-title{
      width: calc(100% - 80px);
      //text-align: center;
    }
  }

  .header {
    width: 100%;
    height: 52px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    border-bottom: 1px #F4F4F4 solid;

    .header-menu {
      img {
        width: 20px;
        margin-left: 16px;
        vertical-align: bottom;
      }
    }

    .header-txt {
      margin-left: 15px;
      font-family: HelveticaNeue-Bold;
      font-size: 24px;
      color: #1E2128;
      letter-spacing: 0;
    }


  }

  .header-search {
    // width: 305px;

    height: 32px;
    opacity: 0.6;
    background: #DFDFE1;
    border-radius: 16px;
    position: relative;
    margin: 12px 16px 12px;
    //box-sizing: border-box;

    img {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      left: 16px;
    }

    .header-search-input {
      width: 100%;
      height: 32px;
      background-color: transparent;
      letter-spacing: 0;

      .adm-input-element {
        text-indent: 38px;
      }

      input::-webkit-input-placeholder {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
      }
    }

  }

  .header-pause {
    background: #1E2128;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: -8px;

    p {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 16px;
    }

    .adm-button {
      width: 96px;
      height: 24px;
      background: #F4F4F4;
      border-radius: 12.5px;
      line-height: 8px;

      span {
        font-family: HelveticaNeue-Medium;
        font-size: 12px;
        color: #16ADFD;
        letter-spacing: 0;
      }
    }
  }

  .upgrade-storage {
    background: #1E2128;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: -8px;

    p:nth-child(1) {
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 16px;

      span {
        font-family: HelveticaNeue;
        font-size: 14px;
        color: #16ADFD;
        letter-spacing: 0;
        text-decoration: underline;
      }
    }

    p:nth-child(2) {
      color: #fff;
      font-size: 18px;
      font-family: HelveticaNeue;
      font-weight: bold;
    }
  }

  .add {
    position: fixed;
    right: 12px;
    bottom: 12px;
    z-index: 9;

    img {
      width: 56px;
    }
  }

  .nav-tab--box{


    .adm-tabs-header{
      border-bottom-color: rgba(180,181,184,0.50);
    }

    .adm-tabs-tab{
      font-family: HelveticaNeue;
      font-size: 14px;
      color: #57606A;
    }

    .adm-tabs-tab-active{
      font-family: HelveticaNeue-Medium;
      color: #24292F;
    }

    .adm-tabs-tab-line{
      background: #16ADFD;
    }
  }
}

.header-search-input:focus-visible {
  border-radius: 16px;
  outline: 1px #DFDFE1 solid;
  /* opacity: 0; */
}

.file-head-box {

  //height: 110px;

  // header-pause
  // upgrade-storage

  &:has(.header-pause), &:has(.upgrade-storage) {
    //height: 158px;
  }
}

.con {
  &:has(.header-pause), &:has(.upgrade-storage) {
    .file-head-box {
      //height: 158px;
    }

    .content {
      //height: calc(100% - 158px);
      position: relative;
    }
  }

  &.has-header-pause,
  &.has-upgrade-storage {
    .file-head-box {
      //height: 158px;
    }

    .content {
      //height: calc(100% - 158px);
    }
  }

  &.a-h100{
    .file-head-box {
      //height: 210px;
    }

    .content {
      //height: calc(100% - 210px);
    }

    .upgrade-storage{
      //height: 100px;
    }
  }
}

.vault-head-contain{

  .content-title {
    // border:1px red solid;
    display: flex;
    justify-content: space-between;
    //margin-bottom: 15px;
    // padding: 24px 24px 15px;
    padding: 24px 0 0 0;
    box-sizing: border-box;
    height: 48px;
    position: relative;
    // margin-left: 30px;
    .page-con-item {
      display: flex;
      align-items: center;
      .page-con-search {
        background: #F6F8FA;
        border-radius: 18px;
        width: 320px;
        height: 36px;
        margin-right: 16px;
        line-height: 36px;
        position: relative;
        .ant-input {
          height: 36px;
          line-height: 36px;
          padding-left: 40px;
          color: #8C959F;
          cursor: pointer;
        }
        .ant-input-outlined {
          background-color: #F6F8FA;
          border-radius: 18px;
          border: none;
         
          &:focus-within{
             border-color: #F6F8FA;
             box-shadow: none;
          }
        }
      }
      .search-img {
        position: absolute;
        top: 10px;
        left: 14px;
        width: 17.5px !important;
        height: 17.5px;
      }
    }
    
    .controls {
      display: flex;
      // position: absolute;
      // left: 75px;
    }

    .sort-download {
      // margin-left: 30px;
      display: flex;
      align-items: center;
      line-height: 24px;
      position: relative;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        margin-left: 8px;
      }
    }
    .move-to-line {
      position: relative;
      width: 1px;
      height: 12px;
      background: #D0D7DE;
      margin-top: 6px;
      margin-left: 20px;
    }
    .move-to-trash-selected {
      span {
        color: #16ADFD;
        margin-left: 0 !important;
      }
    }
    .con-after {
      &::after {
        content: "";
        position: absolute;
        top: 6px;
        right: -14px;
        width: 2px;
        height: 12px;
        background-color: #D0D7DE;
      }

    }

   
    .move-to-trash {
      // margin-left: 30px;
      // margin-left: 14px;
      margin-left: 24px;
      display: flex;
      align-items: center;
      line-height: 24px;
      position: relative;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        margin-left: 8px;
      }
    }

    div:nth-child(1) {
      display: flex;

      h4 {
        font-family: HelveticaNeue-Medium;
        font-size: 18px;
        color: #1E2128;
      }

      img {
        width: 12px;
        //margin-left: 8px;
      }
    }

    div:nth-child(2) {
      img {
        width: 18px;
      }
    }
    .center-search {
      display: flex;
      .search-input {
        margin-right: 6px;
        position: relative;
        cursor: pointer;
        .ant-input {
          width: 256px;
          height: 36px;
          background: #FFFFFF;
          border: 1px solid #16ADFD;
          border-radius: 18px;
          font-family: HelveticaNeue;
          font-size: 16px;
          color: #24292F;
          letter-spacing: 0;
          line-height: 18px;
          text-indent: 28px;
        }
        img {
          position: absolute;
          top: 10px;
          left: 10px
        }
      }
      .search-cancel {
        cursor: pointer;
        img {
          width: 36px;
          height: 36px;
        }
      }
    }

  }

}

.content {
  padding: 0px 0 0px;
  //margin-top: 28px;
  box-sizing: border-box;
  height: calc(100% - 110px);
  // margin-top: -20px;



  .file-item-list {
    height: calc(100% - 55px);
    padding-bottom: 30px;
    // overflow-y: auto;
    box-sizing: border-box;
  }

  .content-data {
    margin: 107px auto 0;
    text-align: center;

    .content-data-img {
      width: 240px;
      height: 198px;
      margin: 0 auto;
    }

    .content-data-txt {
      h4 {
        margin-top: 12px;
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
      }

      p {
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #5E6065;
        letter-spacing: 0;
        margin-top: 7px;
      }
    }
  }

  .content-two {
    // margin-top: -12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // padding: 0 24px;

    .content-two-item {
      width: 152px;
      height: 152px;
      background: #FFFFFF;
      border-radius: 8px;
      margin-top: 24px;
      position: relative;
      overflow: hidden;
      border: 1px solid #EAEEF2;
      .more {
        display: flex;
        justify-content: flex-end;
        margin: 0px;
        position: absolute;
        width: 100%;
        z-index: 1;
        padding: 2px 2px;
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.85));
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .a-more-btn{
          display: inline-flex;
          //height: 12px;
          padding: 6px 10px;
        }

        img {
          width: 20px;
          vertical-align: bottom;
        }
      }

      .file {
        //display: flex;
        //justify-content: center;

        img {
          width: 72px;
          vertical-align: middle;
        }
      }

      .real-file{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .adm-image{
          width: 100%;
          height: 100%;
        }

        img{
          width: 100%;
        }
      }

      .txt {
        margin-top: 12px;
        //font-family: HelveticaNeue;
        //font-size: 16px;
        //color: #1E2128;
        letter-spacing: 0;
        text-align: center;
        //line-height: 18px;
        padding: 0 10px;

        font-family: HelveticaNeue;
        font-size: 14px;
        color: #24292F;
        letter-spacing: 0;
        text-align: center;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 180px;
        //position: absolute;
        //left: 0;
        //right: 0;
        //bottom: 5px;

        //display: flex;

        //color: #FFFFFF;
        //background-color: rgba(0,0,0,0.65);
        .txt-nowrap {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .adm-ellipsis{
          transform-origin: center;
          //transform: scale(0.85);
          text-align: center;
          font-family: HelveticaNeue-Bold;
          font-size: 30px;
          color: #24292F;
          letter-spacing: 0;
        }
      }

      .--bottom-text-box{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
      }

      .--date{
        font-family: HelveticaNeue;
        font-size: 11px;
        color: #270000;
        letter-spacing: 0;
        text-align: center;
        opacity: 0.6;
      }
    }
  }

  .content-item {
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    //margin-top: 33px;
    // padding: 16px 24px;
    padding: 16px 12px;
    margin-left: 24px; 

    &:hover {
      background: #F6F8FA;
    }

    .content-item-type {
      display: flex;
      width: calc(100% - 60px);

      img {
        width: 32px;
        max-height: 42px;
        object-fit: contain;
        object-position: center;
      }

      .adm-image{

      }

      .content-item-txt {
        margin-left: 16px;

        h4 {
          font-family: HelveticaNeue;
          font-size: 16px;
          color: #24292F;
          letter-spacing: 0;
          line-height: 18px;
        }
        p {
          font-family: HelveticaNeue;
          font-size: 12px;
          color: #270000;
          letter-spacing: 0;
          line-height: 18px;
          max-width: 430px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        & > div {
          font-family: HelveticaNeue;
          font-size: 12px;
          color: #000029;
          line-height: 18px;
        }
      }
    }

    .content-item-more {
      img {
        width: 20px;
      }
      cursor: pointer;
    }
  }

  .content-item-checkbox{

    //.adm-checkbox.adm-checkbox-checked .adm-checkbox-icon{
    //  border-color: #16ADFD;
    //  background-color: #16ADFD;
    //}
  }

}


.check-box-quadrate{

  .adm-checkbox .adm-checkbox-icon{
    //border-radius: 2px;
  }

  //.adm-checkbox.adm-checkbox-indeterminate .adm-checkbox-icon{
  //  border-color: #16ADFD;
  //  background-color: #FFFFFF;
  //  //border-radius: 2px;
  //  color: #16ADFD;
  //}
  //
  //.adm-checkbox.adm-checkbox-checked .adm-checkbox-icon{
  //  border-color: #16ADFD;
  //  background-color: #16ADFD;
  //}
}


.popup-con {
  .adm-popup-body {
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    // border-radius: 24px 24px 0 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: auto;
    padding-bottom: 56px;
    // padding: 0 24px;
    .popup-title {
      font-family: HelveticaNeue-Medium;
      font-size: 20px;
      color: #1E2128;
      letter-spacing: 0;
      line-height: 20px;
      margin: 27px 24px 0;
    }

    .popup-item {
      display: flex;
      padding: 15px 24px 15px;
      align-items: center;
      margin-top: 8px;
      p {
        margin-left: 15px;
        font-family: HelveticaNeue;
        font-size: 16px;
        color: #1E2128;
        letter-spacing: 0;
      }
    }

    .popup-item:nth-child(3) {
      border-bottom: 1px solid #F4F4F4;
    }

    .popup-item-active {
      background: #F4F4F4;
    }
  }
}
.adm-popover--sort-menu-all {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  .select-all {
    margin-right: 20px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;

  }

}
.adm-popover--sort-menu{

  .adm-popover-arrow{
    display: none;
  }

  .adm-popover-menu-item-text{
    border-top: none;

    font-family: HelveticaNeue;
    font-size: 16px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 16px;
  }

  .adm-popover-inner{
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.05);
    margin-top: -5px;
  }
}

.adm-popover--add-btn{

  .adm-popover-arrow{
    display: none;
  }

  .adm-popover-menu-item-text{
    border-top: none;

    font-family: HelveticaNeue;
    font-size: 16px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 16px;
  }

  .adm-popover-inner{
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.05);

    transform: translate(-45px, 15px);
  }
}

.--notice-card-box{
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 13px 16px 13px;

  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  width: calc(100% - 32px);

  max-width: 500px;
  z-index: 10;

  .--icon-a{
    width: 36px;
  }

  .--words-a{
    box-sizing: border-box;
    padding: 0 15px;

    width: calc(100% - 100px);

    h3{
      font-family: HelveticaNeue-Medium;
      font-size: 14px;
      color: #1E2128;
      letter-spacing: 0;
      line-height: 16px;
      margin-bottom: 4px;
    }

    p{
      font-family: HelveticaNeue;
      font-size: 11px;
      color: #57606A;
      letter-spacing: 0;
    }
  }

  .--btn-a{
    width: 64px;

    .adm-button{
      font-family: HelveticaNeue-Medium;
      font-size: 14px;
      //color: #FFFFFF;
      letter-spacing: 0;
      line-height: 14px;
    }

    .adm-button:not(.adm-button-default) {
      --text-color: #ffffff;
      --background-color: #16ADFD;
      --border-color: #16ADFD;
    }
  }

  .--btn-close{
    position: absolute;
    top: 6px;
    right: 6px;
    opacity: 0.6;
  }
}

.--notice-popup-box{
   z-index: 10001;
   position: absolute;
   bottom: 40px;
   right: 40px;
   top: auto !important;
  .adm-popup-body{
    box-sizing: border-box;
    padding: 18px 12px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.10);
    border-radius: 20px 20px 0 0;
  }

  .--notice-popup-body{

  }

  .--flex{
    display: flex;
    flex-direction: row;
  }

  .--icon-1{

  }

  .--box-1{
    box-sizing: border-box;
    padding: 0 30px 0 12px;
  }

  .--notice-desc{
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 18px;

    b{
      font-family: HelveticaNeue-Medium;
      font-size: 14px;
      color: #16ADFD;
    }
  }

  .--box-2{
    box-sizing: border-box;
    padding: 25px 0 10px;

    .adm-button{
      font-family: HelveticaNeue-Medium;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
    }

    .adm-button:not(.adm-button-default) {
      --text-color: #ffffff;
      --background-color: #16ADFD;
      --border-color: #16ADFD;
    }
  }
}
