@variable-24292F: #24292F;
@variable-0A0000: #0A0000; 
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065:  #5E6065; 
@variable-37352F:  #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2;
@variable-6E7781: #6E7781;
@variable-16ADFD:  #16ADFD;
@variable-E7F6FE: #E7F6FE;
@variable-2F25F7: #2F25F7;
@variable-white:  #fff;


// .ant-drawer {
//   top: 48px !important;
// }
.top {
  margin-top: 0 !important;
}
.file-information-modal {
  //  margin-top: 48px;
    .ant-drawer-header {
        display: none;
    }
    .ant-drawer-body {
        padding: 0 32px;
        background: #EAEEF2;
    }
    .file-information-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        p:nth-child(2) {
            font-family: HelveticaNeue-Bold;
            font-size: 20px;
            color: @variable-1E2128;
            letter-spacing: 0;
            text-align: center;
        }
        p:last-child {
           width: 44px;
           height: 44px;
           background-color: @variable-white; 
           display: flex;
           justify-content: center;
           align-items: center;
           border-radius: 50%;
           cursor: pointer;
        }
    }
    .details {
        .ui-head-nav {
          margin: 0 16px;
        }
        
        // padding: 0 16px;
        .details-con {
          border-top: 1px #F4F4F4 solid;
      
          .details-con-img {
            width: 176px;
            height: 176px;
            margin: 24px auto;
            overflow: hidden;
            position: relative;
      
            .adm-image{
              height: 100%;
              width: 100%;
            }
      
            .a-ply-btn{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
      
            img {
              width: 100%;
      
            }
          }
      
          .details-content {
            .details-content-item {
              margin: 0 16px;
              display: flex;
              border-bottom: 1px #F4F4F4 solid;
              border-radius: 1.5px;
              padding-bottom: 8px;
              margin-top: 8px;
      
              div:nth-child(1) {
                flex: 1;
      
                p:nth-child(1) {
                  font-family: HelveticaNeue;
                  font-size: 12px;
                  color: #5E6065;
                  letter-spacing: 0;
                  line-height: 14px;
                }
      
                p:nth-child(2) {
                  font-family: HelveticaNeue;
                  font-size: 18px;
                  color: #000F00;
                  letter-spacing: 0;
                  line-height: 24px;
                  word-break: break-all;
                }
              }
      
              div:nth-child(2) {
                width: 20px;
                height: 20px;
                padding-top: 6px;
      
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
}

  