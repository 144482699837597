.photo-page{
  // height: 100%;
  width: 100%;
  position: relative;
  top: -54px;
  // overflow-y: auto;
  .-head{
    width: 100%;
    min-height: 52px;
    align-items: center;
    border-bottom: 1px #DFDFE1 solid;
    //padding: 0 16px;
    box-sizing: border-box;
  }

  .-body{
    width: 100%;
    height: calc(100% - 52px);
    // overflow-y: auto;
  }

  .-alist{
    
  }
  .-alist-mt36 {
    margin-top: 36px;
  }
  .-alist-mt12 {
    margin-top: 12px;
  }

  .item-area{

  }

  .video-player-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .item-title{
    font-family: HelveticaNeue-Medium;
    font-size: 18px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 20px;
    box-sizing: border-box;
    padding:  19px 0 9px;
    display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // align-items: center;
  }

  .list-area{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .list-area-tom {
    position: relative;
  } 
  .content-select {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 8;
    cursor: pointer;
  }


  .item-image{
    width: calc((100% - 12px) / 3);
    margin-bottom: 6px;
    box-sizing: border-box;
    // box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
    position: relative;
    .adm-image-tom {
      width: 129px !important;
      height: 129px !important;
    }
    &.-days{
      // width: calc((100% - 12px) / 3);
      // width: calc((100% - 12px) / 10);
      width: calc((100% - 12px) / 6);
      margin-bottom: calc(12px / 5);
      margin-bottom: calc(12px / 2);
      @media screen and (max-width: 1500px) {
        width: calc((100% - 12px) / 8);
      }
    }

    &.-months{
      // width: calc((100% - 12px) / 5);
      // margin-bottom: calc(12px / 4);
      width: calc((100% - 12px) / 3);
      width: calc((100% - 12px) / 10);
      margin-bottom: calc(12px / 2);
      @media screen and (max-width: 1500px) {
        width: calc((100% - 12px) / 8);
      }
    }

    &.-years{
      width: calc((100% - 12px) / 13);
      // width: calc((100% - 12px) / 3);
      // width: calc((100% - 12px) / 10);
      margin-bottom: calc(12px / 2);
      .adm-image-tom {
        width: 100px !important;
        height: 100px !important;
      }
      @media screen and (max-width: 1500px) {
        width: calc((100% - 12px) / 8);
      }
    }

    // &.-days:not(:nth-of-type(3n)){
    // //  margin-right: calc(12px / 2); 
    //   @media screen and (max-width: 1500px) {
    //     margin-right: 0;
    //   }
    // }
    &.-month:not(:nth-of-type(3n)){
      margin-right: calc(12px / 2); 
       @media screen and (max-width: 1500px) {
         margin-right: 0;
       }
     }
    // &.-months:not(:nth-of-type(3n)){
    //   margin-right: calc(12px / 2); 
    //    @media screen and (max-width: 1500px) {
    //      margin-right: 0;
    //    }
    //  }
    //  &.-years:not(:nth-of-type(3n)){
    //   margin-right: calc(12px / 2); 
    //    @media screen and (max-width: 1500px) {
    //      margin-right: 0;
    //    }
    //  }

    // &.-months:not(:nth-of-type(5n)){
    //   margin-right: calc(12px / 4);
    // }
    // &.-years:not(:nth-of-type(6n)){
    //   margin-right: calc(12px / 5);
    // }

    .adm-image{
      width: 100%;
      height: 100%;
    }

    img{
      width: 100%;
      vertical-align: middle;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  .item-checkbox{
    position: absolute;
    bottom: 4px;
    right: 4px;
    opacity: 1;
  }

  .item-collect{
    position: absolute;
    left: 8px;
    bottom: 8px;
    cursor: pointer;
  }
  
}
.photo-page::-weblit-scrollbar{
  display: none;
}

.photo-show-type-popup{

  .adm-popup-body{
    min-height: 300px;
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    padding: 28px 24px;
    box-sizing: border-box;
  }

  .-area{

    &:not(:nth-last-of-type(1)){
      margin-bottom: 8px;
    }
  }

  .-title{
    font-family: HelveticaNeue-Medium;
    font-size: 20px;
    color: #1E2128;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .-items{
    font-family: HelveticaNeue;
    font-size: 16px;
    color: #1E2128;
    letter-spacing: 0;

    span{
      background: #FFFFFF;
      border: 1px solid #DFDFE1;
      border-radius: 20px;
      height: 40px;
      display: inline-block;
      padding: 12px 25px;
      box-sizing: border-box;

      margin-right: 12px;
      margin-bottom: 12px;

      &.-act{
        background: #16ADFD;
        box-shadow: 0 2px 4px 0 rgba(22,173,253,0.04), 0 8px 16px 0 rgba(22,173,253,0.16);
        border: 1px solid #16ADFD;

        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }
}
.photo-page-tom {
  // height: 100%;
  width: 100%;
  position: relative;
  .page-con-item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-top: -16px;
    .page-con-search {
      background: #F6F8FA;
      border-radius: 18px;
      width: 320px;
      height: 36px;
      margin-right: 16px;
      line-height: 36px;
      position: relative;
      .ant-input {
        height: 36px;
        line-height: 36px;
        padding-left: 40px;
        color: #8C959F;
        cursor: pointer;
      }
      .ant-input-outlined {
        background-color: #F6F8FA;
        border-radius: 18px;
        border: none;
       
        &:focus-within{
           border-color: #F6F8FA;
           box-shadow: none;
        }
      }
    }
    .search-img {
      position: absolute;
      top: 10px;
      left: 14px;
      width: 17.5px !important;
      height: 17.5px;
    }
  }
  .page-con-time {
    display: flex;
    width: 192px;
    height: 36px;
    background: #F6F8FA;
    border-radius: 18px;
    justify-content: center;
    .page-con-day {
      background: #24292F;
      border-radius: 18px 0 0 18px;
      color: #FFFFFF;
    }
    .page-con-month {
      background: #24292F;
      // border-radius: 18px 0 0 18px;
      color: #FFFFFF;
    }
    .page-con-year {
      background: #24292F;
      border-radius: 0px 18px 18px 0px;
      color: #FFFFFF;
    }
    div {
      width: 33%;
      line-height: 36px;
      text-align: center;
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #57606A;
      letter-spacing: 0;
    }
  }
  
  .controls {
    display: flex;
    position: sticky;
    left: 16px;
    top: 190px;
    // border: 1px red solid;
    // height: 70px;
    // background-color: #EAEEF2;
    background-color: #fff;
    z-index: 9;
    justify-content: space-between;
    height: 48px;
    .controls-item {
      display: flex;
      .move-to-line {
        position: relative;
        width: 1px;
        height: 12px;
        background: #D0D7DE;
        margin-top: 22px;
        margin-left: 20px;
      }
      .move-to-trash-selected {
        span {
          color: #16ADFD;
          margin-left: 0 !important;
        }
      }
    }
  }
  .select-all {
    margin-right: 20px;
    position: absolute;
    left: 0px;
    top: 35px;
    cursor: pointer;
  }
  .sort-download {
    // margin-left: 30px;
    display: flex;
    align-items: center;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      margin-left: 8px;
    }
  }
  .move-to-trash {
    margin-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      margin-left: 8px;
    }
  }
}