@variable-24292F: #24292F;
@variable-0A0000: #0A0000; 
@variable-1E2128: #1E2128;
@variable-120000: #120000;
@variable-57606A: #57606A;
@variable-5E6065:  #5E6065; 
@variable-37352F:  #37352F;
@variable-270000: #270000;
@variable-EAEEF2: #EAEEF2; 
@variable-D0D7DE: #D0D7DE;
@variable-6E7781: #6E7781;
@variable-16ADFD:  #16ADFD;
@variable-2F25F7: #2F25F7;
@variable-white:  #fff;

.tips-modal {
    background: @variable-EAEEF2;
    border-radius: 5px !important;
    width: 280px !important;
    // height: 360px !important;
    .ant-modal-content {
        background: @variable-EAEEF2;
        border-radius: 12px !important;
        padding: 10px 12px !important;
        .ant-modal-header {
            display: none;
        }
        .ant-modal-close {
            display: none;
        }
    }
    .prompt {
        text-align: center;
        font-family: HelveticaNeue-Bold;
        font-size: 18px;
        color: #000;
        line-height: 25px;
    }
    .prompt-con {
        text-align: center;
        margin-top: 8px;
        p {
            font-size: 16px;
            color: #000; 
        }
    }
    .prompt-footer {
        width: 100%;
        margin-top: 8px;
        text-align: center;
            .ant-btn:nth-child(1) { 
                background: @variable-16ADFD;
                width: 100%;
                border-radius: 5px;
                font-family: HelveticaNeue-Bold;
                font-size: 18px;
                color: @variable-white;
                letter-spacing: 0;
                height: 49px;
                line-height: 49px;
                text-align: center;
                border: 1px @variable-white solid;
                margin: 0 auto;
                margin-top: 8px;
                margin-bottom: 8px;
                 &:hover {
                    border: 1px @variable-16ADFD solid;
                    color: @variable-white !important;
                    background: @variable-16ADFD !important;
                }
            }
            .ant-btn:nth-child(2) { 
                background: @variable-EAEEF2;
                border-radius: 24px;
                font-family: HelveticaNeue-Bold;
                font-size: 18px;
                color: @variable-16ADFD;
                letter-spacing: 0;
                text-align: center;
                border: none;
                // border: 1px @variable-16ADFD solid;
                 &:hover {
                    background: @variable-EAEEF2 !important;
                    border: none;
                }
            }
    }
}