.device-center-liveview{
  box-sizing: border-box;
  // padding: 30px 0;
  padding: 17px 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 30px;

  position: relative;

  @media screen and (min-width: 4120px){
    grid-template-columns: repeat(9, 1fr);
  }

  @media screen and (max-width: 4120px){
    grid-template-columns: repeat(8, 1fr);
  }

  @media screen and (max-width: 3680px){
    grid-template-columns: repeat(7, 1fr);
  }

  @media screen and (max-width: 3240px){
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (max-width: 2800px){
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (max-width: 2360px){
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 1920px){
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 1690px){
    grid-template-columns: repeat(2, 1fr);
  }

  .device-item-container{
    background: #FFFFFF;
    border-radius: 12px;

    //width: 440px;
    height: 306px;
    box-sizing: border-box;
    padding: 10px;
  }

  .camera-cover{
    height: calc(100% - 60px);
    background-color: #F6F8FA;

    position: relative;
  }

  .camera-default-bg{

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .play-bg-box{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0);

    .play-btn{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      opacity: 0.8;
      transition: opacity 0.35s;

      &:hover{
        opacity: 1;
      }
    }
  }

  .camera-intro-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 60px;
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
  }

  .camera-name-box{

  }

  .camera-status-box{
    display: flex;
    flex-direction: row;

    .icon-offline{
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #D0D7DE;
    }

    .icon-online{
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #6DD400;
    }
  }

  .camera-name{
    font-family: HelveticaNeue-Medium;
    font-size: 18px;
    color: #24292F;
    letter-spacing: 0;
    line-height: 18px;
  }

  .camera-alert{
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #270000;
    letter-spacing: 0;
    line-height: 18px;
  }

  .loading-status-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-device-item{
    background: #FFFFFF;
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 306px;

    img{
      margin-bottom: 16px;
      vertical-align: bottom;
      width: 21.8182%;
      max-width: 96px;
    }
  }

  .text-18{
    font-family: HelveticaNeue;
    font-size: 18px;
    color: #57606A;
    letter-spacing: 0;
    line-height: 18px;
  }
}

