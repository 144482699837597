.adm-modal:has(.a-modal-container-i){
  //border-radius: 18px;
  --border-radius: var(--adm-center-popup-border-radius, 18px);
}

.adm-center-popup-wrap:has(.a-modal-container-i){
  max-width: unset;

  .adm-modal-footer-empty{
    height: 0;
  }

  .adm-modal-body{
    padding-top: 0;
  }
}

.adm-modal-content:has(.a-modal-container-i){
  padding: 0;

  .a-modal-container-i{

    .a-i-body{
      padding: 15px 24px 36px;
    }

    .a-i-icon{
      text-align: center;
    }

    .a-i-name{
      font-family: HelveticaNeue-Medium;
      font-size: 18px;
      color: #1E2128;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 10px;
    }

    .a-i-intro{
      font-family: HelveticaNeue;
      font-size: 16px;
      color: #5E6065;
      letter-spacing: 0;
      line-height: 22px;

      b{
        font-family: HelveticaNeue-Bold;
        color: #16ADFD;
      }
    }

    .a-i-checkbox{
      padding-top: 10px;

      .adm-checkbox-content{
        font-family: HelveticaNeue;
        font-size: 14px;
        color: #5E6065;
        letter-spacing: 0;
      }

      .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon{
        background-color: #16ADFD;
        border-color: #16ADFD;
      }

      .adm-checkbox .adm-checkbox-icon{
        border-radius: 3px;
      }
    }

    .a-i-foot{
      border-top: 1px solid #DFDFE1;
      height: 75px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button{
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: -0.5px;
        background: #16ADFD;
        box-shadow: 0 2px 4px 0 rgba(22,173,253,0.04), 0 8px 16px 0 rgba(22,173,253,0.16);
        border-radius: 22px;
        border: 0;
        height: 44px;
        padding: 0 10px;
      }

      .a-btn-cancel{
        font-family: HelveticaNeue-Medium;
        font-size: 16px;
        color: #5E6065;
        letter-spacing: -0.5px;
        line-height: 18px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
